import React from 'react'
import './sidebar-admin-container.styles.scss'
import SidebarAdmin from './sidebar-admin.component';

const SidebarAdminContainer = () => {
    return (
        <div className='sidebar-admin-container'>
            <SidebarAdmin />
        </div>
    )
}

export default SidebarAdminContainer;