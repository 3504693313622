import React, { useEffect, useRef, useState } from 'react';
import './sort-titles-form.styles.scss';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faX } from '@fortawesome/free-solid-svg-icons';
import Modal from 'components/modal/modal.component';

const SortTitlesForm = ({ formData, saveData }) => {

    const currentOrg = useSelector(state => state.contentData.currentOrg);
    const sortCategories = useSelector(state => state.contentData.sortCategories);
    const [ categoriesToPick, setCategoriesToPick ] = useState({});
    const [ sortTitles, setSortTitles ] = useState(null);
    const tempData = useRef();

    useEffect(() => {
        if (!currentOrg) {return;}
        let arr = [];
        for (let i=1; i<5; i++) {
            if (i === 1 && !currentOrg[`termLevel${i}`]) {
                for (let j=1; j<5; j++) {
                    arr.push({'title': `Sort ${j}`, 'id': `termLevel${j}`, 'sort': `sortLevel${j}`})
                }
                break;
            } else if (currentOrg[`termLevel${i}`]) {
                arr.push({'title': currentOrg[`termLevel${i}`], 'id': `termLevel${i}`, 'sort': `sortLevel${i}`});
            }
        }
        // console.log(arr);
        setSortTitles(arr);
    }, [currentOrg]);

    function saveSortCategory(sort, value, index) {
        if (!formData[sort]) {
            formData[sort] = [value]    
        } else if (!formData[sort].includes(value)) {
            formData[sort].push(value);
        } else if (formData[sort].includes(value)) {
            formData[sort] = formData[sort].filter(t => t !== value);
        }
        // document.getElementById(`sortpicker-${sort}-${index}`).classList.toggle('active');
        saveData(sort, formData[sort]);
    }


    return (
        <div className='sort-titles-form'>
            {
                (sortTitles && formData) &&
                sortTitles.map(s =>(
                    <div key={s.id} className="sort-choice-div">
                        <div>
                            <button className='g-button' onClick={() => setCategoriesToPick(s)}>
                                {/* <FontAwesomeIcon icon={faFilter} /> */}
                                Select {s.title}
                            </button>
                        </div>               
                        <div>
                            <div className='g-list-item selected-sort-title'>
                                {/* <p>Selected</p>
                                <hr /> */}
                                {
                                    (formData[s.sort] && formData[s.sort].length > 0) 
                                    ?
                                    formData[s.sort].map(c => (
                                        <div key={c} className='g-list-item sort-list-item'>
                                            <div className='sort-list-item-title'>{c}</div>
                                            <FontAwesomeIcon icon={faX} className='clickable' title='Remove this sort category' onClick={() => saveSortCategory(s.sort, c, false)} />
                                        </div>
                                    ))
                                    :
                                    <p>None selected...</p>
                                }
                                
                            </div>
                        </div>
                    </div>
                ))
            }
            <Modal show={Object.values(categoriesToPick).length} cancel={() => setCategoriesToPick({})} closeButton={true} cls='narrow' text={`Choose ${categoriesToPick.title}`}>
                <div className='cat-modal'>
                    {
                        (sortCategories[categoriesToPick.sort]) &&
                        sortCategories[categoriesToPick.sort].toSorted((a,b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1)
                        .map((c, index) => (
                            <div key={c} className={`g-list-item clickable ${formData[categoriesToPick.sort] && formData[categoriesToPick.sort].includes(c) ? 'active': ''}`} onClick={() => saveSortCategory(categoriesToPick.sort, c, index)}>
                                {c}
                            </div>
                        ))
                    }
                    <div className='g-space-10'></div>
                </div>
            </Modal>
        </div>
    )
}

export default SortTitlesForm