import React, { useEffect, useRef, useState } from 'react';
import './pending-people.styles.scss';
import { useSelector } from 'react-redux';
import { getPendingPeople } from 'utils/getUserData';
import { off } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { switchOrgMenu } from 'components/ORG-MANAGER/org-sidebar-left/org-sidebar-left.component';
import UIkit from 'uikit';
import { UseCloudFunction } from 'utils/firebase.utils';

const PendingPeopleList = () => {

    const currentOrg = useSelector(state => state.contentData.currentOrg);
    const profile = useSelector(state => state.userData.userProfile);
    const [ pendingPeople, setPendingPeople ] = useState(null);
    const pendingRef = useRef();
    const navigate = useNavigate();
    let pendingListener;

    useEffect(() => {
        switchOrgMenu('coach-add-person');
        return () => {
            if (pendingListener) {
                off(pendingListener);
            }
        }
    }, [])

    useEffect(() => {
        if (!currentOrg || !profile) { return; }
        getPendingList();
    }, [currentOrg, profile])

    async function getPendingList() {
        getPendingPeople({'appId': profile.appId, 'orgId': currentOrg.orgId, 'callback': callback, 'receiveListener': receiveListener})
        function callback(data, deleted) {
            if (!deleted) {
                console.log(data);
                pendingRef.current = {...pendingRef.current, ...{[data.personId]: data}}
                setPendingPeople(pendingRef.current);
            } else {
                const personList = {...pendingRef.current};
                delete personList[data.personId];
                pendingRef.current = {...personList};
                setPendingPeople(pendingRef.current);  
            }
        }
        function receiveListener(unsubscribe) {
            pendingListener = unsubscribe;
        }
    }

    async function activatePerson(id) {
        const approved = await UIkit.modal.confirm('Are you sure you want to activate this person?')
        .then(() => {return true;}, () => {return false;})
        if (!approved) { return; }
        const res = await UseCloudFunction('activatePendingPerson', {'personId': id, 'orgId': currentOrg.orgId, 'appId': profile.appId})
    }

    return (
        <div className='pending-people-container'>
            <div className="pending-people-list">
                <div className='back-div'><span className='back-icon' onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowLeft} />Back</span></div>
                <div className="g-card">
                    <div className='section-title'>
                        Pending List
                    </div><hr />
                    {
                        (pendingPeople) &&
                        Object.values(pendingPeople).sort((a, b) => (a.lName.toLowerCase() > b.lName.toLowerCase()) ? 1 : (a.lName.toLowerCase() === b.lName.toLowerCase()) ? ((a.fName.toLowerCase() > b.fName.toLowerCase()) ? 1 : -1) : -1 )
                        .map(p => (
                            <div key={p.personId} className='g-list-item pending-list-item'>
                                <div><b>{p.fName} {p.lName}</b></div>
                                <div>{p.sortLevel1}</div>
                                <div>{p.sortLevel2}</div>
                                <div>{p.sortLevel3}</div>
                                <div><button className='g-button small-btn' onClick={() => activatePerson(p.personId)}>Activate</button></div>
                            </div>
                        ))                        
                    }
                </div>
            </div>        
        </div>
    )
}

export default PendingPeopleList;