import React, { useEffect, useRef, useState } from 'react';
import './calendar.styles.scss';

import { months, weekdays } from './calendarData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { current } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import ImportItem from './import-item.component';
import Modal from 'components/modal/modal.component';
import NewActivityForm from '../view-person/components/new-activity-form.component';

const Calendar = ({ itemsToDisplay, importEvents, activity, getMoreEvents }) => {

    const myPeople = useSelector(state => state.contentData.myPeople);
    const [ startingPoint, setStartingPoint ] = useState(null);
    const [ currentWeek, setCurrentWeek ] = useState([]);
    const [ importContent, setImportContent ] = useState(null);
    const [ dayCount, setDayCount ] = useState(0);
    const dateRef = useRef();

    useEffect(() => {
        dateRef.current = new Date();
        dateRef.current.setHours(0, 0, 0, 0);
        setStartingPoint(dateRef.current);
    }, [])

    useEffect(() => {
        if (!startingPoint) {return;}
        displayWeek();
    }, [startingPoint])

    useEffect(() => {
        // console.log(dayCount);
        if (dayCount % 20 === 0) {
            getMoreEvents(dayCount);
        }
    }, [dayCount])

    function displayWeek() {

        // Calculate the date for this past Sunday
        // const daysUntilMonday = 7 - startingPoint.getDay();
        // const pastMondayDate = new Date(startingPoint);
        // pastMondayDate.setDate(startingPoint.getDate() - daysUntilMonday);
        // pastMondayDate.setHours(0, 0, 0, 0);
        let currentMillis = new Date(startingPoint).getTime();
        let twoDaysAgo = new Date(currentMillis - (86400000 * 2))

        // Display the days and dates for the next seven days
        const weekArr = []
        // let loopDate = pastMondayDate;
        for (let i = 0; i < 5; i++) {
            
            const currentDay = twoDaysAgo.toLocaleDateString('en-US', { weekday: 'short' });
            const currentDateStr = twoDaysAgo.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
            weekArr.push({'text': `${currentDay} ${currentDateStr}`, 'date': new Date(twoDaysAgo)})
            twoDaysAgo = new Date(new Date(twoDaysAgo).getTime() + 86400000)
            // Move to the next day
        }
        // console.log(weekArr);
        setCurrentWeek(weekArr);
        // setCurrentSunday(pastMondayDate);
    }

    function changeDay(e) {
        let newDate
        let newDayCount = dayCount
        if (e === 'back') {
            newDate = new Date(currentWeek[2].date.getTime() - 86400000)
            newDayCount--
            setDayCount(newDayCount)
        } else {
            newDate = new Date(currentWeek[2].date.getTime() + 86400000)
            newDayCount++
            setDayCount(newDayCount)
        }
        newDate.setHours(0, 0, 0, 0);
        // console.log(newDate);
        dateRef.current = newDate;
        console.log(newDate)
        setStartingPoint(dateRef.current)

    }

    function cancel() {
        setImportContent(null);
    }

    return (
        <div className='calendar-content'>
            <div className='date-selector'>
                
            </div>
            {
                (currentWeek.length > 0) &&
                <>
                <div className='calendar-days'>
                    <div className='align caret' onClick={() => changeDay('back')}><FontAwesomeIcon icon={faCaretLeft} size='2x'  /></div>
                    <div>
                        <div className='g-card'>
                            <p className='calendar-day-text'>{currentWeek[0].text}</p>
                        </div>
                        
                    </div>
                    <div>
                        <div className='g-card'>
                            <p className='calendar-day-text'>{currentWeek[1].text}</p>
                        </div>
                        {/* <div>
                            {
                                (itemsToDisplay) && 
                                Object.values(itemsToDisplay).filter(a => a.startDate > currentWeek[1].date.getTime() && a.startDate < currentWeek[1].date.getTime() + 86400000)
                                .map(a => (
                                    <div key={a.ts}>{a.title}</div>
                                ))
                                
                            }
                        </div> */}
                    </div>
                    <div className='g-card'>
                        <p className='calendar-day-text'>{currentWeek[2].text}</p>
                    </div>
                    <div className='g-card'>
                        <p className='calendar-day-text'>{currentWeek[3].text}</p>
                    </div>
                    <div className='g-card'>
                        <p className='calendar-day-text'>{currentWeek[4].text}</p>
                    </div>
                    <div className='align'><FontAwesomeIcon icon={faCaretRight} size='2x' className='caret' onClick={() => changeDay('forward')} /></div>
                </div>
                <div className='calendar-days day-lists'>
                    <div></div>
                    <div className='day-list'>
                        {
                            (itemsToDisplay) && 
                            Object.values(itemsToDisplay).filter(a => a.startDate > new Date(currentWeek[0].date).getTime() && a.startDate < new Date(currentWeek[0].date).getTime() + 86400000)
                            .toSorted((a,b) => a.startDate > b.startDate ? 1 : -1)
                            .map(a => (
                                <div key={a.importId}>
                                    {
                                        (importEvents) ?
                                        <ImportItem event={a} setImportContent={setImportContent} activity={activity} />
                                        :
                                        <div className='g-list-item'></div>
                                    }
                                </div>
                            ))
                            
                        }
                    </div>
                    <div className='day2'>
                    {
                            (itemsToDisplay) && 
                            Object.values(itemsToDisplay).filter(a => a.startDate > new Date(currentWeek[1].date).getTime() && a.startDate < new Date(currentWeek[1].date).getTime() + 86400000)
                            .toSorted((a,b) => a.startDate > b.startDate ? 1 : -1)
                            .map(a => (
                                <div key={a.importId}>
                                    {
                                        (importEvents) ?
                                        <ImportItem event={a} setImportContent={setImportContent} activity={activity} />
                                        :
                                        <div className='g-list-item'></div>
                                    }
                                </div>
                            ))
                            
                        }
                    </div>
                    <div className='day3'>
                    {
                            (itemsToDisplay) && 
                            Object.values(itemsToDisplay).filter(a => a.startDate > new Date(currentWeek[2].date).getTime() && a.startDate < new Date(currentWeek[2].date).getTime() + 86400000)
                            .toSorted((a,b) => a.startDate > b.startDate ? 1 : -1)
                            .map(a => (
                                <div key={a.importId}>
                                    {
                                        (importEvents) ?
                                        <ImportItem event={a} setImportContent={setImportContent} activity={activity} />
                                        :
                                        <div className='g-list-item'></div>
                                    }
                                </div>
                            ))
                            
                        }
                    </div>
                    <div className='day4'>
                    {
                            (itemsToDisplay) && 
                            Object.values(itemsToDisplay).filter(a => a.startDate > new Date(currentWeek[3].date).getTime() && a.startDate < new Date(currentWeek[3].date).getTime() + 86400000)
                            .toSorted((a,b) => a.startDate > b.startDate ? 1 : -1)
                            .map(a => (
                                <div key={a.importId}>
                                    {
                                        (importEvents) ?
                                        <ImportItem event={a} setImportContent={setImportContent} activity={activity} />
                                        :
                                        <div className='g-list-item'></div>
                                    }
                                </div>
                            ))
                            
                        }
                    </div>
                    <div className='day5'>
                        {
                            (itemsToDisplay) && 
                            Object.values(itemsToDisplay).filter(a => a.startDate > new Date(currentWeek[4].date).getTime() && a.startDate < new Date(currentWeek[4].date).getTime() + 86400000)
                            .toSorted((a,b) => a.startDate > b.startDate ? 1 : -1)
                            .map(a => (
                                <div key={a.importId}>
                                    {
                                        (importEvents) ?
                                        <ImportItem event={a} setImportContent={setImportContent} activity={activity} />
                                        :
                                        <div className='g-list-item'></div>
                                    }
                                </div>
                            ))
                            
                        }
                    </div>
                    <div></div>
                </div>
                </>
            }
            <Modal show={importContent} cancel={() => setImportContent(null)} text={'Import This Event'} cls='wide' >
                {/* {
                    (importContent) && */}
                    <NewActivityForm importData={importContent} cancel={cancel} />
                {/* } */}
            </Modal>
        </div>
    )
}

export default Calendar;