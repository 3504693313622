import React, { useEffect, useState } from 'react';
import './my-people-list.styles.scss';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

const MyPeopleList = ({ myPeople, admin }) => {

    const currentOrg = useSelector(state => state.contentData.currentOrg);
    // const myPeople = useSelector(state => state.contentData.myPeople);
    const [ displayList, setDisplayList ] = useState(null);
    const navigate = useNavigate();
    
    useEffect(() => {
        if (!myPeople) { return; }
        setDisplayList(myPeople);
    }, [myPeople])

    function searchPeople(e) {
        const newPeopleList = Object.values(myPeople).filter(p => p.fName.toLowerCase().includes(e) || p.lName.toLowerCase().includes(e) || p.email.toLowerCase().includes(e))
        setDisplayList(newPeopleList);
    }

    return (
        <div className='people-list-content'>
            <div className='searchbar'>
                <FontAwesomeIcon icon={faMagnifyingGlass} size='xl' />
                <div className='field small'>
                    <input type='text' placeholder='Search...' onChange={(e) => searchPeople(e.target.value.toLowerCase())} />
                </div>
            </div>
            <div className="g-space-10"></div>
            <div className='people-list'>
                <div className="g-list-item people-list-item">
                    <div>Name</div>
                    <div>Goals</div>
                    <div>Logs</div>
                    <div>Hours</div>
                    <div>Last Activity</div>
                </div>
                {
                    (displayList) 
                    ?
                    Object.values(displayList).sort((a, b) => (a.lName > b.lName) ? 1 : (a.lName === b.lName) ? ((a.fName > b.fName) ? 1 : -1) : -1 )
                    .map(p => (
                    <div key={p.personId} className="g-list-item people-list-item clickable" onClick={() => navigate(admin ? `personview/${p.personId}` : `/coach/viewperson/${p.personId}`)}>
                        <div className='name'><b>{p.lName}, {p.fName}</b></div>
                        <div>{p.goalCount ?? 0}</div>
                        <div>{p.logCount ?? 0}</div>
                        <div>{p.duration ? `${+(p.duration/60).toFixed(2)} Hrs` : `0 Hrs`}</div>
                        <div>{p.lastActivity ? format(new Date(p.lastActivity), 'P') : 'No Activity'}</div>
                        {/* <div>0</td>
                        <td>None</td>
                        <td>
                            <FontAwesomeIcon icon={faSquareArrowUpRight} className='clickable' onClick={() => navigate(p.personId)} />
                        </td> */}
                    </div>
                    ))
                    :
                    <h3 className='meta'>No {currentOrg.termPersonPlural ?? 'People'} added yet...</h3>
                }
            </div>
        </div>
    )
}

export default MyPeopleList