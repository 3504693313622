import React, { useEffect, useLayoutEffect, useState } from 'react';
import './App.scss';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import LandingPage from 'pages/landing-page/landing-page.comp';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUser } from 'state/slices/userSlice';
import { onAuthStateChanged } from 'firebase/auth';
import { UseCloudFunction, auth } from 'utils/firebase.utils';
import LoadingPage from 'pages/loading/loading-page.page';
import LogoutPage from 'pages/logout/logout-page.page';
import ProfilePage from 'pages/profile/profile.page';
import AdminPage from 'pages/admin/admin.page';
import PageNotFound from 'pages/page-not-found/page-not-found.component';
import ManageOrg from 'components/ORG-MANAGER/manage-org-landing.component';
import CoachPage from 'pages/coach/coach.page';
import SignIn from 'pages/sign-in/sign-in.component';
import Register from 'pages/sign-in/register.component';
import PrivacyPolicy from 'pages/terms/privacy-policy.component';
import TermsOfService from 'pages/terms/terms-of-service.component';
import CoachPageContainer from 'pages/coach/coach-page-container';
import PrintScreen from 'components/ORG-MANAGER/reports/print-screen.component';

function App() {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentUser = useSelector(state => state.userData.currentUser)
    const userProfile = useSelector(state => state.userData.userProfile)
    const [ authUser, setAuthUser ] = useState();
    // const fbUser = useFirebase();

    useEffect(() => {
        // signInUser();
        // const unsubscribe = onAuthStateChanged(auth, user => {
        //     user ? setAuthUser(user) : setAuthUser('no user');
        // })

        // return () => {
        //     if (unsubscribe) {
        //         unsubscribe();
        //     }
        // }

    }, [])

    useEffect(() => {
        // const currentlySignedInUser = auth.currentUser;
        // if (currentlySignedInUser) {
        //     saveUserToState(currentlySignedInUser)
        //     return;
        // }
        // console.log(authUser);
        if (!authUser) {return;}
        const href = window.location.href
        if (authUser === 'no user') {
            if (currentUser) {
                // navigate('/logout')
            }
            if (!href.includes('/signin') && !href.includes('/register') && !href.includes('/info')) {
                // navigate('/')
            }
        } else {
            // console.log('user')
            // console.log(user)
            saveUserToState(authUser)
            if (href.includes('/signin')) {
                // navigate('/loading') 
            }
        }
    }, [authUser])
    
    function signInUser() {
        const href = window.location.href
        const currentlySignedInUser = auth.currentUser;
        if (currentlySignedInUser) {
            saveUserToState(currentlySignedInUser)
        }
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                console.log('user')
                // console.log(user)
                saveUserToState(user)
                if (href.includes('/signin')) {
                    // navigate('/loading') 
                }
                // navigate('/loading')  
            } else {
                if (currentUser) {
                    navigate('/logout')
                }
                if (!href.includes('/signin') && !href.includes('/register') && !href.includes('/info')) {
                    navigate('/')
                }
            }
        });
    }

    function saveUserToState(user) {
        const newUser = {
            displayName: user.displayName,
            email: user.email,
            metadata: {
            createdAt: Number(user.metadata.createdAt),
            lastLogInAt: user.metadata.lastLogInAt
            },
            uid: user.uid,
            photoURL: user.photoURL,
            provider: user.providerData[0].providerId
        }
        dispatch(setCurrentUser({...currentUser, ...newUser}));
    }

    return (
        <div>
            <Routes>
                <Route path='' element={<LandingPage />}></Route>
                <Route path='/signin' element={<SignIn />}></Route>
                <Route path='/register' element={<Register />}></Route>
                <Route path='/coach/*' element={<CoachPageContainer />}></Route>
                <Route path='/logout' element={<LogoutPage />}></Route>
                <Route path='/loading' element={<LoadingPage />}></Route>
                <Route path='/loading/refresh' element={<LoadingPage refresh={true} />}></Route>
                {/* <Route path='/loading/:refreshProfile' element={<LoadingPage />}></Route> */}
                <Route path='/profile' element={<ProfilePage />}></Route>
                <Route path='/admin/*' element={<AdminPage />}></Route>
                <Route path='/manage/*' element={<ManageOrg />}></Route>
                <Route path='/404' element={<PageNotFound />}></Route>
                <Route path='/privacy' element={<PrivacyPolicy />}></Route>
                <Route path='/terms' element={<TermsOfService />}></Route>
                <Route path='/printreport/:pageId' element={<PrintScreen />}></Route>
                <Route path="*" element={<Navigate replace to="/404" />} />
                <Route path="/noaccess" element={<Navigate replace to="/404" />} />
            </Routes>
        
        </div>
    );
}

export default App;
