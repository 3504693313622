import React, { useEffect, useRef, useState } from 'react';
import './reports-landing.styles.scss';
import { switchOrgMenu } from '../org-sidebar-left/org-sidebar-left.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faDownload, faPrint, faSlash } from '@fortawesome/free-solid-svg-icons';
import ReportRow1 from './report-row1.component';
import { getActivity, getGoals } from 'utils/getUserData';
import { useDispatch, useSelector } from 'react-redux';
import { setPrintData, setTempActivity, setTempGoals } from 'state/slices/contentSlice';
import Chart4 from '../org-landing/chart4.component';
import ReportRow2 from './report-row2.component';
import ReportRow3 from './report-row3.component';
import flatpickr from 'flatpickr';
import { useReactToPrint } from 'react-to-print';
import PrintScreen from './print-screen.component';
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import ReportFullData from './report-full-data.component';
import PersonIcon from 'components/shared/person-icon.component';
import { format } from 'date-fns';

const ReportsLanding = ({ activity, goals }) => {

    // const tempActivity = useSelector(state => state.contentData.tempActivity);
    // const tempGoals = useSelector(state => state.contentData.tempGoals);
    const myPeople = useSelector(state => state.contentData.myPeople);
    const currentOrg = useSelector(state => state.contentData.currentOrg);
    const [ activityDisplay, setActivityDisplay ] = useState(null);
    const [ goalsDisplay, setGoalsDisplay ] = useState(null);
    const [ sortData, setSortData ] = useState({});
    const [ sortList1, setSortList1 ] = useState(null);
    const [ sortList2, setSortList2 ] = useState(null);
    const [ sortList3, setSortList3 ] = useState(null);
    const [ sortList4, setSortList4 ] = useState(null);
    const { personId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const sortRef = useRef();
    const fp1Ref = useRef();
    const fp2Ref = useRef();
    

    useEffect(() => {
        const startDateElement = document.getElementById(`startDate`);
		let flat1 = flatpickr(startDateElement, 
			{
				// altInput: true,
	            // altFormat: "m/d/Y",
                enableTime: false,
                dateFormat: "m/d/y",
				onChange: (dates) => {dates[0] ? saveData('startDate', new Date(dates[0]).getTime()) : saveData('startDate', null)}
			}
		);
        fp1Ref.current = flat1;

        const endDateElement = document.getElementById(`endDate`);
		let flat2 = flatpickr(endDateElement, 
			{
				// altInput: true,
	            // altFormat: "m/d/Y",
                enableTime: false,
                dateFormat: "m/d/y",
				onChange: (dates) => {dates[0] ? saveData('endDate', new Date(dates[0]).getTime()) : saveData('endDate', null)}
			}
		);
        fp2Ref.current = flat2;
        checkForSavedSort();
    }, [])

    useEffect(() => {
        if (!activity) {return;}
        let list1 = [];
        let list2 = [];
        let list3 = [];
        let list4 = [];
        for (let a of Object.values(activity)) {
            if (a.sortLevel1) {
                for (let s of a.sortLevel1) {
                    if (!list1.includes(s)) {
                        list1.push(s);
                    }
                }
            }
            if (a.sortLevel2) {
                for (let s of a.sortLevel2) {
                    if (!list2.includes(s)) {
                        list2.push(s);
                    }
                }
            }
            if (a.sortLevel3) {
                for (let s of a.sortLevel3) {
                    if (!list3.includes(s)) {
                        list3.push(s);
                    }
                }
            }
            if (a.sortLevel4) {
                for (let s of a.sortLevel4) {
                    if (!list4.includes(s)) {
                        list4.push(s);
                    }
                }
            }
        }
        setSortList1(list1.sort());
        setSortList2(list2.sort());
        setSortList3(list3.sort());
        setSortList4(list4.sort());
    }, [activity])

    useEffect(() => {
        if (!sortData || !activity || !goals) {return;}
        let sortedData = Object.values(activity);
        if (sortData.startDate) {
            sortedData = sortedData.filter(a => a.startDate >= sortData.startDate);
        }
        if (sortData.endDate) {
            sortedData = sortedData.filter(a => a.startDate <= sortData.endDate + 36000000);
        } 
        if (sortData.sortTitle1) {
            sortedData = sortedData.filter(a => a.sortLevel1 && a.sortLevel1.includes(sortData.sortTitle1));
        }
        if (sortData.sortTitle2) {
            sortedData = sortedData.filter(a => a.sortLevel2 && a.sortLevel2.includes(sortData.sortTitle2));
        }
        if (sortData.sortTitle3) {
            sortedData = sortedData.filter(a => a.sortLevel3 && a.sortLevel3.includes(sortData.sortTitle3));
        }
        if (sortData.sortTitle4) {
            sortedData = sortedData.filter(a => a.sortLevel4 && a.sortLevel4.includes(sortData.sortTitle4));
        }
        let goalArr = []
        for (let a of sortedData) {
            if (a.goal) {
                for (let gId of a.goal) {
                    if (!goalArr.includes(gId)) {
                        goalArr.push(gId);
                    }
                }
            }
        }
        let sortedGoals = Object.values(goals).filter(g => goalArr.includes(g.ts.toString()))
        setGoalsDisplay(sortedGoals);
        // console.log(sortedData);
        setActivityDisplay(sortedData);
    }, [sortData, activity, goals])

    function checkForSavedSort() {
        const savedSortData = JSON.parse(window.localStorage.getItem('sort'))
        if (!savedSortData) {return;}
        sortRef.current = savedSortData;
        setSortData(sortRef.current);
        if (sortRef.current.startDate) {
            fp1Ref.current.setDate(sortRef.current.startDate);
        }
        if (sortRef.current.endDate) {
            fp2Ref.current.setDate(sortRef.current.endDate);
        }
    }

    function printPage() {
        const title = `Log Reports${personId ? ` | ${myPeople[personId].fName} ${myPeople[personId].lName}` : ''}${sortData.startDate ? ` ${format(new Date(sortData.startDate), 'P')}` : ''}${sortData.endDate && sortData.startDate !== sortData.endDate ? ` - ${format(new Date(sortData.endDate), 'P')}` : ''}`
        let subhead = '';
        if (sortData.sortTitle1 || sortData.sortTitle2 || sortData.sortTitle3 || sortData.sortTitle4 ) {
            if (sortData.sortTitle1) {
                subhead += sortData.sortTitle1
            }
            for (let i=2; i<5; i++) {
                if (sortData[`sortTitle${i}`]) {
                    subhead += `, ${sortData[`sortTitle${i}`]}`
                }
            }
        }

        dispatch(setPrintData({
            'activity': activityDisplay,
            'goals': goalsDisplay, 
            'title': title,
            'subhead': subhead,
        }))
        // navigate(`/printreport/manager`)
        window.open(window.location.origin + `/printreport/${personId ? 'coach' : 'manager'}`, '_blank')
    }
    

    function saveData(key, value) {
        if (key === 'endDate' && value) {
            value += 86399999
        }
        sortRef.current = {...sortRef.current, ...{[key]: value}}
        
        if (key === 'startDate' && (!sortRef.current.endDate || value > sortRef.current.endDate)) {
            fp2Ref.current.setDate(value);
            sortRef.current = {...sortRef.current, ...{'endDate': value + 36000000}}
        }
        setSortData(sortRef.current);
        console.log(sortRef.current);
        window.localStorage.setItem('sort', JSON.stringify(sortRef.current));
    }

    function clearSort() {
        sortRef.current = {};
        setSortData(sortRef.current);
        fp1Ref.current.clear();
        fp2Ref.current.clear();
        window.localStorage.removeItem('sort');
    }

    return (
        <div className='reports-page'>
            {/* <div className='section-title with-icon'>
                <span>Reports</span>
                {
                    (personId && myPeople) &&
                    <PersonIcon myPeople={myPeople} personId={personId} />
                }
            </div>
            <hr /> */}
            <div className='reports-landing'>
                <div className='filter-row g-card'>
                    <div className='field small'>
                        <label>Start Date</label>
                        <input className="flatpickr flatpickr-input" type="text" id='startDate' />
                    </div>
                    <div className='field small'>
                        <label>End Date</label>
                        <input className="flatpickr flatpickr-input" type="text" id='endDate' />
                    </div>
                    {
                        (!personId) &&
                        <>
                        <div className='field small'>
                            <label>{currentOrg && currentOrg.termLevel1 ? currentOrg.termLevel1 : 'Sort 1'}</label>
                            <select value={sortData.sortTitle1 ?? ''} onChange={(e) => saveData('sortTitle1', e.target.value)}>
                                <option value=''>Choose...</option>
                                {
                                    (sortList1) &&
                                    sortList1.map(s => (
                                        <option key={s} value={s}>{s}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='field small'>
                            <label>{currentOrg && currentOrg.termLevel2 ? currentOrg.termLevel2 : 'Sort 2'}</label>
                            <select value={sortData.sortTitle2 ?? ''} onChange={(e) => saveData('sortTitle2', e.target.value)}>
                                <option value=''>Choose...</option>
                                {
                                    (sortList2) &&
                                    sortList2.map(s => (
                                        <option key={s} value={s}>{s}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='field small'>
                            <label>{currentOrg && currentOrg.termLevel3 ? currentOrg.termLevel3 : 'Sort 3'}</label>
                            <select value={sortData.sortTitle3 ?? ''} onChange={(e) => saveData('sortTitle3', e.target.value)}>
                                <option value=''>Choose...</option>
                                {
                                    (sortList3) &&
                                    sortList3.map(s => (
                                        <option key={s} value={s}>{s}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='field small'>
                            <label>{currentOrg && currentOrg.termLevel4 ? currentOrg.termLevel4 : 'Sort 4'}</label>
                            <select value={sortData.sortTitle4 ?? ''} onChange={(e) => saveData('sortTitle4', e.target.value)}>
                                <option value=''>Choose...</option>
                                {
                                    (sortList4) &&
                                    sortList4.map(s => (
                                        <option key={s} value={s}>{s}</option>
                                    ))
                                }
                            </select>
                        </div>
                        </>
                    }
                    <div className='button'>
                        <button className='g-button small-btn' title='Clear Sort Data' onClick={() => clearSort()}><FontAwesomeIcon icon={faBan} /></button>
                        <button className='g-button small-btn' title='Print This Report' onClick={() => printPage()}><FontAwesomeIcon icon={faPrint} /></button>                    
                    </div>
                
                </div>
                <div className="g-space-20"></div>
                {
                    (activityDisplay) &&
                    <ReportFullData activity={activityDisplay} goals={goalsDisplay} />
                }
            </div>
        </div>
    )
}

export default ReportsLanding