import React from 'react';
import './sidebar-container.styles.scss';
import SidebarLeft from './sidebar-left.component';
import { useSelector } from 'react-redux';

const SidebarLeftContainer = ({ closeSlider }) => {

    const myOrgs = useSelector(state => state.contentData.myOrgs);
    const currentOrg = useSelector(state => state.contentData.currentOrg);
    
    return (
        <div className='sidebar-left-container'>
            {
                (currentOrg && Object.values(myOrgs).length > 0) &&
                <div className='org-header'>
                    <img src={currentOrg.icon ?? require('assets/placeholder-icon-org.jpg')} />
                    <span>{currentOrg.name}</span>
                </div>
            }
            <div className=''>
                <SidebarLeft closeSlider={closeSlider} />
            </div>
        </div>
    )
}

export default SidebarLeftContainer