import React, { useEffect, useState } from 'react'
import Chart from "react-apexcharts";

const Chart3 = ({ recentActivity }) => {

    const [ boxHeight, setBoxHeight ] = useState(0);

    useEffect(() => {
        const box = document.querySelector('.hours-chart');
        const height = box.offsetHeight;
        setBoxHeight(height - 28);
    }, [])
  
    const [ data, setData ] = useState({
        series: [{
            name: "Logs",
            data: [0,0,0,0,0,0,0]
        }],
        options: {}
    });

    useEffect(() => {
        // console.log(recentActivity)
        const lastSevenDays = getLastSevenDaysOfWeek();
        const hoursPerDay = getHoursPerDay();
        const dataObj = {
            series: [{
                name: "Logs",
                data: hoursPerDay,
            }],
            options: {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                    // sparkline: {
                    //     enabled: true
                    // }
                },
                colors: ['var(--primary'],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                // title: {
                //     text: 'Hours per day',
                //     align: 'left'
                // },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: lastSevenDays,
                },
            },
        };
        setData(dataObj);
    }, [recentActivity]);

    function getLastSevenDaysOfWeek() {
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const today = new Date(); // Get the current date and time
        const lastSevenDays = [];
      
        for (let i = 6; i >= 0; i--) {
            const previousDay = new Date(today);
            previousDay.setDate(today.getDate() - i);
            lastSevenDays.push({ day: daysOfWeek[previousDay.getDay()], date: previousDay.toDateString() });
        }
      
        return lastSevenDays.map(d => d.day);
    }
      
    function getHoursPerDay() {
        if (!recentActivity) {return;}
        const currentDate = new Date();
        const sevenDaysAgo = new Date(currentDate);
        sevenDaysAgo.setDate(currentDate.getDate() - 7);
        sevenDaysAgo.setHours(0, 0, 0, 0);
        let countObj = {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0
        }
        const sevenDays = sevenDaysAgo.getTime()
        console.log(sevenDays)
        const sixDays = sevenDays + 86400000;
        const fiveDays = sixDays + 86400000;
        const fourDays = fiveDays + 86400000;
        const threeDays = fourDays + 86400000;
        const twoDays = threeDays + 86400000;
        const oneDay = twoDays + 86400000;
        for (let obj of Object.values(recentActivity)) {
            const d = obj.startDate;
            // console.log(d)
            if (!d) {continue;}
            if (d > sevenDays && d < sixDays) {
                countObj['0'] += +(obj.duration/60).toFixed(2);
            } else if (d > sixDays && d < fiveDays) {
                countObj['1'] += +(obj.duration/60).toFixed(2);
            } else if (d > fiveDays && d < fourDays) {
                countObj['2'] += +(obj.duration/60).toFixed(2);
            } else if (d > fourDays && d < threeDays) {
                countObj['3'] += +(obj.duration/60).toFixed(2);
            } else if (d > threeDays && d < twoDays) {
                countObj['4'] += +(obj.duration/60).toFixed(2);
            } else if (d > twoDays && d < oneDay) {
                countObj['5'] += +(obj.duration/60).toFixed(2);
            } else if (d > oneDay && d < new Date().getTime()) {
                countObj['6'] += +(obj.duration/60).toFixed(2);
            }
        }
        const arr = Object.values(countObj).map(o => o)
        // console.log(arr)
        return arr;
    }
  
    return (
        <div>
            {
                (data) &&
                    <Chart
                    options={data.options}
                    series={data.series}
                    type="line"
                    height={`${boxHeight}px`}
                    />
            }
        </div>
    )
}

export default Chart3