import { useMediaQuery } from "react-responsive";

export const mediaQueries = {
    mobile: {
        type: "maxWidth",
        size: 767,
    },
    tablet: {
        type: "maxWidth",
        size: 1023
    },
    desktop: {
        type: "minWidth",
        size: 1200
    }
}

export const IsMobileDevice = () => {
    const tf = useMediaQuery({maxWidth: 767});
    return tf ? true : false;
}

export const IsTabletDevice = () => {
    return useMediaQuery({query: "(min-device-width: 768px)"});
}

export const IsLaptop = () => {
    return useMediaQuery({query: "(min-device-width: 1024px)"});
}

export const IsDesktop = () => {
    return useMediaQuery({query: "(min-device-width: 1200px)"});
}

export const IsBigScreen = () => {
    return useMediaQuery({query: "(min-device-width: 1201px )"});
}

export const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
}

export const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
  }

export const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
}
  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
  }