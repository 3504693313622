import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getFunctions, httpsCallable } from "firebase/functions";

const config = {
    apiKey: "AIzaSyAiwP-EROdLE9BZFWjM9NOOJnl9AJXOJSg",
    authDomain: "guidepost-5cb13.firebaseapp.com",
    projectId: "guidepost-5cb13",
    storageBucket: "guidepost-5cb13.appspot.com",
    messagingSenderId: "522964444065",
    appId: "1:522964444065:web:69eab0b4736af328b521e0",
    clientId: "522964444065-quaspueuilffio8qbh9c3q585085f870.apps.googleusercontent.com",
    scopes: [
        "https://www.googleapis.com/auth/calendar.events",
        "https://www.googleapis.com/auth/calendar.readonly"
    ],
    discoveryDocs: [
        "https://calendar-json.googleapis.com/$discovery/rest?version=v3",
    ]
};


const app = initializeApp(config);

/**
 * 
 * AUTHENTICATION
 * 
 */


export const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export const signInWithGoogle = () => {
	console.log('signing in')
	signInWithPopup(auth, provider)
	.then((result) => {
		
	}).catch(err => console.log(err))
}

/**
 * 
 * EXPORT DATABASE REFS
 * 
 */

export const db = getFirestore();

export const rtDb = getDatabase();

/**
 * 
 * SIGN OUT
 * 
 */

export const signOut = async () => {
	await auth.signOut();
	return true;
}


/**
 *
 * Use Cloud Function
 *
 */

export const UseCloudFunction = async (command, data) => {
	console.log('cloud function', command)
  const functions = getFunctions();
	const processCommand = httpsCallable(functions, 'processCommand');
	const result = await processCommand({ 
		command: command,
		data: data
	})
  // console.log(result.data)
	const resData = result.data;
	return resData;
}