import React from 'react'
import './activity-item.styles.scss'

import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import DOMPurify from 'isomorphic-dompurify';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UseCloudFunction } from 'utils/firebase.utils';
import UIkit from 'uikit';


const ActivityItem = ({ activity, myGoals, showButtons, deleteActivity }) => {

    const profile = useSelector(state => state.userData.userProfile);
    const navigate = useNavigate();

    function showDetails(id) {
        const els = document.getElementsByClassName('details');
        for (let el of els) {
            if (el.id === `details-${id}`) {
                el.classList.toggle('hidden');    
            } else {
                el.classList.add('hidden');
            }
        }
    }

    function duplicateActivity(activity) {
        console.log(activity);
        window.localStorage.setItem('toDuplicate', JSON.stringify(activity))
        navigate('/coach/addactivity');
    }
    
    return (
        <>
        { (activity && myGoals) &&
        <div className='activity-item'>
            <div className='title-div'  onClick={() => showDetails(activity.ts)}>
                <p className='title'><b>{activity.title}</b></p>
                <p className='meta'>Date: {format(new Date(activity.startDate), 'P')}<br />
                {activity.activityCategory === 'other' ? 'Other Duties' : activity.activityCategory === 'visit' ? 'In-Person Visit' : 'Meeting'}</p>
            </div>
            <div className='info-div'  onClick={() => showDetails(activity.ts)}>
                <p className='meta'><FontAwesomeIcon icon={faClock} style={{'marginRight': '10px'}} />{+(activity.duration/60).toFixed(2)} hrs</p>
                <p className='meta'><b>Focus: </b>{activity.focusArea}</p>
                {/* <FontAwesomeIcon className='clickable' icon={faSquareArrowUpRight}  /> */}
            </div>
            <div className='details hidden' id={`details-${activity.ts}`}>
                <p className='meta'><b>Activity:</b> {activity.activityType}</p>
                {activity.goal && activity.goal !== 'none' ? <p className='meta'><b>Goal:</b> {myGoals[activity.goal] ? myGoals[activity.goal].title : ''}</p> : null}
                <hr />
                <span><b>Notes:</b></span><br />
                {<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activity.desc) }} />}
                {/* <div className='action-button-row'>
                    <FontAwesomeIcon icon={faPen}  />
                    <FontAwesomeIcon icon={faTrash} />    
                </div> */}
                {
                    showButtons &&
                    <div className='button-row'>
                        <hr />        
                        <button className='g-button tiny' onClick={() => navigate(`/coach/editactivity/${activity.ts}`)}>Edit</button>
                        <button className='g-button tiny' onClick={() => duplicateActivity(activity)}>Duplicate</button>
                        <button className='g-button tiny' onClick={() => deleteActivity(activity)}>Delete</button>
                    </div>
                }
                <hr />
                <p className='meta'><b>ID: </b>{activity.ts}</p>
            </div>
        </div>
        }
        </>
    )
}

export default ActivityItem