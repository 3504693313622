import React from 'react'
import './sidebar-admin.styles.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBug, faBuilding, faCity, faCode, faList, faPieChart, faUsers } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'

const SidebarAdmin = () => {

    const navigate = useNavigate();

    function goTo(path) {
        const els = document.getElementsByClassName('admin-menu-item')
        for (let el of els) {
            el.classList.remove('active')
        }
        document.getElementById(path).classList.add('active')
        navigate(path);
    }

    return (
        <div className='admin-side-menu'>
            <div className='admin-menu-items'>
                <div className='admin-menu-item' id='orgs' onClick={() => goTo('orgs')}>
                    <FontAwesomeIcon className='admin-menu-icon' icon={faCity} size='lg' />
                    Manage Organizations
                </div>
                <div className='admin-menu-item' id='users' onClick={() => goTo('users')}>
                    <FontAwesomeIcon className='admin-menu-icon' icon={faUsers} size='lg' />
                    Manage Users
                </div>
                <div className='admin-menu-item'>
                    <FontAwesomeIcon className='admin-menu-icon' icon={faPieChart} size='lg' />
                    User Data
                </div>
                <div className='admin-menu-item' id='bugs' onClick={() => goTo('bugs')}>
                    <FontAwesomeIcon className='admin-menu-icon' icon={faBug} size='lg' />
                    Bug Reports
                </div>
                <div className='admin-menu-item' id='functions' onClick={() => goTo('functions')}>
                    <FontAwesomeIcon className='admin-menu-icon' icon={faCode} size='lg' />
                    Admin Functions
                </div>
                <div className='admin-menu-item' id='logs' onClick={() => goTo('logs')}>
                    <FontAwesomeIcon className='admin-menu-icon' icon={faList} size='lg' />
                    Activity Logs
                </div>
            </div>
        </div>
    )
}

export default SidebarAdmin