import React, { useEffect, useRef, useState } from 'react';
import './new-goal-form.styles.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import TextEditor from 'components/text-editor/text-editor.component';
import UIkit from 'uikit';
import { UseCloudFunction } from 'utils/firebase.utils';
import { getGoals } from 'utils/getUserData';
import PeopleChoice from './people-choice.component';

const NewGoalForm = ({ handleSuccessfulCompletion }) => {

    const currentOrg = useSelector(state => state.contentData.currentOrg);
    const myPeople = useSelector(state => state.contentData.myPeople);
    const profile = useSelector(state => state.userData.userProfile);
    const [ textContent, setTextContent ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ exisitingDescription, setExistingDescription] = useState(null)
    const [ goalFormData, setGoalFormData ] = useState({})
    const [ stepCompleted, setStepCompleted ] = useState(false);
    const { sourcePersonId, goalId } = useParams();
    const navigate = useNavigate();
    const dataRef = useRef();
    let goalListener;

    useEffect(() => {

        return () => {
            if (goalListener) {
                goalListener();
            }
        }
    }, [])

    useEffect(() => {
        if (!sourcePersonId || !myPeople) {return;}
        saveGoalPerson(sourcePersonId, true)
    }, [sourcePersonId, myPeople])

    useEffect(() => {
        if (goalId) {
            console.log(goalId)
            getGoal()
        }
    }, [goalId])

    async function getGoal() {
        getGoals({'goalId': goalId, 'status': 'incomplete', 'callback': goalCallback, 'receiveListener': receiveGoalListener})
    }

    function goalCallback(goalData) {
        console.log(goalData)
        dataRef.current = goalData
        setGoalFormData(dataRef.current);
        const description = goalData.desc;
        setExistingDescription(description);
        // setTextContent(goalData.desc)
    }

    function receiveGoalListener(unsubscribe) {
        goalListener = unsubscribe;
    }

    function saveGoalData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}}
        setGoalFormData(dataRef.current);
    }

    function saveGoalPerson(id, add) {
        if (add) {
            if (dataRef.current && dataRef.current.personId && !dataRef.current.personId.includes(id)) {
                let newArr = [...dataRef.current.personId]
                newArr.push(id)
                dataRef.current = {...dataRef.current, ...{'personId': newArr}};
            } else {
                dataRef.current = {...dataRef.current, ...{'personId': [id]}};
            }
            
        } else {
            let newArr = dataRef.current.personId.filter(p => p !== id)
            dataRef.current = {...dataRef.current, ...{'personId': newArr}};
        }
        setGoalFormData(dataRef.current)
    }

    async function handleGoalSubmit() {
        if (!goalFormData.personId || !goalFormData.title || !goalFormData.focusArea) {
            UIkit.modal.alert('Please complete the form before saving goal.')
            return;
        }
        setLoading(true);
        console.log(goalFormData)
        let newData = {...goalFormData}
        newData.appId = profile.appId;
        newData.orgId = currentOrg.orgId;
        newData.status = 'incomplete';
        if (newData.ts) {
            newData.edited = new Date().getTime();
            if (stepCompleted) {
                newData.cycleStep++;
            }
        } else {
            newData.ts = new Date().getTime();
            newData.cycleStep = 0;
        }
        const res = await UseCloudFunction('saveGoal', {'goalObj': newData, 'edit': newData.edited ? true : false });
        if (res.error) {
            UIkit.modal.alert('Something went wrong, try again later.<br/>' + res.error);
        } else {
            UIkit.modal.alert('Goal saved successfully!');
            handleSuccessfulCompletion();
        }

    }

    return (
        <div className="goal-form-content">
            <div className='goal-header'>
                <h3>{goalFormData.ts ? 'Edit Goal' : 'Add a New Goal'}</h3>
                <span className='meta'>{goalId ? `ID: ${goalId}` : ''}</span>
            </div>
            <hr />
            <PeopleChoice formData={goalFormData} savePerson={saveGoalPerson} />
            <div className="g-space-20"></div>
            
            <div className='field'>
                <label>Focus Area<span className='required'>*</span></label>
                <select value={goalFormData.focusArea} onChange={(e) => saveGoalData('focusArea', e.target.value)}>
                    <option value=''>Choose...</option>
                    {
                        currentOrg.focusAreas &&
                        [...currentOrg.focusAreas].sort()
                        .map(a => (
                            <option key={a} value={a}>{a}</option>
                        )) 
                    }
                </select>
            </div>
            <div className="field">
                <label>Goal Title<span className='required'>*</span></label>
                <input type='text' value={goalFormData.title ?? ''} onChange={(e) => saveGoalData('title', e.target.value)} />
            </div>
            <div className="field">
                <label>Description</label>
                <TextEditor setTextContent={(e) => saveGoalData('desc', e)} textContent={goalFormData.desc} existingContent={exisitingDescription} />
            </div>
           
            <hr />
            <span className='required'>* = Required</span>
            <div className='buttons'>
                <button className='g-button' onClick={() => navigate(-1)}>Cancel</button>
                {
                    (!loading)
                    ?
                    <button className='g-button primary submit' onClick={() => handleGoalSubmit()}>Save Goal</button>
                    :
                    <button className='g-button disabled submit'><div data-uk-spinner='ratio: .5'></div></button>
                }
            </div>
        </div>
    )
}

export default NewGoalForm;