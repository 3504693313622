import React, { useEffect, useState } from 'react'
import './coach-landing.styles.scss'
import { useSelector } from 'react-redux'

import TipCard from './tips/tip-card.component'
import { switchOrgMenu } from 'components/ORG-MANAGER/org-sidebar-left/org-sidebar-left.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandshakeAngle, faPlusCircle, faSquareArrowUpRight, faStopwatch } from '@fortawesome/free-solid-svg-icons'
import { getCountsOfUniqueValues } from 'utils/getUniqueCounts'
import ChatDisplay from 'components/chat/chat-display.component'
import ChatInput from 'components/chat/chat-input.component'
import MyPeople from '../my-people/my-people-container.component'
import MyPeopleList from '../my-people/my-people-list.component'
import { useNavigate } from 'react-router-dom'
import { IsMobileDevice } from 'utils/responsiveQueries'

const CoachLanding = ({ currentOrg, recentActivity }) => {

    const myPeople = useSelector(state => state.contentData.myPeople);
    const profile = useSelector(state => state.userData.userProfile);
    const [ personCount, setPersonCount ] = useState(0);
    const [ hourTotal, setHourTotal ] = useState(0);
    const [ replyId, setReplyId ] = useState(null);
    const [ mobile, setMobile ] = useState(IsMobileDevice());
    const navigate = useNavigate();
    
    useEffect(() => {
        switchOrgMenu('coach-home')
    }, [])

    useEffect(() => {
        if (!recentActivity || !currentOrg) {return;}
        const personList = []
        for (let a of Object.values(recentActivity)) {
            if (a.personId) {
                for (let p of a.personId) {
                    if (!personList.includes(p)) {
                        personList.push(p);
                    }
                }
            }
        }
        console.log(personList);
        setPersonCount(personList.length);
        const hourCount = Object.values(recentActivity).filter(a => a.startDate <= new Date().getTime()).reduce((acc, curr) => acc + curr.duration, 0 );
        setHourTotal(hourCount);
    }, [recentActivity, currentOrg])

    return (
        <div className={`coach-landing`}>
            
            <div className={`main-content ${mobile && 'mobile'}`}>
                
                <div className='g-card welcome-card'>
                    <h4>Welcome back, {profile.fName}!</h4>
                    <div className='icon-div'></div>
                </div>
                
                <div className='g-card tip-card'>
                    <TipCard />
                </div>

                <div className='g-card data-box'>
                    <div className='data-point'>
                        <div className='big-data'>{personCount}</div>
                        <div className='descriptor'>{currentOrg.termPersonPlural ?? 'People'} coached this week</div>
                        {/* <div className='meta'>-4% compared to last week</div> */}
                    </div>
                    <div className='icon'>
                        <span className='icon-circle'>
                            <FontAwesomeIcon icon={faHandshakeAngle} size="2xl" />
                        </span>
                    </div>
                </div>

                <div className="g-card data-box">
                    <div className='data-point'>
                        <div className='big-data'>{+(hourTotal/60).toFixed(2)}</div>
                        <div className='descriptor'>Total hours this week</div>
                        {/* <div className='meta'>+5% compared to last week</div> */}
                    </div>
                    <div className='icon'>
                        <span className='icon-circle'>
                            <FontAwesomeIcon icon={faStopwatch} size="2xl" />
                        </span>
                    </div>
                </div>
                
                <div className='g-card people-list'>
                    
                    <div className='small-title-with-icon'>
                        <span>My People</span>
                        <FontAwesomeIcon icon={faPlusCircle} className='clickable' onClick={() => navigate('/coach/addperson')}/>
                    </div>
                    <hr className='no-margin' />
                    <div className="g-space-10"></div>
                    <div className='table-container'>
                        <div className="g-space-20"></div>
                        <MyPeopleList myPeople={myPeople} />
                    </div>
                </div>
                
                <div className='g-card daily-feed'>
                    <div className='small-title-with-icon'>
                        <span>Daily Feed</span>
                        {/* <FontAwesomeIcon icon={faSquareArrowUpRight} className='clickable'/> */}
                    </div>
                    <hr className='no-margin' />
                    <ChatDisplay setReplyId={setReplyId} replyId={replyId} />
				    <ChatInput replyId={replyId} />
                </div>
                
            </div>
        </div>
    )
}

export default CoachLanding