import { faBook, faBrain, faBullseye, faChampagneGlasses, faComments, faCompactDisc, faComputer, faEarListen, faEye, faFaceSmileBeam, faHandshakeAngle, faLineChart, faPersonChalkboard, faSpa, faUsers, faWrench } from '@fortawesome/free-solid-svg-icons'

export const tipsList = [
    {
        'text': `Build trust: Establish a strong and supportive relationship with teachers by demonstrating your commitment to their growth and success.`,
        'bg': 'linear-gradient(263deg, #639eb8, #e3253f)',
        'icon': faHandshakeAngle
    },
    {
        'text': `Active listening: Listen attentively to teachers' concerns and challenges to understand their individual needs better.`,
        'bg': 'linear-gradient(28deg, #1df4ff, #306845)',
        'icon': faEarListen
    },
    {
        'text': `Set clear goals: Collaborate with teachers to set specific, measurable, achievable, relevant, and time-bound (SMART) goals for their professional development.`,
        'bg': 'linear-gradient(329deg, #56f3e4, #0075bd)',
        'icon': faBullseye
    },
    {
        'text': `Observe and provide feedback: Regularly observe teachers in their classrooms and offer constructive feedback that focuses on strengths and areas for growth.`,
        'bg': 'linear-gradient(72deg, #48709c, #ee4a83)',
        'icon': faEye
    },
    {
        'text': `Model best practices: Demonstrate effective teaching techniques and strategies to help teachers visualize successful implementation.`,
        'bg': 'linear-gradient(96deg, #7da626, #d8e8f8)',
        'icon': faPersonChalkboard
    },
    {
        'text': `Offer differentiated support: Recognize that teachers have different levels of experience and expertise, and tailor your support accordingly.`,
        'bg': 'linear-gradient(301deg, #308bf6, #c5f1ed)',
        'icon': faHandshakeAngle
    },
    {
        'text': `Use data effectively: Help teachers analyze student data to identify areas for improvement and make data-driven instructional decisions.`,
        'bg': 'linear-gradient(238deg, #d5918d, #1b1aab)',
        'icon': faLineChart
    },
    {
        'text': `Facilitate professional learning communities (PLCs): Encourage teachers to collaborate and share best practices with their peers in a supportive environment.`,
        'bg': 'linear-gradient(278deg, #97c4c2, #a489de)',
        'icon': faUsers
    },
    {   
        'text': `Provide resources: Offer access to instructional materials, research, and professional development opportunities that align with teachers' needs.`,
        'bg': 'linear-gradient(224deg, #4f59e0, #3fc618)',
        'icon': faBook
    },
    {   
        'text': `Cultivate reflective practice: Encourage teachers to reflect on their teaching experiences and consider how to refine their instructional methods.`,
        'bg': 'linear-gradient(162deg, #eee39d, #e20dd7)',
        'icon': faBrain
    },
    {   
        'text': `Be a problem-solver: Assist teachers in finding solutions to challenges they encounter in the classroom.`,
        'bg': 'linear-gradient(109deg, #e6b169, #bb6c3d)',
        'icon': faWrench
    },
    {   
        'text': `Celebrate successes: Acknowledge and celebrate the progress and achievements of teachers as they develop their instructional skills.`,
        'bg': 'linear-gradient(191deg, #86cd4d, #f9dd10)',
        'icon': faChampagneGlasses
    },
    {   
        'text': `Offer regular follow-ups: Schedule consistent check-ins with teachers to assess progress, address concerns, and offer ongoing support.`,
        'bg': 'linear-gradient(66deg, #f2bfda, #8c67bd)',
        'icon': faComments
    },
    {   
        'text': `Stay updated on educational trends: Keep abreast of the latest developments in education to share relevant and innovative ideas with teachers.`,
        'bg': 'linear-gradient(103deg, #f5a725, #e80d97)',
        'icon': faLineChart
    },
    {   
        'text': `Support professional development: Advocate for opportunities for teachers to attend workshops, conferences, and training sessions to enhance their expertise.`,
        'bg': 'linear-gradient(348deg, #9fac0a, #b95706)',
        'icon': faPersonChalkboard
    },
    {   
        'text': `Foster a growth mindset: Encourage teachers to embrace a growth mindset, seeing challenges as opportunities for learning and improvement.`,
        'bg': 'linear-gradient(48deg, #50c4b4, #1f9399)',
        'icon': faLineChart
    },
    {   
        'text': `Emphasize self-care: Remind teachers of the importance of taking care of their well-being to maintain their passion and energy for teaching.`,
        'bg': 'linear-gradient(185deg, #4a5369, #59d678)',
        'icon': faSpa
    },
    {   
        'text': `Create a positive culture: Help establish a positive and supportive school culture where collaboration and continuous improvement are valued.`,
        'bg': 'linear-gradient(10deg, #6d6e31, #ecf554)',
        'icon': faFaceSmileBeam
    },
    {   
        'text': `Utilize technology: Introduce teachers to relevant educational technology tools and resources that can enhance their instruction and student engagement.`,
        'bg': 'linear-gradient(104deg, #dafefb, #126c24)',
        'icon': faComputer
    },
    {   
        'text': `Seek feedback from teachers: Continuously seek feedback from teachers about your coaching approach and adjust your strategies based on their input.`,
        'bg': 'linear-gradient(230deg, #c9f65e, #fe81f4)',
        'icon': faComments
    }
]