import { createSlice } from '@reduxjs/toolkit'
import { revertAll } from './userSlice'

const initialState = {
    myOrgs: null,
    orgList: null,
    orgUsers: null,
    currentOrg: null,
    sortCategories: null,
    myPeople: null,
    myGoals: null,
    unread: null,
    chat: null,
    impersonateData: null,
    printData: null,
    // delete
    tempActivity: null,
    tempGoals: null,
    reportData: null,
}

export const contentSlice = createSlice({
    name: 'content',
    initialState,
    extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
    reducers: {
        setMyOrgs: (state, action) => {
            state.myOrgs = action.payload
        },
        setOrgList: (state, action) => {
            state.orgList = action.payload
        },
        setOrgUsers: (state, action) => {
            state.orgUsers = action.payload
        },
        setCurrentOrg: (state, action) => {
            state.currentOrg = action.payload
        },
        setSortCategories: (state, action) => {
            state.sortCategories = action.payload
        },
        setMyPeople: (state, action) => {
            state.myPeople = action.payload
        },
        setMyGoals: (state, action) => {
            state.myGoals = action.payload
        },
        setUnread: (state, action) => {
            state.unread = action.payload
        },
        setChat: (state, action) => {
            state.chat = action.payload
        },
        setImpersonateData: (state, action) => {
            state.impersonateData = action.payload
        },
        setPrintData: (state, action) => {
            state.printData = action.payload
        },
        //delete
        setTempActivity: (state, action) => {
            state.tempActivity = action.payload
        },
        setTempGoals: (state, action) => {
            state.tempGoals = action.payload
        },
        setReportData: (state, action) => {
            state.reportData = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { 
    setMyOrgs,
    setOrgList,
    setOrgUsers,
    setCurrentOrg,
    setSortCategories,
    setMyPeople,
    setMyGoals,
    setUnread,
    setChat,
    setImpersonateData,
    setPrintData,
    //delete
    setTempActivity,
    setTempGoals,
    setReportData,
} = contentSlice.actions

export default contentSlice.reducer