import React, { useEffect } from 'react'
import './report-full-data.styles.scss'
import ReportRow1 from './report-row1.component'
import Chart4 from '../org-landing/chart4.component'
import ReportRow2 from './report-row2.component'
import ReportRow3 from './report-row3.component'

const ReportFullData = ({ activity, goals }) => {

    useEffect(() => {
        // console.log(activity);
        // console.log(goals);
    }, [activity, goals])

    return (
        <div className='report-rows'>
            {
                (activity && goals) &&
                <>
                    <ReportRow1 activity={activity} goals={goals} />
                    <div className="g-space-20"></div>
                    <div className='g-card focus-chart'>
                        <Chart4 recentActivity={activity} showCats={true} />
                    </div>
                    <div className='g-space-20'></div>
                    <ReportRow2 activity={activity} goals={goals} />
                    <div className='g-space-20'></div>
                    <ReportRow3 activity={activity} goals={goals} />
                </>
            }
        </div>
    )
}

export default ReportFullData