import React, { useEffect, useState } from 'react';
import './header-mobile.styles.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faChartLine, faCommentDots, faGear, faHandsBubbles, faHome, faUsers, faX } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import OrgSidebarLeft from 'components/ORG-MANAGER/org-sidebar-left/org-sidebar-left.component';
import OrgSidebarLeftContainer from 'components/ORG-MANAGER/org-sidebar-left/org-sidebar-container.component';
import UserDropdown from './user-dropdown.component';
import { useLocation } from 'react-router-dom';
import SidebarLeftContainer from 'components/COACH/sidebar-left/sidebar-container.component';
// import SidebarLeft from 'components/sidebar-left/sidebar-left.component';

const HeaderMobile = ({ isMobileDevice }) => {
    
    const [menuOpen, setMenuOpen] = useState(false);
    const profile = useSelector(state => state.userData.userProfile)
    const user = useSelector(state => state.userData.currentUser)
    const location = useLocation();
    
    useEffect(() => {
        // document.getElementById('slide-menu').classList.toggle('show');
    }, [menuOpen])

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const menuItems = [
        {
            'path': '',
            'label': 'My List'
        }
    ]

    return (
        <div className="mobile-header">
            <div className="mobile-header__logo">
                <img src={require('assets/logo_long.png')} alt="Logo" />
            </div>
            <div className="mobile-header__menu">
                
                <button className="mobile-header__menu-button" id="slide-menu" onClick={toggleMenu}>
                    <FontAwesomeIcon icon={faBars} />
                </button>
                <div className={`mobile-header__menu-items ${menuOpen ? "show": ""}`}>
                    <div className='close-button' onClick={() => setMenuOpen(!menuOpen)}><FontAwesomeIcon icon={faX} size='xl' /></div>
                    {
                        location.pathname.includes('manage') &&
                        <OrgSidebarLeftContainer closeSlider={() => setMenuOpen(!menuOpen)} />
                    }
                    {
                        location.pathname.includes('coach') &&
                        <SidebarLeftContainer closeSlider={() => setMenuOpen(!menuOpen)} />
                    }
                    
                </div>
                
            </div>

            <div className='mobile-bottom-menu'>
                {
                    location.pathname.includes('manage') &&
                    <>
                        <FontAwesomeIcon icon={faHome} size='xl' />
                        <FontAwesomeIcon icon={faUsers} size='xl' />
                        <FontAwesomeIcon icon={faChartLine} size='xl' />
                        <FontAwesomeIcon icon={faCommentDots} size='xl' />
                        <FontAwesomeIcon icon={faGear} size='xl' />
                    </>
                }
            </div>
            
        </div>
    );
};

export default HeaderMobile;