import React, { useEffect, useState } from 'react'
import Chart from "react-apexcharts";

const Chart2 = ({ recentActivity, showCats }) => {

    const [ boxHeight, setBoxHeight ] = useState(0);

    useEffect(() => {
        const box = document.querySelector('.focus-chart');
        if (box) {
            const height = box.offsetHeight;
            setBoxHeight(height - 48);
        }
    }, [])
  
    const [ data, setData ] = useState({
        series: [{
            name: "",
            data: []
        }],
        options: {}
    });
        
    useEffect(() => {
        if (!recentActivity) {return;}
        const filteredData = getFilteredData(recentActivity);
        // console.log(filteredData)
        const dataObj = {
            options: {
                chart: {
                    stacked: true,
                    width: '100%',
                    // height: '200px',
                    zoom: {
                        enabled: false
                    },
                },
                theme: {
                    // monochrome: {
                    //     enabled: true,
                    //     color: '#F27634',
                    //     shadeTo: 'light',
                    //     shadeIntensity: 0.65
                    //   }
                    palette: 'palette2'
                },
                dataLabels: {                            
                    formatter: (value) => {
                        return +value.toFixed(2)
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        dataLabels: {
                            total: {
                              enabled: true,
                              offsetX: 0,
                              style: {
                                fontSize: '13px',
                                // fontWeight: 900
                              }
                            }
                          }
                    },
                    dataLabels: {
                        enabled: false
                    },
                },
                xaxis: {
                    categories: ['Data'],  
                },
                yaxis: {
                    show: false,
                },
                tooltip: {
                    y: {
                        formatter: function (value) {
                            return +value.toFixed(2)
                        }
                    }
                },
                // title: {
                //     text: 'Focus Areas',
                //     align: 'left'
                // },
                legend: {
                    show: showCats,
                    fontSize: '10px',
                }
            },
            
            series: filteredData,
        }
        // console.log(dataObj);
        setData(dataObj);
    }, [recentActivity]);

    function getFilteredData() {
        let dataObj = {}
        for (let obj of recentActivity) {
            if (!obj.startDate || obj.startDate > new Date().getTime()) {continue;}
            if (dataObj[obj.focusArea]) {
                const count = dataObj[obj.focusArea].data[0]
                dataObj[obj.focusArea].data = [(count + +(obj.duration/60).toFixed(2))]
            } else {
                dataObj[obj.focusArea] = {
                    'name': obj.focusArea,
                    'data': [+(obj.duration/60).toFixed(2)],
                }
            }
        }
        return Object.values(dataObj).toSorted((a,b) => a.data[0] < b.data[0] ? 1 : -1);
    }
  
    return (
        <div>
            {
                (data) && 
                <Chart
                    options={data.options}
                    series={data.series}
                    type="bar"
                    height={`${boxHeight}px`}
                />
            }
        </div>
    )
}

export default Chart2