import React, { useState, useEffect } from 'react'
import './sidebar-left.styles.scss'

import { useNavigate } from 'react-router-dom';
import { mediaQueries } from 'utils/responsiveQueries';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faBug, faCalendarDay, faDiagramProject, faGear, faHome, faLineChart, faList, faListCheck, faPenSquare, faPenToSquare, faPlusCircle, faUsers } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import BugModal from 'components/bug-modal/bug-modal.component';


const SidebarLeft = ({ toggleMenu, closeSlider }) => {

    const profile = useSelector(state => state.userData.userProfile);
    const currentOrg = useSelector(state => state.contentData.currentOrg);
    const [ viewDrop, setViewDrop ] = useState(false);
    const [ showServices, setShowServices ] = useState(false);
    const [ showSearchModal, setShowSearchModal ] = useState(false);
    const [ showBugModal, setShowBugModal] = useState(false);
    const isMobileDevice = useMediaQuery({[mediaQueries.mobile.type]: mediaQueries.mobile.size});
    const navigate = useNavigate();

    function chooseMenuItem(e) {
        navigate(e);
        if (toggleMenu) {
            setTimeout(() => {        
                toggleMenu();
            }, 200);
        }
    }

    return (
        <div>
            <div className={`sidebar-container ${isMobileDevice ? 'mobile' : ''}`}>

                
                <div className="g-height-20"></div>
                {/* <div className="button-container">
                    
                    <button className={`start-button ${showSearchModal ? 'clicked' : ''}`} onClick={() => setShowSearchModal(true)}>
                        <span className="circle" aria-hidden="true">
                            <span className="icon arrow"></span>
                        </span>
                        <span className="button-text">Get Started</span>
                    </button>
                </div> */}
                <div className={`drop-container ${viewDrop ? 'active' : ''}`}>
                    <div className="drop-menu">
                        
                        {/* <div className="drop-menu-item" id="newFolder" onClick={() => setShowSearchModal(true)}> 
                            <img src={require('assets/icons/play.png')} className="drop-menu-item-icon" />
                            Add To WatchList
                        </div>
                        <div className="drop-menu-item" id="newFolder" onClick={() => navigate('/home/newsuggestion')}> 
                            <img src={require('assets/icons/suggestion.png')} className="drop-menu-item-icon" />
                            Recommend
                        </div>
                        <div className="drop-menu-item" id="newFolder" onClick={() => navigate('/home/newchat')}> 
                            <img src={require('assets/icons/chat.png')} className="drop-menu-item-icon" />
                            New Chat
                        </div> */}
                        
                        
                    </div>
                </div>
                <div className="g-height-20"></div>
                <div className="menu-container">
                    <div className='menu-item' id='coach-home' onClick={() => {navigate('/coach'); if (closeSlider) closeSlider()}}>
                        <FontAwesomeIcon className='menu-item-icon' icon={faHome} size='lg' />
                        Home 
                    </div>
                    
                    <div className='menu-item' id='coach-new-log' onClick={() => {navigate('addactivity'); if (closeSlider) closeSlider()}}>
                        <FontAwesomeIcon className='menu-item-icon' icon={faPenToSquare} size='lg' />
                        Record a New Log
                    </div>
                    {
                        (profile.gCalId) &&
                        <div className='menu-item' id='coach-import' onClick={() => {navigate('import'); if (closeSlider) closeSlider()}}>
                            <FontAwesomeIcon className='menu-item-icon' icon={faCalendarDay} size='lg' />
                            Import Logs
                        </div>
                    }
                    <div className='menu-item' id='coach-my-people' onClick={() => {navigate('mypeople'); if (closeSlider) closeSlider()}}>
                        <FontAwesomeIcon className='menu-item-icon' icon={faUsers} size='lg' />
                        My {(currentOrg && currentOrg.termPersonPlural) ? currentOrg.termPersonPlural : 'People'}
                    </div>
                    <div className='menu-item' id='coach-add-person' onClick={() => {navigate('addperson'); if (closeSlider) closeSlider()}}>
                        <FontAwesomeIcon className='menu-item-icon' icon={faPlusCircle} size='lg' />
                        Add a New {(currentOrg && currentOrg.termPersonSingular) ? currentOrg.termPersonSingular : 'Person'}
                    </div>
                    <div className='menu-item' id='coach-my-todos' onClick={() => {navigate('todolist'); if (closeSlider) closeSlider()}}>
                        <FontAwesomeIcon className='menu-item-icon' icon={faListCheck} size='lg' />
                        My To Do List
                    </div>
                    <div className='menu-item' id='org-menu-reports' onClick={() => {navigate('reports'); if (closeSlider) closeSlider()}}>
                        <FontAwesomeIcon className='menu-item-icon' icon={faLineChart} size='lg' />
                        Reports
                    </div>
                    <div className='menu-item disabled' id='coach-my-resources' onClick={() => {navigate('resources'); if (closeSlider) closeSlider()}}>
                        <FontAwesomeIcon className='menu-item-icon' icon={faBook} size='lg' />
                        My Resources
                    </div>
                    <div className='menu-item disabled' id='coach-my-projects' onClick={() => {navigate('projects'); if (closeSlider) closeSlider()}}>
                        <FontAwesomeIcon className='menu-item-icon' icon={faDiagramProject} size='lg' />
                        My Projects
                    </div>
                    
                    <div className='menu-item' id='coach-settings' onClick={() => {navigate('settings'); if (closeSlider) closeSlider()}}>
                        <FontAwesomeIcon className='menu-item-icon' icon={faGear} size='lg' />
                        My Settings
                    </div>
                    <div className='menu-item' id='coach-bug-report' onClick={() => {setShowBugModal(true)}}>
                        <FontAwesomeIcon className='menu-item-icon' icon={faBug} size='lg' />
                        Report a Bug
                    </div>
                    {/* <div className="menu-item" onClick={() => chooseMenuItem('/home')}>
                        <img src={require('assets/icons/home.png')} className='menu-item-icon' />
                        Home
                    </div>
                    <div className="menu-item" onClick={() => chooseMenuItem('watchlist')}>
                        <img src={require('assets/icons/play.png')} className='menu-item-icon' />
                        My WatchList
                    </div>
                    <div className="menu-item" onClick={() => chooseMenuItem('clans')}>
                        <img src={require('assets/icons/clans.png')} className='menu-item-icon' />
                        My Clans
                    </div>
                    <div className="menu-item" onClick={() => chooseMenuItem('chats')}>
                        <img src={require('assets/icons/chat.png')} className='menu-item-icon' />
                        My Chats
                    </div>
                    <div className="menu-item" onClick={() => setShowServices(true)}>
                        <img src={require('assets/icons/puzzle.png')} className='menu-item-icon' />
                        My Services
                    </div> */}
                    
                </div>
            </div>
            <BugModal show={showBugModal} cancel={() => setShowBugModal(false)} />
        </div>
    )
}

export default SidebarLeft;