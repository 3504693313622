import { ref, query as dbQuery, orderByChild, equalTo, limitToLast, onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";
import { and, collection, doc, query as fbQuery, getDoc, getDocs, onSnapshot, or, startAfter, where } from "firebase/firestore";
import { db, rtDb } from "utils/firebase.utils";

export async function getUserInfo(e) {
    let userRef;
    let statRef;

    if (e.appId && e.orgId) {
        userRef = doc(db, `users`, e.appId);
        statRef = doc(db, `coachStats`, `${e.appId}-${e.orgId}`);
    }
    let userDataSnap = await getDoc(userRef, doc => {
        return doc;
    })
    const userData = userDataSnap.data();
    let statDataSnap = await getDoc(statRef, doc => {
        return doc;
    })
    const statData = statDataSnap.data();
    return {...userData, ...{'stats': statData}}
}

export async function getInvites(e) {
    let inviteRef;
    if (e.email) {
        inviteRef = fbQuery(collection(db, `invites`), where('status', '==', 'pending'), where('email', '==', e.email));
    } else if (e.org) {
        inviteRef = fbQuery(collection(db, `invites`), where('status', '==', 'pending'), where('orgId', '==', e.org));
    }
    const docSnap = await getDocs(inviteRef);
    const invites = {};
    docSnap.forEach(doc => {
        if (!doc.exists) {return;}
        invites[doc.id] = doc.data();
        return;
    })
    return invites;
}

export async function getSortCategories(e) {
    let orgsRef;
    
    if (e.orgId) {
        orgsRef = doc(db, `sortCategories/${e.orgId}`)
    } 
    const unsubscribe = onSnapshot(orgsRef, doc => {
        const data = doc.data();
        if (data) {
            delete data.lastUpdate;
            delete data.orgId;
            e.callback(data);
        }
    })
    e.receiveListener(unsubscribe);
}

export async function getActivePeople(e) {
    let peopleRef;
    
    if (e.appId && e.orgId) {
        peopleRef = fbQuery(collection(db, `people`), where('appId', 'array-contains', e.appId), where('orgId', '==', e.orgId), where('status', '!=', 'deleted'))
    } else if (e.appId && e.lastId) {
        peopleRef = fbQuery(collection(db, `people`), where('appId', 'array-contains', e.appId), where('status', '!=', 'deleted'), startAfter(e.lastId))
    } else if (e.appId) {
        peopleRef = fbQuery(collection(db, `people`), where('appId', 'array-contains', e.appId), where('status', '!=', 'deleted'))
    } else if (e.personId) {
        peopleRef = fbQuery(collection(db, `people`), where('personId', '==', e.personId))
    }
    const unsubscribe = onSnapshot(peopleRef, docs => {
        docs.forEach(doc => {
            const data = doc.data();
            e.callback(data);
        });
    })
    e.receiveListener(unsubscribe);
}

export async function getGoals(e) {
    let goalsRef;
    // console.log(e)

    if (e.goalId) {
        goalsRef = fbQuery(collection(db, 'goals'), where('ts', '==', Number(e.goalId)));
    } else if (e.appId) {
        goalsRef = fbQuery(collection(db, `goals`), where('appId', '==', e.appId));
    } else if (e.orgId) {
        goalsRef = fbQuery(collection(db, `goals`), where('orgId', '==', e.orgId));
    } else if (e.personId) {
        goalsRef = fbQuery(collection(db, `goals`), where('personId', 'array-contains', e.personId));
    }
    const unsubscribe = onSnapshot(goalsRef, docs => {
        docs.forEach(doc => {
            const data = doc.data();
            e.callback(data);
        });
    })
    e.receiveListener(unsubscribe);
}

export async function getActivity(e) {
    let activityRef;

    if (e.activityId) {
        activityRef = fbQuery(collection(db, 'activity'), and(where('ts', '==', Number(e.activityId)), where('status', '==', e.status)));
    } else if (e.orgId && e.appId && e.startDate) {
        activityRef = fbQuery(collection(db, `activity`), and(where('orgId', '==', e.orgId), where('appId', '==', e.appId), where('startDate', '>=', e.startDate), where('status', '==', e.status)));
    } else if (e.orgId && e.appId) {
        activityRef = fbQuery(collection(db, `activity`), and(where('orgId', '==', e.orgId), where('appId', '==', e.appId), where('status', '==', e.status)));
    } else if (e.startDate && e.appId) {
        activityRef = fbQuery(collection(db, `activity`), and(where('appId', '==', e.appId), where('startDate', '>=', e.startDate), where('status', '==', e.status)));
    } else if (e.startDate && e.orgId) {
        activityRef = fbQuery(collection(db, `activity`), where('orgId', '==', e.orgId), where('startDate', '>=', e.startDate), where('status', '==', e.status));
    } else if (e.appId) {
        activityRef = fbQuery(collection(db, `activity`), and(where('appId', '==', e.appId), where('status', '==', e.status)));
    } else if (e.orgId) {
        activityRef = fbQuery(collection(db, `activity`), and(where('orgId', '==', e.orgId), where('status', '==', e.status)));
    } else if (e.personId) {
        activityRef = fbQuery(collection(db, `activity`), and(where('status', '==', e.status), or(where('personId', '==', e.personId), where('personId', 'array-contains', e.personId))));
    } 
    const unsubscribe = onSnapshot(activityRef, docs => {
        docs.forEach(doc => {
            const data = doc.data();
            // console.log(data)
            e.callback(data);
        });
    })
    e.receiveListener(unsubscribe);
}

export async function getChats(e) {
    let chatRef;
    // console.log(e)
    if (e.orgId) {
        chatRef = dbQuery(ref(rtDb, `chats/${e.orgId}`), limitToLast(10))
    } else if (e.orgId && e.appId) {
        chatRef = dbQuery(ref(rtDb, `chats/${e.orgId}`), orderByChild('appId'), equalTo(e.appId), limitToLast(10))
    }

    onChildAdded(chatRef, snap => {
        // console.log(snap.val())
        e.callback(snap.exists() ? snap.val() : null);
    });
    onChildChanged(chatRef, snap => {
        // console.log(snap.val())
        e.callback(snap.exists() ? snap.val() : null);
    });
    e.receiveListener(chatRef);
}

export async function getPendingPeople(e) {
    let peopleRef;
    
    if (e.orgId && e.appId) {
        // console.log(e)
        peopleRef = dbQuery(ref(rtDb, `pendingPeople/${e.orgId}`), orderByChild('appId'), equalTo(e.appId)) ///${e.orgId}/${e.appId}
    }
    else if (e.orgId && e.activeOnly) {
        peopleRef = dbQuery(ref(rtDb, `pendingPeople/${e.orgId}`), orderByChild('lastActivity'), startAfter(0))
    }  
    else if (e.orgId) {
        peopleRef = dbQuery(ref(rtDb, `pendingPeople/${e.orgId}`))
    }
    onChildAdded(peopleRef,  snap => {
        e.callback(snap.exists() ? snap.val() : null);
    })
    onChildChanged(peopleRef,  snap => {
        e.callback(snap.exists() ? snap.val() : null);
    })
    onChildRemoved(peopleRef, snap => {
        e.callback(snap.exists() ? snap.val() : null, true);
    })
}

export async function getToDoItems(e) {
    let todoRef;

    if (e.appId && e.personId && e.active) {
        todoRef = fbQuery(collection(db, 'todos'), where('appId', '==', e.appId), where('personId', '==', e.personId), where('status', '==', 'active'));
    } else if (e.appId && e.personId && e.closed) {
        todoRef = fbQuery(collection(db, 'todos'), where('appId', '==', e.appId), where('personId', '==', e.personId), where('status', '==', 'closed'));
    } else if (e.appId && e.personId) {
        todoRef = fbQuery(collection(db, 'todos'), where('appId', '==', e.appId), where('personId', '==', e.personId));
    } else if (e.appId && e.active) {
        todoRef = fbQuery(collection(db, 'todos'), where('appId', '==', e.appId), where('status', '==', 'active'));
    }else if (e.appId && e.orgId) {
        todoRef = fbQuery(collection(db, 'todos'), where('appId', '==', e.appId), where('orgId', '==', e.orgId));
    }

    const unsubscribe = onSnapshot(todoRef, docs => {
        docs.forEach(doc => {
            const data = doc.data();
            e.callback(data);
        });
    })
    e.receiveListener(unsubscribe);
}

export async function getCoachStats(e) {
    let statsRef;

    if (e.orgId && e.appId) {
        statsRef = doc(db, `coachStats/${e.appId}-${e.orgId}`);
        const unsubscribe = onSnapshot(statsRef, doc => {
            const data = doc.data();
            if (data) {
                e.callback(data);
            }
        })
        e.receiveListener(unsubscribe);
    } else if (e.orgId) {
        statsRef = fbQuery(collection(db, 'coachStats'), where('orgId', '==', e.orgId));
        const unsubscribe = onSnapshot(statsRef, docs => {
            docs.forEach(doc => {
                const data = doc.data();
                e.callback(data);
            });
        })
        e.receiveListener(unsubscribe);
    }

    
}