import UIkit from "uikit";
import { UseCloudFunction } from "./firebase.utils";

export async function declineInvite(invite, appId) {
    console.log(invite);
    const approve = await UIkit.modal.confirm('Are you sure you want to decline this invitation?')
    .then(() => { return true }, () => { return false })
    if (!approve) { return; }
    const now = new Date().getTime();
    const res = await UseCloudFunction('declineInvite', {'inviteData': invite, 'ts': now, 'appId': appId})
    return res;
}

export async function acceptInvite(invite, appId) {
    console.log(invite);
    const approve = await UIkit.modal.confirm('Are you sure you want to accept this invitation? By accepting the invitation, all of your current data will be transferred to the organization, and you will no longer have an individual account with this email address. To maintain this individual account, you will need to decline the invitation and have the organization\'s manager send a new invite using a different email address.')
    .then(() => { return true }, () => { return false })
    if (!approve) { return; }
    const now = new Date().getTime();
    const res = await UseCloudFunction('acceptInvite', {'inviteData': invite, 'ts': now, 'appId': appId})
    return res;
}

