import React, { useEffect, useRef, useState } from 'react'
import './calendar-page.styles.scss'
import Calendar from './calendar.component'
import { getActivity } from 'utils/getUserData'
import { useSelector } from 'react-redux'

const CalendarPage = () => {

    const profile = useSelector(state => state.userData.userProfile);
    const [ activity, setActivity ] = useState(null);
    const activityRef = useRef();
    let activityListener;

    useEffect(() => {
        getAllActivity();

        return () => {
            if (activityListener) {
                activityListener();
            }
        }
    }, [])

    async function getAllActivity() {
        await getActivity({'appId': profile.appId, 'status': 'active', 'callback': callback, 'receiveListener': receiveListener})
        function callback(data) {
            activityRef.current = {...activityRef.current, ...{[data.ts]: data}}
            setActivity(activityRef.current);
        }
        function receiveListener(unsubscribe) {
            activityListener = unsubscribe;
        }
    }
    return (
        <div className='calendar-page'>
            <Calendar activity={activity} />
        </div>
    )
}

export default CalendarPage