import React, { useState, useEffect } from 'react'
import './chat-display.styles.scss'

import { useSelector } from 'react-redux'

import useWindowResize from 'utils/windowSize'
import ChatItem from 'components/chat/chat-item.component'
import ChatView from 'components/chat/chat-view.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const ChatDisplay = ({ setReplyId }) => {

	const chat = useSelector(state => state.contentData.chat)
	const users = useSelector(state => state.contentData.orgUsers)
	const [ sortedChat, setSortedChat ] = useState(null)
	let [ width, height ] = useWindowResize();
	const [ chatToView, setChatToView ] = useState(null)
	const [ maxChats, setMaxChats ] = useState(5)

	useEffect(() => {
		if (chat) {
			const arr = Object.values(chat)
			arr.sort((a,b) => (a.latest < b.latest) ? 1 : -1)
			console.log('sorting chat')
			setSortedChat(arr)
            if (chatToView) {
                const id = chatToView.id
                setChatToView({...chat[id]})
            }
		} else {
            setSortedChat(null)
        }
	}, [chat])

    function goBack() {
		setChatToView(null)
		setReplyId(null)
	}
	
	return (
        <div>
            
            {
                chatToView &&
                <>
                    <div className="g-space-10"></div>
                    <p className='chat-back-button clickable' onClick={() => goBack()}><FontAwesomeIcon icon={faArrowLeft} /> Back</p>
                </>
                
            }
            <div className='chat-display' id='chat-display' style={{'height': height-361}}>
                {
                    (chatToView) 
                    ?
                    <ChatView mess={chatToView} setChatToView={setChatToView} setReplyId={setReplyId} />
                    
                    :
                    (sortedChat && users) 
                    ?
                    sortedChat.map((mess, index) => (
                        (index < maxChats) &&
                        <div key={mess.id}>
                            <ChatItem mess={mess} icon={users[mess.appId] ? users[mess.appId].icon : ''} setChatToView={setChatToView} setReplyId={setReplyId} replyButton={true} />
                        </div>
                    ))
                    :
                    'No messages yet...'
                }
                {
                    sortedChat && sortedChat.length > maxChats &&
                    <div className='more-button-div'>
                        <button className='g-button' onClick={() => setMaxChats(maxChats + 5)}>load more...</button>
                    </div>
                }
                <div className="g-space-40"></div>
            </div>
            
        </div>
	)
}

export default ChatDisplay;