import React, { useEffect, useState } from 'react'
import Chart from "react-apexcharts";

const PieChart = ({ data, querySelector }) => {
    const [ boxHeight, setBoxHeight ] = useState(0);

    useEffect(() => {
        const box = document.querySelector(querySelector);
        if (box) {
            const height = box.offsetHeight;
            setBoxHeight(height - 30);
        }
    }, [])
  
    return (
        <div>
            {
                (data) && 
                <Chart
                    options={data.options}
                    series={data.series}
                    type="pie"
                    // width="320px"
                    height={`${boxHeight}px`}
                />
            }
        </div>
    )
}

export default PieChart