import React from 'react'
import './user-dropdown.styles.scss'

import { Link } from 'react-router-dom'
import { version } from 'changelog-version'

const UserDropdown = ({ show }) => {
  return (
    <div className={show ? 'active dropdown-container' : 'dropdown-container'}>
        <div className="dropdown-menu">
            <Link to='/profile'>My Profile</Link>
            <a href='https://guidepost.tamingthetech.com/changelog.txt' target='_blank'>Change Log</a>
            <Link to='/logout'>Sign Out</Link>
            <p className='meta'>{version}</p>
        </div>
    </div>
  )
}

export default UserDropdown