import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import './coach.styles.scss'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Layout from 'components/layout/layout.comp';
import { getActivePeople, getActivity, getChats, getGoals, getSortCategories, getToDoItems } from 'utils/getUserData';
import { setChat, setMyGoals, setMyPeople, setOrgUsers, setSortCategories } from 'state/slices/contentSlice';
import CoachLanding from 'components/COACH/coach-landing/coach-landing.component';
import NewPerson from 'components/COACH/coach-landing/new-person/new-person.component';
import MyPeople from 'components/COACH/my-people/my-people-container.component';
import ViewPerson from 'components/COACH/view-person/view-person.component';
import MyResources from 'components/COACH/resources/my-resources.component';
import NewGoal from 'components/COACH/view-person/components/new-goal.component';
import UploadList from 'components/COACH/coach-landing/upload-list/upload-list.component';
import { getOrgUsersFromDb } from 'utils/getAdminData';
import InviteHandler from 'components/invite-handler/invite-handler.component';
import ToDoContainer from 'components/COACH/todo-list/todo-container.component';
import ActivityListFull from 'components/COACH/activity-list/activity-list-container.component';
import PendingPeopleList from 'components/COACH/pending-people/pending-people.component';
import { off } from 'firebase/database';
import CoachSettings from 'components/COACH/settings/coach-settings.component';
import NewActivity from 'components/COACH/view-person/components/new-activity.component';
import GoalList from 'components/COACH/goal-list/goal-list.component';
import CalendarPage from 'components/COACH/calendar/calendar-page.component';
import ImportLog from 'components/COACH/import/import-log.component';
import ReportsContainer from 'components/ORG-MANAGER/reports/reports-container.component';

const CoachPage = () => {

    const currentUser = useSelector(state => state.userData.currentUser);
    const userProfile = useSelector(state => state.userData.userProfile);
    const currentOrg = useSelector(state => state.contentData.currentOrg);
    const impersonateData = useSelector(state => state.contentData.impersonateData)
    const [ profileToView, setProfileToView ] = useState(null);
    const [ recentActivity, setRecentActivity ] = useState(null);
    const [ approvedRole, setApprovedRole ] = useState(false);
    const [ todoList, setTodoList ] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const peopleRef = useRef();
    const goalsRef = useRef();
    const chatRef = useRef();
    const recentActivityRef = useRef();
    const userListRef = useRef();
    const todoRef = useRef();
    const location = useLocation();
    let orgListener;
    let peopleListener;
    let goalsListener;
    let recentActivityListener;
    let chatListener;
    let todoListener;

    useLayoutEffect(() => {
        if (!currentUser || !userProfile) {
            navigate('/loading');
        }
    }, [currentUser, userProfile])

    useLayoutEffect(() => {
        if (!userProfile || !location) {return;}
        if (location.pathname.includes('/impersonate/')) {
            setProfileToView(impersonateData);
        } else {
            setProfileToView(userProfile);
        }
    }, [userProfile, impersonateData, location])

    useLayoutEffect(() => {

        return () => {
            if (orgListener) {
                console.log('detaching org listener');
                orgListener();
            }
            if (peopleListener) {
                console.log('detaching people listener');
                peopleListener();
            }
            if (goalsListener) {
                console.log('detaching goals listener');
                goalsListener();
            }
            if (todoListener) {
                console.log('detaching goals listener');
                todoListener();
            }
            if (recentActivityListener) {
                console.log('detaching activity listener');
                recentActivityListener();
            }
            if (chatListener) {
                console.log('detaching chat listener')
                off(chatListener);
            }
        }
    }, [])

    useEffect(() => {
        if (currentOrg) {checkRole();}
    }, [currentOrg])

    function checkRole() {
        if (currentOrg.role.includes('coach')) {
            setApprovedRole(true)
        } else {
            navigate('/manage');
        }
    }

    useLayoutEffect(() => {
        if (!currentOrg || !profileToView) { return; }
        dispatch(setMyPeople(null))
        dispatch(setMyGoals(null))
        getSortCats();
        getActivePeopleFromDB();
        getMyGoals();
        getRecentActivity();
        getChatContent();
        getOrgUsers();
        getToDoList();
    }, [currentOrg, profileToView])

    async function getSortCats() {
        getSortCategories({'orgId': currentOrg.orgId, 'callback': sortCategoriesCallback, 'receiveListener': receiveCategoriesListener});
        async function sortCategoriesCallback(e) {
            // console.log(e)
            const obj = {
                'sortLevel1': e.sortLevel1.sort(),
                'sortLevel2': e.sortLevel2.sort(),
                'sortLevel3': e.sortLevel3.sort(),
                'sortLevel4': e.sortLevel4.sort(),
            }
            dispatch(setSortCategories(obj))
        }
        function receiveCategoriesListener(unsubscribe) {
            orgListener = unsubscribe;
        }
    }

    async function getActivePeopleFromDB() {
        getActivePeople({'appId': profileToView.appId, 'orgId': currentOrg.orgId, 'callback': callback, 'receiveListener': receivePeopleListener})
        function callback(e) {
            // console.log(e)
            peopleRef.current = {...peopleRef.current, ...{[e.personId]: e}}
            dispatch(setMyPeople(peopleRef.current))
        }
        function receivePeopleListener(unsubscribe) {
            peopleListener = unsubscribe;
        }
    }

    async function getMyGoals() {
        goalsRef.current = {}
        dispatch(setMyGoals(goalsRef.current))
        getGoals({'appId': profileToView.appId, 'status': 'incomplete', 'callback': goalsCallback, 'receiveListener': receiveGoalsListener})
        async function goalsCallback(e) {
            // console.log(e)
            goalsRef.current = {...goalsRef.current, ...{[e.ts]: e}}
            dispatch(setMyGoals(goalsRef.current))
        }

        function receiveGoalsListener(unsubscribe) {
            goalsListener = unsubscribe;
        }
    }

    async function getRecentActivity() {
        recentActivityRef.current = null;
        setRecentActivity(recentActivityRef.current);
        const currentDate = new Date();
        const sevenDaysAgo = new Date(currentDate);
        sevenDaysAgo.setDate(currentDate.getDate() - 7);
        sevenDaysAgo.setHours(0, 0, 0, 0);
        getActivity({'appId': profileToView.appId, 'orgId': currentOrg.orgId,  'startDate': sevenDaysAgo.getTime(), 'callback': recentActivityCallback, 'receiveListener': receiveRecentActivityListener, 'status': 'active'})
        async function recentActivityCallback(data) {
            recentActivityRef.current = {...recentActivityRef.current, ...{[data.ts]: data}};
            setRecentActivity(recentActivityRef.current);
        }
        function receiveRecentActivityListener(unsubscribe) {
            recentActivityListener = unsubscribe;
        }
    }
    
    async function getChatContent() {
        chatRef.current = null;
        dispatch(setChat(chatRef.current));
        getChats({'orgId': currentOrg.orgId, 'callback': chatCallback, 'receiveListener': receiveChatListener});
        async function chatCallback(e) {
            chatRef.current = {...chatRef.current, ...{[e.ts]: e}}
            dispatch(setChat(chatRef.current))
        }
        function receiveChatListener(unsubscribe) {
            chatListener = unsubscribe;
        }
    }
    
    async function getOrgUsers() {
        getOrgUsersFromDb({'orgId': currentOrg.orgId, 'callback': userCallback});
        async function userCallback(user) {
            userListRef.current = {...userListRef.current, ...{[user.appId]: user}}
            dispatch(setOrgUsers(userListRef.current));
        }
    }

    function getToDoList() {
        getToDoItems({'appId': profileToView.appId, 'active': true, 'callback': callback, 'receiveListener': receiveToDoListener})
        function callback(data) {
            todoRef.current = {...todoRef.current, ...{[data.ts]: data}};
            setTodoList(todoRef.current);
        }
        function receiveToDoListener(unsubscribe) {
            todoListener = unsubscribe;
        }
    }

    return (
        <div>
            {
                (approvedRole) &&
                <div>
                    <Routes>
                        <Route path='' element={<CoachLanding currentOrg={currentOrg} recentActivity={recentActivity} todoList={todoList} />}></Route>
                        <Route path='mypeople/*' element={<MyPeople currentOrg={currentOrg} recentActivity={recentActivity} todoList={todoList} />}></Route>
                        <Route path='addperson' element={<NewPerson currentOrg={currentOrg} />}></Route>
                        <Route path='editperson/:personId' element={<NewPerson currentOrg={currentOrg} />}></Route>
                        <Route path='viewperson/:personId/*' element={<ViewPerson />}></Route>
                        <Route path='goals/:personId' element={<GoalList />}></Route>
                        <Route path='addgoal/:sourcePersonId' element={<NewGoal />}></Route>
                        <Route path='editgoal/:goalId' element={<NewGoal edit={true} />}></Route>
                        <Route path='activityList/:personId' element={<ActivityListFull />}></Route>
                        <Route path='addactivity' element={<NewActivity />}></Route>
                        <Route path='duplicateactivity/:activityToDuplicate' element={<NewActivity duplicate={true} />}></Route>
                        <Route path='addactivity/:sourcePersonId' element={<NewActivity />}></Route>
                        <Route path='editactivity/:sourceActivityId' element={<NewActivity edit={true} />}></Route>
                        <Route path='todolist/:personId' element={<ToDoContainer todoList={todoList} setTodoList={setTodoList} />}></Route>
                        <Route path='todolist' element={<ToDoContainer todoList={todoList} setTodoList={setTodoList} />}></Route>
                        <Route path='calendar/:personId' element={<CalendarPage />}></Route>
                        <Route path='pendinglist' element={<PendingPeopleList />}></Route>
                        <Route path='uploadlist' element={<UploadList />}></Route>
                        <Route path='calendar' element={'calendar'}></Route>
                        <Route path='import' element={<ImportLog />}></Route>
                        <Route path='reports/*' element={<ReportsContainer />}></Route>
                        <Route path='resources' element={<MyResources />}></Route>
                        <Route path='resources/add' element={'Add New Resource'}></Route>
                        <Route path='projects' element={'My Projects'}></Route>
                        <Route path='projects/add' element={'Add New Project'}></Route>
                        
                        <Route path='todolist/add' element={'Add To Do item'}></Route>
                        <Route path='settings' element={<CoachSettings />}></Route>
                        <Route path="*" element={<Navigate replace to="/404" />} />
                    </Routes>    
                    <InviteHandler />
                </div>
            }
        </div>
    )
}

export default CoachPage