import React, { useEffect, useRef, useState } from 'react';
import './activity-list-container.styles.scss';
import { getActivity } from 'utils/getUserData';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faClock } from '@fortawesome/free-solid-svg-icons';
import ActivityList from '../view-person/components/activity-list.component';
import { useSelector } from 'react-redux';
import PersonIcon from '../../shared/person-icon.component';
import ActivityItem from 'components/shared/activity-item/activity-item.component';

const ActivityListFull = () => {

    const myPeople = useSelector(state => state.contentData.myPeople);
    const myGoals = useSelector(state => state.contentData.myGoals);
    const [ fullActivity, setFullActivity ] = useState(null)
    const [ display, setDisplay ] = useState(null);
    const { personId } = useParams();
    const activityRef = useRef();
    const navigate = useNavigate();
    let activityListener;

    useEffect(() => {
        return () => {
            if (activityListener) {
                activityListener();
            }
        }
    }, [])

    useEffect(() => {
        if (!personId) {return;}
        getPersonActivity()
    }, [personId])

    async function getPersonActivity() {
        getActivity({
            'personId': personId,
            'callback': callback,
            'receiveListener': receiveListener,
            'status': 'active',
        })
        function callback(data) {
            activityRef.current = {...activityRef.current, ...{[data.ts]: data}}
            setFullActivity(activityRef.current);
            setDisplay(activityRef.current);
        }
        function receiveListener(unsubscribe) {
            activityListener = unsubscribe;
        }
    }

    return (
        <div className='activity-list-container'>
            <div className='lists'>
                <div className='back-div'>
                    <span className='back-icon' onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowLeft} />Back</span>
                    {
                        (personId && myPeople) &&
                        <PersonIcon personId={personId} myPeople={myPeople} />
                    }
                </div>
                <div className='g-double-col'> 
                    <div className='g-card'>
                        <h4>Upcoming Activities</h4><hr />
                        <div className='activity-content'>                        
                        {
                            (display) &&
                            Object.values(display).filter(a => a.startDate > new Date().getTime())
                            .map(a => (
                                <div key={a.ts}>
                                   <ActivityItem activity={a} showButtons={true} myGoals={myGoals} />
                                </div>
                            ))
                            // <ActivityList activity={Object.values(display).filter(a => a.startDate > new Date().getTime())} />
                            // Object.values(display).sort((a,b) => a.startDate > b.startDate ? 1 : -1)
                            // .filter(a => a.startDate > new Date().getTime())
                            // .map(a => (
                            //     <div key={a.ts} className='activity-item' onClick={() => navigate(`${a.ts}`)}>
                            //         <div>
                            //             <p className='title'><b>{a.title}</b></p>
                            //             <p className='meta'>Date: {format(new Date(a.startDate), 'P')}</p>
                            //         </div>
                            //         <div className='info-div'>
                            //             <p className='meta'><FontAwesomeIcon icon={faClock} style={{'marginRight': '10px'}} />{a.duration}</p>
                            //             <p className='meta'>Focus: {a.focusArea}</p>
                            //             {/* <FontAwesomeIcon className='clickable' icon={faSquareArrowUpRight}  /> */}
                            //         </div>
                                    
                                    
                            //     </div>
                            // ))
                        }
                        </div>
                    </div>
                    <div className='g-card'>
                        <h4>Passed Activities</h4><hr />
                        <div className='activity-content'>      
                        {
                            (display) &&
                            Object.values(display).filter(a => a.startDate < new Date().getTime())
                            .toSorted((a,b) => a.startDate < b.startDate ? 1 : -1)
                            .map(a => (
                                <div key={a.ts}>
                                    <ActivityItem activity={a} showButtons={true} myGoals={myGoals} />
                                </div>
                            ))
                            // Object.values(display).sort((a,b) => a.startDate > b.startDate ? 1 : -1)
                            // .filter(a => a.startDate < new Date().getTime())
                            // .map(a => (
                            //     <div key={a.ts} className='activity-item' onClick={() => navigate(`${a.ts}`)}>
                            //         <div>
                            //             <p className='title'><b>{a.title}</b></p>
                            //             <p className='meta'>Date: {format(new Date(a.startDate), 'P')}</p>
                            //         </div>
                            //         <div className='info-div'>
                            //             <p className='meta'><FontAwesomeIcon icon={faClock} style={{'marginRight': '10px'}} />{a.duration}</p>
                            //             <p className='meta'>Focus: {a.focusArea}</p>
                            //             {/* <FontAwesomeIcon className='clickable' icon={faSquareArrowUpRight}  /> */}
                            //         </div>
                                    
                                    
                            //     </div>
                            // ))
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) 
}

export default ActivityListFull