import React from 'react';
import './todo-list.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretLeft, faCheck, faXmarkSquare } from '@fortawesome/free-solid-svg-icons';
import UIkit from 'uikit';
import { UseCloudFunction } from 'utils/firebase.utils';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

const TodoList = ({ todoList, setTodoList, personId }) => {

    const currentOrg = useSelector(state => state.contentData.currentOrg);
    const myPeople = useSelector(state => state.contentData.myPeople);

    function showDesc(id) {

		const els = document.getElementsByClassName('todo-desc')
		for (let e of els) {
			// console.log(e.style.height)
			if (e.style.height > '0px' || `todo-${id}` == e.id) {
				// console.log(e.id)
				toggleDescDisplay(e.id)
			}
		}
	}

	function toggleDescDisplay(id) {
		
		console.log(id)
		const elem = document.getElementById(id)
		elem.style.height = '';
  		elem.style.transition = 'none';
  
  		const startHeight = window.getComputedStyle(elem).height;
  
		// Remove the collapse class, and force a layout calculation to get the final height
		elem.classList.toggle('hidden');
		const height = window.getComputedStyle(elem).height;
  
		// Set the start height to begin the transition
		elem.style.height = startHeight;
  
		// wait until the next frame so that everything has time to update before starting the transition
		requestAnimationFrame(() => {
			elem.style.transition = '';
		
			requestAnimationFrame(() => {
				elem.style.height = height
			})
		}) 
        // const carets = document.getElementsByClassName('caret')
        // for (let c of carets) {
        //     if (c.)
        //     // console.log(c)
        //     c.classList.remove('rotate')
        // }
        document.getElementById(`caret-${id}`).classList.toggle('rotate');
	}

    async function updateToDoItem(status, id) {
        const approved = await UIkit.modal.confirm(`Are you sure you want to ${status} this To-Do Item`).then(() => { return true; }, () => { return false; })
        if (!approved) {return;}
        const obj = {...todoList[id]}
        obj.status = status;
        const res = await UseCloudFunction('updateToDoItem', {'obj': obj})
        if (res.success) {
            const newTodos = {...todoList}
            newTodos[id].status = status;
            setTodoList(newTodos);
        }
    }

    return (
        <div className='todo-list'>
            {
                (todoList) &&
                Object.values(todoList).sort((a,b) => a.ts > b.ts ? 1 : -1)
                .filter(t => {
                    if (personId) {
                        if (personId === t.personId) {
                            if (t.status === 'active') {
                                return true;
                            } else {return false;}
                        } else { return false; }
                    } else { 
                        if (t.status === 'active') {
                            return true; 
                        } else { return false; }
                    }
                })
                .map(t => (
                    <div key={t.ts} className='g-list-item todo-item'>
                        <div className='title-row'>
                            <div className='title'>{t.title}</div>
                            <div className='icons'>
                                <div className='caret-div caret clickable' id={`caret-todo-${t.ts}`} onClick={() => showDesc(t.ts)}>
                                    <FontAwesomeIcon icon={faCaretLeft}  />
                                </div>
                                <FontAwesomeIcon className='clickable red' icon={faXmarkSquare} onClick={() => updateToDoItem('delete', t.ts)} />
                                <FontAwesomeIcon className='clickable green' icon={faCheck} onClick={() => updateToDoItem('close', t.ts)} />
                            </div>
                        </div>
                        <div className='todo-desc hidden' id={`todo-${t.ts}`}>
                            <div className="g-space-10"></div>
                            {
                                (t.personId !== 'none') &&
                                <><b>{currentOrg.termPersonSingular ?? 'Person'}: </b>{myPeople[t.personId].fName} {myPeople[t.personId].lName}<br /></>
                            }
                            {
                                (t.dueDate) &&
                                <><b>Due: </b>{format(new Date(t.dueDate), 'P')}<br /></>
                            }
                            <b>Details: </b>{t.details}<br />
                            
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default TodoList