import React, { useEffect, useLayoutEffect, useState } from 'react'
import './head-bar.styles.scss'

import { useSelector } from 'react-redux';
import UserIcon from 'components/user-icon/user-icon.component';
import UserDropdown from './user-dropdown.component';
import { useLocation, useNavigate } from 'react-router-dom';
import MyOrgsModal from 'components/ORG-MANAGER/my-orgs-modal/my-orgs-modal.component';

const HeadBar = () => {

    const currentOrg = useSelector(state => state.contentData.currentOrg);
    const currentUser = useSelector(state => state.userData.currentUser);
    const userProfile = useSelector(state => state.userData.userProfile);
    const myOrgs = useSelector(state => state.contentData.myOrgs);
    const [ showDropdown, setShowDropdown ] = useState(false);
    const [ showMyOrgs, setShowMyOrgs ] = useState(false);
    const [ managing, setManaging ] = useState([]);
    const [ coaching, setCoaching ] = useState([]);
    const [ currentCoach, setCurrentCoach ] = useState(false);
    const [ currentManager, setCurrentManager ] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useLayoutEffect(() => {
        if (!myOrgs) { return; }
        const managingList = Object.values(myOrgs).filter(org => org.role && (org.role.includes('primaryContact') || org.role.includes('orgManager')))
        const coachingList = Object.values(myOrgs).filter(org => org.role && org.role.includes('coach') && org.orgType !== 'ind')
        // console.log(managingList)
        if (managingList.length) {
            setManaging(managingList);
        }
        if (coachingList.length) {
            setCoaching(coachingList);
        }
    }, [myOrgs])

    useEffect(() => {
        if (!currentOrg || !coaching || !managing) { return; }
        setCurrentCoach(coaching.filter(c => c.orgId === currentOrg.orgId).length > 0);
        setCurrentManager(managing.filter(m => m.orgId === currentOrg.orgId).length > 0);
    }, [currentOrg, coaching, managing])

    return (
        <div className="head-bar-container">
            <div className="logo-container">
                    {/* <div className="g-card"> */}
                    
                    <img src={require("assets/logo_long.png")} alt="Logo" className="logo"  onClick={() => navigate('/loading')} />
                    <div className='menu-spacer'></div>
                    <h3>{location.pathname.includes('/manage') ? 'Organization Management' : 'Coach Portal'}</h3>
                {/* </div> */}
                
            </div>
            {/* <SearchContainer setShowSearchModal={setShowSearchModal} location='head-bar' /> */}
            <div className='head-menu-box'>
                <div className='head-menu-flex'>
                    <div className='head-menu'>
                        {
                            (!location.pathname.includes('/coach') && coaching && currentCoach) &&
                            <>
                                <div className='head-menu-item' onClick={() => navigate('/coach')}>
                                    Coach Home
                                </div>
                                <div className='menu-spacer'></div>
                            </>
                        }
                        {
                            (!location.pathname.includes('/manage') && managing && currentManager) &&
                            <>
                                <div className='head-menu-item' onClick={() => navigate('/manage')}>
                                    Manage Org
                                </div>
                                <div className='menu-spacer'></div>
                            </>
                        }
                        {
                            (Object.values(myOrgs).filter(o => o.orgType !== 'ind').length > 1) &&
                            <>    <div className='head-menu-item' onClick={() => setShowMyOrgs(true)}>
                                    Switch Organization
                                </div>
                                <div className='menu-spacer'></div>
                            </>
                        }
                        
                        {/* {
                            (location.pathname !== '/coach' && coaching && coaching.length > 1) 
                            ?
                            <>
                                <div className='head-menu-item' onClick={() => navigate('/coach')}>
                                    Switch 
                                </div>
                                <div className='menu-spacer'></div>
                            </>
                            :
                            (coaching)
                            <>
                                <div className='head-menu-item' onClick={() => navigate('/coach')}>
                                    Coach Home
                                </div>
                                <div className='menu-spacer'></div>
                            </>
                        }
                        {
                            (managing && managing.length > 1) 
                            ?
                            <div className='head-menu-item' onClick={() => setShowMyOrgs(true)}>
                                Manage My Orgs
                            </div>
                            :
                            (managing && managing.length === 1)
                            ?
                            <div className='head-menu-item' onClick={() => navigate(`/manage/${managing[0].orgId}`)}>
                                Manage {managing[0].name}
                            </div>
                            :
                            null
                        } */}
                        {
                            (userProfile && userProfile.admin) &&
                                <>
                                    
                                    <div className='head-menu-item' onClick={() => navigate('/admin')}>
                                        Admin
                                    </div>
                                </>
                        }
                    </div>
                    <div className='head-user-info'>
                        {
                            (currentUser && userProfile) &&
                            <>
                                
                                <div className='user-icon' onClick={() => setShowDropdown(!showDropdown)}>
                                    <UserIcon profile={userProfile} reverse={true} />
                                </div>

                            </>
                            
                        }
                    </div>
                </div>
                <UserDropdown show={showDropdown} />
                <MyOrgsModal show={showMyOrgs} cancel={() => setShowMyOrgs(false)} />
            </div>
        </div>
    )
}

export default HeadBar