export function getRandomLinearGradient() {
    // Helper function to generate a random number between min and max (inclusive)
    function getRandomNumber(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
  
    // Define the possible directions for the linear gradient
    const directions = ['to top', 'to right', 'to bottom', 'to left'];
  
    // Generate two random colors in hexadecimal format (#RRGGBB)
    const color1 = '#' + getRandomNumber(0, 255).toString(16).padStart(2, '0') +
                  getRandomNumber(0, 255).toString(16).padStart(2, '0') +
                  getRandomNumber(0, 255).toString(16).padStart(2, '0');
  
    const color2 = '#' + getRandomNumber(0, 255).toString(16).padStart(2, '0') +
                  getRandomNumber(0, 255).toString(16).padStart(2, '0') +
                  getRandomNumber(0, 255).toString(16).padStart(2, '0');
  
    // Choose a random direction from the directions array
    const direction = directions[getRandomNumber(0, directions.length - 1)];
  
    // Combine everything into a linear gradient string
    const linearGradient = `linear-gradient(${direction}, ${color1}, ${color2})`;
  
    return linearGradient;
}