import React, { useEffect, useRef, useState } from 'react';
import './activity-logs.styles.scss';
import { limitToLast, off, onChildAdded, ref, query as rtQuery } from 'firebase/database';
import { format } from 'date-fns';
import { rtDb } from 'utils/firebase.utils';
import { useSelector } from 'react-redux';
import { getActivityLogs, getAllUsersFromDb } from 'utils/getAdminData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

const AdminActivityLogs = () => {

    const [ activity, setActivity ] = useState(null);
    const [ toDisplay, setToDisplay ] = useState(null);
    const [ fullUserList, setFullUserList ] = useState(null);
    const [ userList, setUserList ] = useState(null);
    const [ filterData, setFilterData ] = useState({})
    const activityRef = useRef();
    const userListRef = useRef();
    const filterDataRef = useRef();
    let userListener;
    let logListener;

    useEffect(() => {
		setTimeout(() => {
            document.getElementById('logs').classList.add('active');
        }, 500)
		getRecentLogs();
        getUsers();

        return () => {
            if (logListener) {
                off(logListener);
            }
        }
	}, [])

    async function getRecentLogs() {
        getActivityLogs({'callback': logCallback, 'receiveListener': receiveLogListener})
        function receiveLogListener(unsubscribe) {
            logListener = unsubscribe;
        }
    }

    function logCallback(data) {
        // console.log(data);
        activityRef.current = {...activityRef.current, ...{[data.ts]: data}}
        setActivity(activityRef.current)
        setToDisplay(activityRef.current)
    }

    async function getUsers() {
        const obj = {
            'callback': function (e) {usersCallback(e)},
            'lastUser': null,
            'receiveListener': function (e) {receiveUserListener(e)},
        }
        getAllUsersFromDb(obj);
        function receiveUserListener(unsubscribe) {
            userListener = unsubscribe;
        }
        async function usersCallback(newUser) {
            // console.log(newUser);
            userListRef.current = {...userListRef.current, ...{[newUser.appId]: newUser}}
            setUserList(userListRef.current);
        }
    } 


    function saveFilterData(key, value) {
        filterDataRef.current = {...filterDataRef.current, ...{[key]: value}}
        setFilterData(filterDataRef.current);
    }

    async function filterLogs() {
        if (logListener) { off(logListener); }
        activityRef.current = null;
        setActivity(activityRef.current)
        setToDisplay(activityRef.current)
        const filterObj = {...{'callback': logCallback, 'receiveListener': receiveLogListener}, ...filterData}
        console.log(filterObj);
        getActivityLogs(filterObj)
        function receiveLogListener(unsubscribe) {
            logListener = unsubscribe;
        }
    }

    return (
        <div className='activity-logs'>
            <div className='section-title'>
                <span>Activity Logs</span>
            </div>
            <div className="g-space-20"></div>
            <div className="filter-row">
                <div className='field'>
                    <select className='small' onChange={(e) => saveFilterData('appId', e.target.value)}>
                        <option value=''>Choose a user</option>
                        {
                            (userList) &&
                            Object.values(userList).sort((a, b) => (a.lName > b.lName) ? 1 : (a.lName === b.lName) ? ((a.fName > b.fName) ? 1 : -1) : -1 )
                            .map(user => (
                                <option key={user.appId} value={user.appId}>{user.lName}, {user.fName}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="field">
                    <select className='small' onChange={(e) => saveFilterData('type', e.target.value)}>
                        <option value=''>Choose a log type</option>
                        <option value='error'>Error</option>
                        <option value='success'>Success</option>
                    </select>
                </div>

                <button className='g-button small-btn primary' onClick={() => filterLogs()}>Filter Results<FontAwesomeIcon icon={faFilter} /></button>
            </div>
            <div className="g-space-20"></div>
            <table className='g-table logs-table'>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>User</th>
                        <th>Type</th>
                        <th>Function</th>
                        <th>Message</th>
                        <th>Data</th>
                    </tr>
                </thead>
                <tbody>
                {
                    (toDisplay && userList) && 
                    Object.values(toDisplay).sort((a,b) => a.ts < b.ts ? 1 : -1)
                    .map(a => (
                        <tr key={a.ts}>
                            <td>{format(new Date(a.ts), 'Pp')}</td>
                            <td>{userList[a.user] ? `${userList[a.user].fName} ${userList[a.user].lName}` : a.user}</td>
                            <td>{a.type}</td>
                            <td>{a.functName}</td>
                            <td>{a.message ?? a.action}</td>
                            <td>{
                                (typeof a.data === 'object') 
                                ? 
                                Object.keys(a.data).map(k => (
                                    <div key={k}>
                                        <span>
                                            <b>{k}: </b>{a.data[k]}
                                        </span><br />
                                    </div>
                                ))
                                : 
                                a.data ?? null}</td>
                        </tr>
                    ))
                      
                }
                </tbody>
            </table>
        
        </div>
    )
}

export default AdminActivityLogs;