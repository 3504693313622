import React, { useEffect, useState } from 'react'
import './import-item.styles.scss';
import { format } from 'date-fns';
import Modal from 'components/modal/modal.component';
import NewActivityForm from '../view-person/components/new-activity-form.component';
import { useSelector } from 'react-redux';

const ImportItem = ({ event, setImportContent, activity }) => {

    const profile = useSelector(state => state.userData.userProfile);

    useEffect(() => {
        if (!activity) {return;}
        // console.log(Object.values(activity).filter(a => a.importId === event.importId))
    }, [activity])

    return (
        <div className={`import-item g-list-item ${Object.values(activity).filter(a => a.importId === event.importId).length > 0 ? 'disabled' : 'clickable'}`} style={{'borderLeft': `4px solid ${profile ? profile.gCalColor : '#fff'}`}}onClick={() => setImportContent(event)}>
            <div className='title'><b>{event.title}</b></div>
            <div className='date meta'>{format(new Date(event.startDate), 'Pp')}</div>
        </div>
    )
}

export default ImportItem