import React, { useEffect, useState } from 'react'
import './my-people-container.styles.scss'
import { switchOrgMenu } from 'components/ORG-MANAGER/org-sidebar-left/org-sidebar-left.component'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowUpRightFromSquare, faMagnifyingGlass, faPlusCircle, faSquareArrowUpRight } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import MyPeopleList from './my-people-list.component';

const MyPeopleContainer = () => {

    const currentOrg = useSelector(state => state.contentData.currentOrg);
    const myPeople = useSelector(state => state.contentData.myPeople);
    const [displayList, setDisplayList] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        switchOrgMenu('coach-my-people')
    }, [])

    

    

    return (
        <div className='my-people-container'>
            <div className='my-people'>
                <div className='back-div'><span className='back-icon' onClick={() => navigate('/coach')}><FontAwesomeIcon icon={faArrowLeft} />Back</span></div>
                <div className='g-card'>
                    <div className='section-title with-icon'>
                        My {(currentOrg && currentOrg.termPersonPlural) ? currentOrg.termPersonPlural : 'People'}
                        <FontAwesomeIcon icon={faPlusCircle} size='xl' className='add-button' onClick={() => navigate('/coach/addperson')} />
                    </div>
                    <hr className='small-margin' />
                    {/* <div className="g-space-20"></div> */}
                    
                    <MyPeopleList myPeople={myPeople} />
                </div>
            </div>
        </div>
    )
}

export default MyPeopleContainer;