import React from 'react';
import './person-icon.styles.scss';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const PersonIcon = ({ myPeople, personId }) => {
    return (
        <div className='person-icon'>
            {myPeople[personId].fName} {myPeople[personId].lName}
            <div className='grad-icon' style={{'background': myPeople[personId].grad}}><FontAwesomeIcon icon={faUser} /></div>
        </div>
    )
}

export default PersonIcon