import React from 'react'
import { UseCloudFunction } from 'utils/firebase.utils'

const AdminFunctions = () => {

    async function updateActivity() {
        const res = await UseCloudFunction('updateActivity', {'obj': 'data'});
        console.log(res);
    }

    async function updateActivityAndGoals() {
        const res = await UseCloudFunction('updateActivityAndGoals', {'obj': 'data'});
        console.log(res);
    }

    return (
        <div>
            <div>
                <button className='g-button' onClick={() => updateActivityAndGoals()}>Update Activity and Goals for multiple elements</button>
            </div>
            <div>
                <button className='g-button' onClick={() => updateActivity()}>Update Activity Sort Titles</button>
            </div>
        </div>
    )
}

export default AdminFunctions