import React, { forwardRef, useEffect, useRef, useState } from 'react'
import './print-screen.styles.scss';
import ReportsLanding from './reports-landing.component'
import { useReactToPrint } from 'react-to-print';
import { useDispatch, useSelector } from 'react-redux';
import { setPrintData } from 'state/slices/contentSlice';
import ThreeDotsLoader from 'components/loader/three-dots-loader.component';
import ReportFullData from './report-full-data.component';
import { useParams } from 'react-router-dom';

const PrintScreen = () => {

    const printData = useSelector(state => state.contentData.printData);
    const componentRef = useRef();
    const dispatch = useDispatch();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const ContentToPrint = forwardRef((props, ref) => {
        return <div ref={ref}>
            <ReportFullData {...printData} />
        </div>
    })

    useEffect(() => {
        if (!printData) {return;}
        setTimeout(() => {
            window.print();
        }, 1500);
        // return () => {
        //     dispatch(setPrintData(null));
        // }
    }, [printData])
    
    return (
        <div className='print-page'>
            { 
                (printData) ?
                <div className='print-content'>
                    <h2>{printData.title}</h2>
                    {
                        (printData.subhead) &&
                        <h4>{printData.subhead}</h4>
                    }
                    {/* <button className='g-button' onClick={handlePrint}>Print this page</button> */}
                    {
                        (ContentToPrint) &&
                        <div>
                            {/* <button className='g-button' onClick={handlePrint}>Print this page</button> */}
                            <ContentToPrint ref={componentRef} />
                        </div>
                    }
                </div>
                :
                <div className='center'><ThreeDotsLoader /></div>
            }
        </div>
    )
}

export default PrintScreen