import React, { useState, useEffect } from 'react'
import './chat-view.styles.scss'

import { useSelector } from 'react-redux'
import ChatItem from './chat-item.component'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowLeft, faTurnDown } from '@fortawesome/free-solid-svg-icons'

import { getDatabase, ref as dbRef, onValue, off } from "firebase/database";

const ChatView = ({ mess, setChatToView, setReplyId }) => {

	const users = useSelector(state => state.contentData.orgUsers)
	const [ icon, setIcon ] = useState(null)
	const [ replies, setReplies ] = useState(null) 
	const rtDb = getDatabase();
	const currentOrg = useSelector(state => state.contentData.currentOrg)

	useEffect(() => {
		setIcon(users[mess.appId].icon)
		const repliesRef = dbRef(rtDb, `replies/${currentOrg.orgId}/${mess.id}`)
		onValue(repliesRef, (snapshot) => {
			// console.log(snapshot.val())
			// const reply = snapshot.val()
			// console.log(reply)
			
			// const newObj = {
			// 	[reply.id]: reply
			// }
			// newReplies = {...newObj, ...replies}
			// setReplies(newMatchupList)
			setReplies(snapshot.val())
		});

		return () => {
			console.log('unmounting')
			console.log('removing listener')
			off(repliesRef)
		}
	}, [mess, users, currentOrg.orgId, rtDb])
	
	return (

		<div className='chat-view'>
			
			{
				(mess && icon)
				?
				// <div className='g-box'>
					<ChatItem mess={mess} icon={icon} setChatToView={setChatToView} />
				// </div>
				: ''
			}
			<div className='replies'>
				{
					(replies && users)
					?
					Object.values(replies).sort((a,b) => (a.ts > b.ts) ? 1 : -1)
                    .map(reply => (
						<div key={reply.id}>
							<ChatItem mess={reply} icon={users[reply.appId].icon} replyButton={false} isReply={true} />
							{/* <hr className='mid-item' /> */}
						</div>
					))
					: ''
				}
                <p>Reply below <FontAwesomeIcon icon={faTurnDown} style={{'transform': 'translate(10px, 8px)'}} /></p>
                <div className="g-height-30"></div>
			</div>
		</div>
	)
}

export default ChatView;