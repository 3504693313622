import React, { useState } from 'react'
import './report-row2.styles.scss'
import PieChart from './pie-chart.component'
import { useEffect } from 'react'

export function createPieChartData(filter) {
    const dataObj = {
        series: Object.values(filter).sort((a,b) => a.data < b.data ? 1 : -1).map(a => a.data),
        options: {
            chart: {
                width: 380,
                type: 'pie',
            },
            legend: {
                position: 'bottom',
                fontSize: '10px',
            },
            labels: Object.values(filter).map(a => a.name),
            theme: {
                // monochrome: {
                //     enabled: true,
                //     color: '#F27634',
                //     shadeTo: 'light',
                //     shadeIntensity: 0.65
                //   }
                palette: 'palette2'
            },
            responsive: [{
                breakpoint: 280,
                options: {
                    chart: {
                        width:1200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
    }
    return dataObj;
}

const ReportRow2 = ({ activity, goals }) => {

    const [ meetingData, setMeetingData ] = useState();
    const [ visitData, setVisitData ] = useState();
    const [ otherData, setOtherData ] = useState();

    useEffect(() => {
        if (!activity || !goals) {return;}
        const meetings = getChartData('meeting');
        setMeetingData(meetings);
        const visits = getChartData('visit');
        setVisitData(visits);
        const other = getChartData('other');
        setOtherData(other);
    }, [activity, goals]);

    function getChartData(e) {
        let filter = {}
        for (let obj of activity) {
            if (!obj.startDate || obj.startDate > new Date().getTime()) {continue;}
            if (obj.activityCategory === e) {
                if (filter[obj.activityType]) {
                    const count = filter[obj.activityType].data;
                    filter[obj.activityType].data = (count + +(obj.duration/60).toFixed(2))
                } else {
                    filter[obj.activityType] = {
                        'name': obj.activityType,
                        'data': +(obj.duration/60).toFixed(2),
                    }
                }
            }
        }
        return createPieChartData(filter);
    }

    

    return (
        <div className='report-row2'>
            <div className='g-card pie-chart'>
                <b>Meetings By Type</b>
                <PieChart data={meetingData} querySelector='.pie-chart' />
            </div>
            <div className='g-card'>
                <b>Visits By Type</b>
                <PieChart data={visitData} querySelector='.pie-chart' />
            </div>
            <div className='g-card'>
                <b>Other Duties</b>
                <PieChart data={otherData} querySelector='.pie-chart' />
            </div>
        </div>
    )
}

export default ReportRow2