import React, { useEffect, useState } from 'react';
import './invite-handler.styles.scss';
import { getInvites } from 'utils/getUserData';
import InviteModal from 'components/COACH/invite-modal.component.jsx/invite-modal.component';
import UIkit from 'uikit';
import { UseCloudFunction } from 'utils/firebase.utils';
import { setUserProfile } from 'state/slices/userSlice';
import { setMyOrgs } from 'state/slices/contentSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const InviteHandler = () => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ displayInvite, setDisplayInvite ] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        getInviteData();
    }, [])

    async function getInviteData() {
        const invites = await getInvites({'email': userProfile.email});
        if (Object.keys(invites).length) {
            const newInvite = Object.values(invites).sort((a,b) => a.ts > b.ts ? 1 : -1)[0]
            // console.log(newInvite);
            setDisplayInvite(newInvite);
        }
    }

    async function processInvite(e) {
        // console.log(e);
        if (e.error) {
            UIkit.modal.alert('Sorry, you\'re already a member of this organization.');
            setDisplayInvite(null);
        } else if (e.success) {
            UIkit.modal.alert('Congratulations! You\'re part of the team!');
            setDisplayInvite(null);
            navigate('/loading/refresh');
        }
    }

    return (
        <div>
            <InviteModal invite={displayInvite} callback={processInvite} />
        </div>
    )
}

export default InviteHandler