import React from 'react';
import './unauth-layout.styles.scss';
import UnauthHeader from './unauth-head.component';

const UnauthLayout = ({ children }) => {
    return (
        <div className='unauth-layout'>
            <UnauthHeader />
            <div className='g-container'>
                {children}
            </div>
        </div>
    )
}

export default UnauthLayout