import React, { useEffect, useRef, useState } from 'react';
import './coach-view.styles.scss';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { getActivePeople, getActivity, getGoals, getUserInfo } from 'utils/getUserData';
import MyPeopleList from 'components/COACH/my-people/my-people-list.component';
import AdminPersonView from './person-view.component';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBullseye, faHandshakeAngle, faPeopleCarryBox, faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import AdminPeopleList from './people-list.component';
import ReportsLanding from '../reports/reports-landing.component';
import { switchOrgMenu } from '../org-sidebar-left/org-sidebar-left.component';
import DeepDiveTable from '../reports/deep-dive/deep-dive-table';
import PersonIcon from 'components/shared/person-icon.component';

const CoachView = () => {
    
    const currentOrg = useSelector(state => state.contentData.currentOrg);
    const { personId, appId } = useParams()
    const [ coach, setCoach ] = useState({});
    const [ activity, setActivity ] = useState();
    const [ personActivity, setPersonActivity ] = useState();
    const [ goals, setGoals ] = useState({});
    const [ people, setPeople ] = useState();
    const [ personCount, setPersonCount ] = useState();
    const [ totalTime, setTotalTime ] = useState();
    const [ coachingTime, setCoachingTime ] = useState();
    const [ completeGoals, setCompleteGoals ] = useState();
    const [ totalGoals, setTotalGoals ] = useState();
    const activityRef = useRef();
    const goalsRef = useRef();
    const peopleRef = useRef();
    const navigate = useNavigate();
    let coachListener;
    let goalsListener;
    let activityListener;
    let peopleListener;
    
    useEffect(() => {
        setTimeout(() => {
            switchOrgMenu('org-menu-coaches')
        }, 50);

        return () => {
            if (coachListener) { coachListener(); }
            if (activityListener) { activityListener(); }
            if (goalsListener) { goalsListener(); }
            if (peopleListener) {peopleListener(); }
        }
    }, [])

    useEffect(() => {
        // console.log(appId);
        getCoachPeople();
        getCoachInfo();
        getCoachActivity();
        getCoachGoals();
    }, [appId])

    useEffect(() => {
        if (!activity) {return;}
        let personArr = []
        let totalTimeLogged = 0;
        let coachTimeLogged = 0;
        const personActObj = {}
        for (let a of Object.values(activity)) {
            if (a.personId) {
                for (let p of a.personId) {
                    if (!personArr.includes(p)) {
                        personArr.push(p);
                    }
                }
            }
            if (a.startDate < new Date().getTime()) {
                totalTimeLogged += a.duration;
                if (a.activityCategory !== 'other') {
                    coachTimeLogged += a.duration;
                }
                if (a.personId && a.personId.includes(personId)) {
                    personActObj[a.ts] = a
                }
            }
        }
        console.log(personActObj);
        setPersonActivity(personActObj);
        setPersonCount(personArr.length);
        setTotalTime(+(totalTimeLogged/60).toFixed(2));
        setCoachingTime(+(coachTimeLogged/60).toFixed(2));
    }, [activity])

    useEffect(() => {
        if (!goals) {return;}
        let totalGoalCount = 0;
        let completedGoalCount = 0;
        for (let g of Object.values(goals)) {
            if (g.status === 'complete') {
                completedGoalCount++;
            }
            totalGoalCount++;
        }
        setTotalGoals(totalGoalCount);
        setCompleteGoals(completedGoalCount);
    }, [goals])

    

    async function getCoachInfo() {
        const coachInfo = await getUserInfo({'appId': appId, 'orgId': currentOrg.orgId})
        // console.log(coachInfo);
        setCoach(coachInfo);
    }

    async function getCoachPeople() {
        getActivePeople({'appId': appId, 'callback': callback, 'receiveListener': receiveListener})
        function callback(data) {
            // console.log(data)
            peopleRef.current = {...peopleRef.current, ...{[data.personId]: data}}
            // console.log(peopleRef.current);
            setPeople(peopleRef.current);
        }
        function receiveListener(unsubscribe) {
            peopleListener = unsubscribe;
        }
    }

    async function getCoachActivity() {
        getActivity({'appId': appId, 'status': 'active', 'callback': callback, 'receiveListener': receiveListener})
        function callback(data) {
            activityRef.current = {...activityRef.current, ...{[data.ts]: data}}
            setActivity(activityRef.current);
        }
        function receiveListener(unsubscribe) {
            activityListener = unsubscribe;
        }
    }

    async function getCoachGoals() {
        getGoals({'appId': appId, 'callback': callback, 'receiveListener': receiveListener})
        function callback(data) {
            goalsRef.current = {...goalsRef.current, ...{[data.ts]: data}}
            setGoals(goalsRef.current);
        }
        function receiveListener(unsubscribe) {
            goalsListener = unsubscribe;
        }
    }

    return (
        <div className='admin-coach-view'>
            <Routes>
                <Route path='' element={
                    <div>
                        <div className='back-div'><span className='back-icon' onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowLeft} />Back</span></div>
                    
                        <div className='coach-view-content'>
                            
                            <div className='g-card coach-info'>
                                <div className='coach-icon'>
                                    <img src={coach.icon} />
                                </div>
                                <div className='coach-name'>
                                    <span className='f-name'>{coach.fName}</span>
                                    <span className='l-name'>{coach.lName}</span>
                                    <div className="g-space-10"></div>
                                    <span className='meta'>Last Activity: {coach.stats && format(new Date(coach.stats.lastActivity), 'P')}</span>
                                </div>
                            </div>
                            <div className='g-card data-box people-coached clickable' onClick={() => navigate('people')}>
                                <div className='data-point'>
                                    <div className='big-data'>{personCount ?? 0}</div>
                                    <div className='descriptor'>Total {currentOrg.termPersonPlural ?? 'People'} Coached</div>
                                    {/* <div className='meta'>-4% compared to last week</div> */}
                                </div>
                                <div className='icon'>
                                    <span className='icon-circle'>
                                        <FontAwesomeIcon icon={faHandshakeAngle} size="2xl" />
                                    </span>
                                </div>
                            </div>
                            <div className='g-card data-box time-logged'>
                                <div className='data-point'>
                                    <div className='big-data'>{totalTime ?? 0} Hrs.</div>
                                    <div className='descriptor'>Total Time Logged</div>
                                    {/* <div className='meta'>-4% compared to last week</div> */}
                                </div>
                                <div className='icon'>
                                    <span className='icon-circle'>
                                        <FontAwesomeIcon icon={faStopwatch} size="2xl" />
                                    </span>
                                </div>
                            </div>
                            <div className='g-card data-box time-coaching'>
                                <div className='data-point'>
                                    <div className='big-data'>{coachingTime ?? 0} Hrs.</div>
                                    <div className='descriptor'>Total Time Coaching</div>
                                    {/* <div className='meta'>-4% compared to last week</div> */}
                                </div>
                                <div className='icon'>
                                    <span className='icon-circle'>
                                        <FontAwesomeIcon icon={faPeopleCarryBox} size="2xl" />
                                    </span>
                                </div>
                            </div>
                            <div className='g-card data-box goals'>
                                <div className='data-point'>
                                    <div className='big-data'>{completeGoals ?? 0}/{totalGoals ?? 0}</div>
                                    <div className='descriptor'>Goals Completed</div>
                                    {/* <div className='meta'>-4% compared to last week</div> */}
                                </div>
                                <div className='icon'>
                                    <span className='icon-circle'>
                                        <FontAwesomeIcon icon={faBullseye} size="2xl" />
                                    </span>
                                </div>
                            </div>
                            {/* {
                                (people) &&
                                <MyPeopleList myPeople={people} admin={true} />
                            } */}
                        </div>
                        <hr />
                        {/* <div className="g-space-20"></div> */}
                        <ReportsLanding activity={activity} goals={goals} />
                    </div>
                }></Route>
                <Route path='people' element={<AdminPeopleList people={people} goals={goals} />}></Route>
                <Route path='people/personview/:personId/*' element={<AdminPersonView people={people} activity={activity} goals={goals}  />}></Route>
                
                <Route path='deepdive' element={<DeepDiveTable />}></Route>
                <Route path="*" element={<Navigate replace to="/404" />} />
            </Routes>
            
            {/* {
                (activity && goals) &&
                Object.values(activity)
                .toSorted((a,b) => a.startDate < b.startDate ? 1 : -1)
                .map(a => {
                    let goalsExist = true;
                    if (a.goal) {
                        for (let g of a.goal) {
                            if (!goals[g]) {
                                goalsExist = false
                            }
                        }
                    }
                    if (goalsExist) {
                        return (
                            <div key={a.ts}>
                                <ActivityItem activity={a} myGoals={goals} />
                            </div>
                        )
                    }
                })
            } */}
        </div>
    )
}

export default CoachView