import React, { useEffect, useRef, useState } from 'react'
import './reports-container.styles.scss';
import ReportsLanding from './reports-landing.component';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import DeepDiveTable from './deep-dive/deep-dive-table';
import { useDispatch, useSelector } from 'react-redux';
import { getActivity, getGoals } from 'utils/getUserData';
import PrintScreen from './print-screen.component';
import { switchOrgMenu } from '../org-sidebar-left/org-sidebar-left.component';
import PersonIcon from 'components/shared/person-icon.component';

const ReportsContainer = () => {

    const currentOrg = useSelector(state => state.contentData.currentOrg);
    const myPeople = useSelector(state => state.contentData.myPeople);
    const [ activity, setActivity ] = useState(null);
    const [ goals, setGoals ] = useState(null);
    const { personId } = useParams();
    const activityRef = useRef();
    let activityListener;
    const goalsRef = useRef(); 
    let goalsListener;
    const dispatch = useDispatch();

    useEffect(()=> {
        getAllActivity();
        getAllGoals();
        setTimeout(() => {
            switchOrgMenu('org-menu-reports');
        }, 50);

        return () => {
            if (activityListener) {
                activityListener();
            }
            if (goalsListener) {
                goalsListener();
            }
        }
    }, [])

    function getAllActivity() {
        console.log('getting activity')
        getActivity({'orgId': currentOrg.orgId, 'status': 'active', 'callback': callback, 'receiveListener': receiveListener})
        function callback(data) {
            activityRef.current = {...activityRef.current, ...{[data.ts]: data}}
            setActivity(activityRef.current);
            // dispatch(setTempActivity(activityRef.current));
        }
        function receiveListener(unsub) {
            activityListener = unsub;
        }
    }

    function getAllGoals() {
        console.log('getting goals')
        getGoals({'orgId': currentOrg.orgId, 'callback': callback, 'receiveListener': receiveListener})
        function callback(data) {
            goalsRef.current = {...goalsRef.current, ...{[data.ts]: data}}
            setGoals(goalsRef.current);
            // dispatch(setTempGoals(goalsRef.current));
        }
        function receiveListener(unsub) {
            goalsListener = unsub;
        }
    }

    return (
        <div className='reports-container'>
            <Routes>
                <Route path='' element={
                <div>
                    <div className='section-title with-icon'>
                        <span>Reports</span>
                        {
                            (personId && myPeople) &&
                            <PersonIcon myPeople={myPeople} personId={personId} />
                        }
                    </div>
                    <hr />
                    <ReportsLanding activity={activity} goals={goals} />
                    </div>
                }></Route>
                <Route path='deepdive' element={<DeepDiveTable goals={goals} />}></Route>
                <Route path="*" element={<Navigate replace to="/404" />} />
            </Routes>
        </div>
    )
}

export default ReportsContainer