import React, { useEffect, useState } from 'react'
import './report-row3.styles.scss';
import Chart2 from '../org-landing/chart2.component';
import { useSelector } from 'react-redux';
import PieChart from './pie-chart.component';
import { createPieChartData } from './report-row2.component';

const ReportRow3 = ({ activity, goals }) => {
    
    const currentOrg = useSelector(state => state.contentData.currentOrg);
    const [ goalData, setGoalData ] = useState(null);
    
    useEffect(() => {
        if (!goals || !currentOrg) {return;}
        const goalInfo = filterGoalData();
        setGoalData(goalInfo);
    }, [goals, currentOrg])
    
    function filterGoalData() {
        let filtered = {}
        for (let obj of Object.values(goals)) {
            const goalStep = obj.cycleStep;
            const stepName = currentOrg.cycleTerms[goalStep].name;
            if (filtered[stepName]) {
                filtered[stepName].data++;
            } else {
                filtered[stepName] = {
                    'name': stepName,
                    'data': 1,
                }
            }
        }
        return createPieChartData(filtered);
    }

    return (
        <div className='report-row3'>
            <div className='focus-areas g-card'>
                <b>Focus Areas</b>
                <Chart2 recentActivity={activity} showCats={true} />
            </div>
            <div className='g-card'>
                <b>Goal Progress</b>
                <PieChart data={goalData} querySelector='.focus-areas' />
            </div>
        </div>
    )
}

export default ReportRow3