import React, { useRef, useState } from 'react';
import './add-coach.styles.scss';

import { useNavigate } from 'react-router-dom';
import { newCoachEmail } from 'utils/emailBodies';
import { UseCloudFunction } from 'utils/firebase.utils';
import UIkit from 'uikit';
import { useDispatch, useSelector } from 'react-redux';
import { setOrgUsers } from 'state/slices/contentSlice';

const AddCoach = ({ orgData }) => {
    
    const userProfile = useSelector(state => state.userData.userProfile);
    const orgUsers = useSelector(state => state.contentData.orgUsers);
    const [ formData, setFormData ] = useState({});
    const [ loading, setLoading ] = useState(false);
    const navigate = useNavigate();
    const dataRef = useRef();
    const dispatch = useDispatch();

    function saveData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}};
        setFormData(dataRef.current);
    }

    async function searchForCoach() {

    }

    async function addCoach() {
        if (!formData.email || !formData.role) {
            UIkit.modal.alert('Please complete the form before continuing.')
            return;
        }
        setLoading(true);
        let tempData = {...formData}
        const now = new Date().getTime();
        tempData.ts = now;
        tempData.status = 'pending';
        tempData.userId = userProfile.appId;
        tempData.orgId = orgData.orgId;
        tempData.orgName = orgData.name;
        tempData.orgIcon = orgData.icon ?? 'none';
        const emailData = newCoachEmail({
            'orgName': orgData.name,
            'subject': 'You\'ve been added as a coach on the GuidePost Coaching Platform!',
            'emails': `${tempData.email},adam@tamingthetech.org`, 
            'link': 'https://guidepost.tamingthetech.com',
            'linkText': 'Click here to ACCEPT your invite', 
        })
        const res = await UseCloudFunction('saveCoachInvite', {'data': tempData, 'emailData': emailData, 'appId': userProfile.appId})
        console.log(res);
        if (res.success) {
            await UIkit.modal.alert('New coach saved successfully! They will be receiving an email with their invite shortly.');
            setFormData({});
            dispatch(setOrgUsers({...orgUsers, ...{[res.data.email]: res.data}}))
            navigate(-1);
            
        } else if (res.error === 'user exists') {
            UIkit.modal.alert('This user already exists in your organization.')
        } else {
            UIkit.modal.alert('Something went wrong. Please try again later.')
        }
        setLoading(false);
    }

    return (
        <div className='add-coach-container'>        
            <div className='add-coach-content'>
                <div className='g-card'>
                    <button className='g-button small-btn' onClick={() => navigate(-1)}>Back</button>
                    <h4>Add Coach</h4><hr />
                    <div className='field'>
                        <label>Email Address</label>
                        <input type='text' value={formData.email ?? ''} onChange={(e) => saveData('email', e.target.value.trim().toLowerCase())} />
                    </div>
                    <div className='field'>
                        <label>User Role</label>
                        <select value={formData.role ?? ''} onChange={(e) => saveData('role', [e.target.value])} >
                            <option value=''>Choose...</option>
                            <option value='coach'>Coach</option>
                            <option value='orgManager'>Organization Manager</option>
                            <option value='viewer'>Viewer</option>
                        </select>
                    </div>
                    {/* <button className='g-button' onClick={() => searchForCoach()}>Search GuidePost</button> */}
                    <hr/>
                    <div className='buttons'>
                        {
                            (!loading) 
                            ?
                            <button className='g-button primary submit' onClick={() => addCoach()}>Add Coach</button>
                            :
                            <button className='g-button disabled submit'><div data-uk-spinner='ratio: .5'></div></button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddCoach