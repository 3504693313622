import React, { useEffect, useRef, useState } from 'react';
import './sort-titles-form.styles.scss';
import { useSelector } from 'react-redux';

const SortTitlesFormOLD = ({ formData, saveData }) => {

    const currentOrg = useSelector(state => state.contentData.currentOrg);
    const sortCategories = useSelector(state => state.contentData.sortCategories);
    const [ catsToDisplay, setCatsToDisplay ] = useState(null);
    
    useEffect(() => {
        if (!sortCategories) { return; }
        // console.log(sortCategories)
        setCatsToDisplay({...sortCategories})
    }, [sortCategories])

    function saveSortTitle(key, value) {
        if (key.includes('sortLevel')) {
            disableLevel(key);
        }
        saveData(key, value);
    }

    function disableLevel(key) {
        // console.log(key)
        const selectElement = document.getElementById(`${key}-select`);
        const inputElement = document.getElementById(`${key}-input`);
        if (selectElement.value) {
            inputElement.value = ''
            inputElement.classList.add('disabled');
        } else if (inputElement.value) {
            selectElement.value = ''
            selectElement.classList.add('disabled');
        } else {
            selectElement.classList.remove('disabled')
            inputElement.classList.remove('disabled')
        }
    }


    return (
        <div className='sort-titles-form'>
        {
            (formData) &&
            <div>
                <div className="field" >
                    <label>{currentOrg.termLevel1 ?? 'Group 1'}</label>
                    <div className='g-double-col'>
                        <div>
                            <select id='sortLevel1-select' value={formData.sortLevel1 ?? ''} onChange={(e) => saveSortTitle('sortLevel1', e.target.value)} required >
                                <option value=''>Choose...</option>
                                {
                                    (catsToDisplay && catsToDisplay.sortLevel1) &&
                                    catsToDisplay.sortLevel1
                                    .map(cat => (
                                        <option key={cat} value={cat}>{cat}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div>
                            <input type='text' id='sortLevel1-input' value={formData.level1} onChange={(e) => saveSortTitle('sortLevel1', e.target.value)} />
                        </div>
                    </div>
                </div>
                {
                    ((currentOrg.termLevel1 && currentOrg.termLevel2) || !currentOrg.termLevel1) &&
                    <div className="field">
                        <label>{currentOrg.termLevel2 ?? 'Group 2'}</label>
                        <div className='g-double-col'>
                            <div>
                                <select id='sortLevel2-select' value={formData.sortLevel2 ?? ''} onChange={(e) => saveSortTitle('sortLevel2', e.target.value)} required >
                                    <option value=''>Choose...</option>
                                    {
                                    (catsToDisplay && catsToDisplay.sortLevel2) &&
                                    catsToDisplay.sortLevel2
                                    .map(cat => (
                                        <option key={cat} value={cat}>{cat}</option>
                                    ))
                                }
                                </select>
                            </div>
                            <div>
                                <input type='text' id='sortLevel2-input' value={formData.level2} onChange={(e) => saveSortTitle('sortLevel2', e.target.value)} />
                            </div>
                        </div>
                    </div>
                }
                {
                    ((currentOrg.termLevel1 && currentOrg.termLevel3) || !currentOrg.termLevel1) &&
                    <div className="field">
                        <label>{currentOrg.termLevel3 ?? 'Group 3'}</label>
                        <div className='g-double-col'>
                            <div>
                                <select id='sortLevel3-select' value={formData.sortLevel3 ?? ''} onChange={(e) => saveData('sortLevel3', e.target.value)} required >
                                    <option value=''>Choose...</option>
                                    {
                                    (catsToDisplay && catsToDisplay.sortLevel3) &&
                                    catsToDisplay.sortLevel3
                                    .map(cat => (
                                        <option key={cat} value={cat}>{cat}</option>
                                    ))
                                }
                                </select>
                            </div>
                            <div>
                                <input type='text' id='sortLevel3-input' value={formData.level3} onChange={(e) => saveData('sortLevel3', e.target.value)} />
                            </div>
                        </div>
                    </div>
                }
                {
                    ((currentOrg.termLevel1 && currentOrg.termLevel4) || !currentOrg.termLevel1) &&
                    <div className="field">
                        <label>{currentOrg.termLevel4 ?? 'Group 4'}</label>
                        <div className='g-double-col'>
                            <div>
                                <select id='sortLevel4-select' value={formData.sortLevel4 ?? ''} onChange={(e) => saveData('sortLevel4', e.target.value)} required >
                                    <option value='' >Choose...</option>
                                    {/* <option value=''>None</option> */}
                                    {
                                    (catsToDisplay && catsToDisplay.sortLevel4) &&
                                    catsToDisplay.sortLevel4
                                    .map(cat => (
                                        <option key={cat} value={cat}>{cat}</option>
                                    ))
                                }
                                </select>
                            </div>
                            <div>
                                <input type='text' id='sortLevel4-input' value={formData.level4} onChange={(e) => saveData('sortLevel4', e.target.value)} />
                            </div>
                        </div>
                    </div>
                }
            </div>
        }
        </div>
    )
}

export default SortTitlesFormOLD