import React, { useEffect, useRef, useState } from 'react';
import './new-person.styles.scss';
import { switchOrgMenu } from 'components/ORG-MANAGER/org-sidebar-left/org-sidebar-left.component'
import { useSelector } from 'react-redux';
import { RandomString } from 'utils/randomString';
import { UseCloudFunction } from 'utils/firebase.utils';
import UIkit from 'uikit';
import { useNavigate, useParams } from 'react-router-dom';
import { getRandomLinearGradient } from 'utils/randomGradient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faUpload } from '@fortawesome/free-solid-svg-icons';
import SortTitlesForm from 'components/shared/sort-titles-form-OLD.component';

const NewPerson = () => {
    
    const currentOrg = useSelector(state => state.contentData.currentOrg);
    const profile = useSelector(state => state.userData.userProfile);
    const myPeople = useSelector(state => state.contentData.myPeople);
    // const sortCategories = useSelector(state => state.contentData.sortCategories);
    const [ formData, setFormData ] = useState({});
    const [ tempLevels, setTempLevels ] = useState({});
    // const [ catsToDisplay, setCatsToDisplay ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const { personId } = useParams();
    const dataRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        switchOrgMenu('coach-add-person');
    }, [])

    useEffect(() => {
        if (!personId || !myPeople ) {
            dataRef.current = {}
            setFormData(dataRef.current);
            return;
        }
        dataRef.current = myPeople[personId]
        setFormData(dataRef.current);
        switchOrgMenu('coach-my-people');
    }, [personId, myPeople])

    // useEffect(() => {
    //     if (!sortCategories) { return; }
    //     // console.log(sortCategories)
    //     setCatsToDisplay({...sortCategories})
    // }, [sortCategories])

    function saveData(key, value) {
        if (key.includes('sortLevel')) {
            disableLevel(key);
        }
        dataRef.current = {...dataRef.current, ...{[key]: value}}
        setFormData(dataRef.current);
    }

    function disableLevel(key) {
        // console.log(key)
        const selectElement = document.getElementById(`${key}-select`);
        const inputElement = document.getElementById(`${key}-input`);
        if (selectElement.value) {
            inputElement.value = ''
            inputElement.classList.add('disabled');
        } else if (inputElement.value) {
            selectElement.value = ''
            selectElement.classList.add('disabled');
        } else {
            selectElement.classList.remove('disabled')
            inputElement.classList.remove('disabled')
        }
    }

    async function saveNewPerson() {
        if (!formData.fName || !formData.lName || !formData.email) {
            UIkit.modal.alert('Please complete the required parts of the form before submitting.');
            return;
        }
        setLoading(true)
        let personObj = {...formData}
        if (!personObj.personId) {
            personObj.personId = RandomString(20);
            personObj.ts = new Date().getTime();
            personObj.added = personObj.ts;
            personObj.orgId = currentOrg.orgId;
            personObj.appId = [profile.appId];
            personObj.grad = getRandomLinearGradient();
            personObj.status = 'active';
        }
        // console.log(personObj);
        const res = await UseCloudFunction('savePerson', {'obj': personObj})
        // console.log(res);
        if (res.success) {
            await UIkit.modal.alert(`${personObj.fName} ${personObj.lName} saved successfully!`)
            navigate(`/coach/viewperson/${personObj.personId}`);
        } else {
            UIkit.modal.alert('Something went wrong. Try again later.<br />' + res.error);
        }
        setLoading(false)
    }
    
    return (
        <div className='new-person-container'>
            <div className='new-person-content'>
                <div className='back-div'><span className='back-icon' onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowLeft} />Back</span></div>
                <div className='g-card'>
                    <div className='section-title with-icon'>
                        {personId
                            ?
                            <span>Edit {(currentOrg && currentOrg.termPersonSingular) ? currentOrg.termPersonSingular : 'Person'}</span>
                            : 
                            <span>Add a New {(currentOrg && currentOrg.termPersonSingular) ? currentOrg.termPersonSingular : 'Person'}</span>
                        }
                        {!personId &&
                            <div>
                                <button className='g-button small-btn' onClick={() => navigate('/coach/pendinglist')}>Activate a Pending Person</button>
                                <button className='g-button small-btn' onClick={() => navigate('/coach/uploadlist')}>Or Upload a List<FontAwesomeIcon className='' icon={faUpload} style={{'marginLeft': '14px'}} /></button>
                            </div>
                        }
                    </div>
                    <hr />
                    <div className="g-double-col">
                        <div className="field">
                            <label>First Name<span className='required'>*</span></label>
                            <input type='text' defaultValue={formData.fName ?? ''} onChange={(e) => saveData('fName', e.target.value)} />
                        </div>
                        <div className="field">
                            <label>Last Name<span className='required'>*</span></label>
                            <input type='text' defaultValue={formData.lName ?? ''} onChange={(e) => saveData('lName', e.target.value)} />
                        </div>
                    </div>
                    <div className="field">
                            <label>Email Address<span className='required'>*</span></label>
                            <input type='text' defaultValue={formData.email ?? ''} onChange={(e) => saveData('email', e.target.value.trim().toLowerCase())} />
                        </div>
                    <div className="field">
                        <label>Notes</label>
                        <textarea defaultValue={formData.notes ?? ''} onChange={(e) => saveData('notes', e.target.value)}></textarea>
                    </div>
                    <div className="g-space-20"></div>
                    <h4>Sorting Information</h4>
                    <p>(Choose from existing categories, or create a new one.)</p>
                    <div className="g-space-20"></div>
                    <SortTitlesForm formData={formData} saveData={saveData} />
                    {/* <div className="field">
                        <label>{currentOrg.termLevel1 ?? 'Group 1'}</label>
                        <div className='g-double-col'>
                            <div>
                                <select id='sortLevel1-select' defaultValue={formData.sortLevel1 ?? ''} onChange={(e) => saveData('sortLevel1', e.target.value)}>
                                    <option value=''>Choose...</option>
                                    {
                                        (catsToDisplay && catsToDisplay.sortLevel1) &&
                                        catsToDisplay.sortLevel1
                                        .map(cat => (
                                            <option key={cat} value={cat}>{cat}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div>
                                <input type='text' id='sortLevel1-input' defaultValue={formData.level1} onChange={(e) => saveData('sortLevel1', e.target.value)} />
                            </div>
                        </div>
                    </div>
                    {
                        ((currentOrg.termLevel1 && currentOrg.termLevel2) || !currentOrg.termLevel1) &&
                        <div className="field">
                            <label>{currentOrg.termLevel2 ?? 'Group 2'}</label>
                            <div className='g-double-col'>
                                <div>
                                    <select id='sortLevel2-select' defaultValue={formData.sortLevel2 ?? ''} onChange={(e) => saveData('sortLevel2', e.target.value)}>
                                        <option value=''>Choose...</option>
                                        {
                                        (catsToDisplay && catsToDisplay.sortLevel2) &&
                                        catsToDisplay.sortLevel2
                                        .map(cat => (
                                            <option key={cat} value={cat}>{cat}</option>
                                        ))
                                    }
                                    </select>
                                </div>
                                <div>
                                    <input type='text' id='sortLevel2-input' defaultValue={formData.level2} onChange={(e) => saveData('sortLevel2', e.target.value)} />
                                </div>
                            </div>
                        </div>
                    }
                    {
                        ((currentOrg.termLevel1 && currentOrg.termLevel3) || !currentOrg.termLevel1) &&
                        <div className="field">
                            <label>{currentOrg.termLevel3 ?? 'Group 3'}</label>
                            <div className='g-double-col'>
                                <div>
                                    <select id='sortLevel3-select' defaultValue={formData.sortLevel3 ?? ''} onChange={(e) => saveData('sortLevel3', e.target.value)}>
                                        <option value=''>Choose...</option>
                                        {
                                        (catsToDisplay && catsToDisplay.sortLevel3) &&
                                        catsToDisplay.sortLevel3
                                        .map(cat => (
                                            <option key={cat} value={cat}>{cat}</option>
                                        ))
                                    }
                                    </select>
                                </div>
                                <div>
                                    <input type='text' id='sortLevel3-input' defaultValue={formData.level3} onChange={(e) => saveData('sortLevel3', e.target.value)} />
                                </div>
                            </div>
                        </div>
                    }
                    {
                        ((currentOrg.termLevel1 && currentOrg.termLevel4) || !currentOrg.termLevel1) &&
                        <div className="field">
                            <label>{currentOrg.termLevel4 ?? 'Group 4'}</label>
                            <div className='g-double-col'>
                                <div>
                                    <select id='sortLevel4-select' defaultValue={formData.sortLevel4 ?? ''} onChange={(e) => saveData('sortLevel4', e.target.value)}>
                                        <option value=''>Choose...</option>
                                        {
                                        (catsToDisplay && catsToDisplay.sortLevel4) &&
                                        catsToDisplay.sortLevel4
                                        .map(cat => (
                                            <option key={cat} value={cat}>{cat}</option>
                                        ))
                                    }
                                    </select>
                                </div>
                                <div>
                                    <input type='text' id='sortLevel4-input' defaultValue={formData.level4} onChange={(e) => saveData('sortLevel4', e.target.value)} />
                                </div>
                            </div>
                        </div>
                    } */}
                    <hr />
                    <span className='required'>* = Required</span>
                    <div className='buttons'>
                        <button className='g-button' onClick={() => navigate(-1)}>Cancel</button>
                        {
                            (!loading)
                            ?
                            <button className='g-button primary submit' onClick={() => saveNewPerson()}>{personId ? 'Save Changes' : `Save New ${currentOrg.termPersonSingular ?? 'Person'}`}</button>
                            :
                            <button className='g-button disabled submit' ><div data-uk-spinner='ratio: .5'></div></button>
                        }
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default NewPerson