import React, { useEffect, useRef, useState } from 'react';
import './new-activity-form.styles.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignLeft, faArrowLeft, faCalendarCheck, faCalendarDay, faCaretDown, faCaretUp, faChartBar, faChartSimple, faFlagCheckered, faHandsClapping, faList, faListCheck, faRankingStar, faUsers, faX } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import TextEditor from 'components/text-editor/text-editor.component';
import UIkit from 'uikit';
import { UseCloudFunction } from 'utils/firebase.utils';
import flatpickr from 'flatpickr';
import { switchOrgMenu } from 'components/ORG-MANAGER/org-sidebar-left/org-sidebar-left.component';
import { getActivity } from 'utils/getUserData';
import ActivityList from './activity-list.component';
import SortTitlesForm from 'components/shared/sort-titles-form.component';
import GoalSelectForm from './goal-select-form.component';
import PeopleChoice from './people-choice.component';

const NewActivityForm = ({ edit, setActivityId, activityId, setPersonId, importData, cancel, duplicate }) => {

    const currentOrg = useSelector(state => state.contentData.currentOrg);
    const myPeople = useSelector(state => state.contentData.myPeople);
    const profile = useSelector(state => state.userData.userProfile);
    const myGoals = useSelector(state => state.contentData.myGoals)
    const [ textContent, setTextContent ] = useState(null);
    const [ focusAreas, setFocusAreas ] = useState(null);
    const [ clearEditor, setClearEditor ] = useState(false);
    
    const [ flatpickrInstance, setFlatpickrInstance ] = useState(null);
    const [ fp1, setFp1 ] = useState(null);
    const [ formData, setFormData ] = useState({});
    const [ filteredGoals, setFilteredGoals ] = useState(null);
    const [ existingDescription, setExistingDescription ] = useState(null);
    // const { activityId } = useParams();
    const [ stepCompleted, setStepCompleted ] = useState(false); 
    const [ durationText, setDurationText ] = useState('0 Hours, 0 Minutes')
    const [ loading, setLoading ] = useState(false);
    const [ showPeopleModal, setShowPeopleModal ] = useState(false);
    const { sourcePersonId, activityToDuplicate } = useParams();
    const navigate = useNavigate();
    const dataRef = useRef();
    const fullActivityRef = useRef();
    const location = useLocation();
    const dispatch = useDispatch();
    let activityListener;
    let fullActivityListener;

    useEffect(() => {
        if (!importData || !fp1) {return;}
        console.log(importData);
        dataRef.current = {...dataRef.current, ...importData};
        setFormData(dataRef.current);
        const hours = Math.floor(importData.duration/60).toFixed(0);
        const mins = importData.duration % 60;
        setDurationText(`${hours} ${hours == 1 ? 'Hour' : 'Hours'}, ${mins} Minutes`);
        fp1.setDate(new Date(Number(importData.startDate)))
        const description = importData.desc;
        setExistingDescription(description);
        for (let p of importData.people) {
            savePerson(p, true);
        }
    }, [importData, fp1])

    // useEffect(() => {
    //     console.log(formData);
    // }, [formData])

    useEffect(() => {
        // switch left menu tab
        switchOrgMenu('coach-new-log')

        // scrolls to the top of the page onload
        const els = document.getElementsByClassName('content-container');
        for (let el of els) {
            el.scroll({
                top: 0, 
                left: 0, 
                behavior: 'smooth'
            })
        }

        // sets base formData
        dataRef.current = {
            ...dataRef.current, 
            ...{
                'duration': 0,
                'activityCategory': 'meeting',
            }
        }
        setFormData(dataRef.current);

        // creates flatpickr instance
        const startDateElement = document.getElementById(`startDate`);
		let flat1 = flatpickr(startDateElement, 
			{
				altInput: true,
	            altFormat: "F j, Y, h:i K",
                enableTime: true,
                dateFormat: "Y-m-d",
				onChange: (dates) => {saveData('startDate', new Date(dates[0]).getTime())}
			}
		);
        setFp1(flat1);
        
        checkForDataToDuplicate();
         
        // cancels realtime listeners
        return () => {
            if (fullActivityListener) {
                fullActivityListener();
            }
            if (activityListener) {
                activityListener();
            }
            window.localStorage.removeItem('toDuplicate');
        }
    }, [])

    
    // sets focusArea dropdown once currentOrg is loaded
    // activity types are loaded in the html based on activity category
    useEffect(() => {
        setFocusAreas([...currentOrg.focusAreas].sort());
    }, [currentOrg])

    // filters goals if only one personId is chosen
    // runs every time a person is chosen or myGoals are loaded
    useEffect(() => {
        const dataToDup = JSON.parse(window.localStorage.getItem('toDuplicate'))
        if (dataToDup) {return;}
        if (!myGoals || !sourcePersonId) { 
            setFilteredGoals(null)
            dataRef.current.goal = [];
            setFormData(dataRef.current);
            return; 
        }
        async function filterGoals(id) {
            const goals = Object.values(myGoals).filter(g => g.personId.includes(id));
            setFilteredGoals(goals);
        }
        filterGoals(sourcePersonId);
    }, [myGoals, sourcePersonId])

    useEffect(() => {
        if (!sourcePersonId || !myPeople) {return;}
        savePerson(sourcePersonId, true);
    }, [sourcePersonId, myPeople])

    // goes to database to get activity data if editing
    // creates activityListener
    useEffect(() => {
        if (!activityId) {return;}
        async function getActivityData() {
            getActivity({'activityId': activityId, 'callback': activityCallback, 'receiveListener': receiveActivityListener, 'status': 'active'})
            function activityCallback(activityData) {
                // console.log(activityData)
                for (let i=1; i<5; i++ ) {
                    const key = `sortLevel${i}`;
                    if (activityData[key] && typeof activityData[key] === 'string') {
                        activityData[key] = [activityData[key]]
                    } 
                }
                dataRef.current = activityData
                setFormData(dataRef.current);
                const description = activityData.desc;
                setExistingDescription(description);
                const hours = Math.floor(activityData.duration/60).toFixed(0)
                const mins = activityData.duration % 60
                setDurationText(`${hours} ${hours == 1 ? 'Hour' : 'Hours'}, ${mins} Minutes`);
            }
            function receiveActivityListener(unsubscribe) {
                activityListener = unsubscribe;
            }
        }
        getActivityData()
    }, [activityId])

    // if editing, this function assigns the saved date to the flatPicker instance
    useEffect(() => {
        // console.log(activityId, formData.startDate, fp1)
        if (!activityId || !formData.startDate || !fp1) { return; }
        fp1.setDate(new Date(Number(formData.startDate)))
    }, [activityId, fp1, formData.startDate])

    function checkForDataToDuplicate() {
        const dataToDup = JSON.parse(window.localStorage.getItem('toDuplicate'))
        if (!dataToDup) {return}
        delete dataToDup.ts;
        delete dataToDup.startDate;
        dataRef.current = dataToDup;
        setFormData(dataRef.current);
        computeDuration(dataRef.current.duration);
        console.log(dataRef.current);
    }

    function savePerson(id, add) {
        // adds people from formData and personId
        if (add) {
            if (dataRef.current.personId) {
                let newArr = [...dataRef.current.personId]
                newArr.push(id)
                dataRef.current = {...dataRef.current, ...{'personId': newArr}};
                // peopleToDisplayRef.current.push(myPeople[id])
                // setPersonId(newArr);
            } else {
                dataRef.current = {...dataRef.current, ...{'personId': [id]}};
                // peopleToDisplayRef.current = [myPeople[id]]
                // setPersonId([id]);
            }
            
        } else {
            // removes people from formData and personId
            let newArr = dataRef.current.personId.filter(p => p !== id)
            dataRef.current = {...dataRef.current, ...{'personId': newArr}};
            // peopleToDisplayRef.current = peopleToDisplayRef.current.filter(p => p.personId !==id)
            // setPersonId(newArr);
        }
        for (let i=1; i<5; i++ ) {
            const key = `sortLevel${i}`;
            dataRef.current[key] = [];
        }
        for (let p of dataRef.current.personId) {
            for (let i=1; i<5; i++ ) {
                const key = `sortLevel${i}`;
                if (!dataRef.current[key]) {
                    saveData(key, [myPeople[p][key]])
                } else if (dataRef.current[key] && !dataRef.current[key].includes(myPeople[p][key])) {
                    saveData(key, [...dataRef.current[key], ...[myPeople[p][key]]]);
                }
            }
        }

        // loops through chosen people and removes any inapplicable goals if one is chosen
        
        if (dataRef.current.personId && dataRef.current.personId.length > 0) {
            for (let pId of dataRef.current.personId) {
                let newGoalList;
                let newGoalSteps;
                if (dataRef.current.goal) {
                    newGoalList = [...dataRef.current.goal]
                    newGoalSteps = {...dataRef.current.goalSteps}
                    for (let gId of dataRef.current.goal) {
                        const goal = myGoals[gId];
                        if (!goal.personId.includes(pId)) {
                            newGoalList.splice(newGoalList.indexOf(gId),1)
                            delete newGoalSteps[gId]

                        }
                    }
                }
                dataRef.current = {...dataRef.current, ...{'goal': newGoalList, 'goalSteps': newGoalSteps}}
            }
        } else {
            dataRef.current = {...dataRef.current, ...{'goal': [], 'goalSteps': {}}}
        }

        // resets sortLevels to default if all people are removed
        // if (peopleToDisplayRef.current.length === 0) {
        //     dataRef.current = {...dataRef.current, ...{
        //         'sortLevel1': [],
        //         'sortLevel2': [],
        //         'sortLevel3': [],
        //         'sortLevel4': [],
        //     }}
        // } else {
        //     // sets sortTitles for all selected people
        //     for (let p of peopleToDisplayRef.current) {
        //         for (let i=1; i<5; i++ ) {
        //             const key = `sortLevel${i}`;
        //             if (!dataRef.current[key]) {
        //                 saveData(key, [p[key]])
        //             } else if (dataRef.current[key] && !dataRef.current[key].includes(p[key])) {
        //                 saveData(key, [...dataRef.current[key], ...[p[key]]]);
        //             }
        //         }
        //     }
        // }
        if (setPersonId) {
            setPersonId(dataRef.current.personId);
        }
        setFormData(dataRef.current)
        // setPeopleToDisplay(peopleToDisplayRef.current);

    }

    

    // function checkShareEvent(e, date, personId) {
    //     // console.log(e, date, personId)
    //     if (e && e.checked) {
    //         if (formData.startDate && formData.startDate > new Date().getTime() && formData.personId && formData.personId !== 'none') {
    //             saveData('shareWithPerson', true)
    //         } else {
    //             saveData('shareWithPerson', false);
    //         }
    //     } else if (e && e.checked === false) {
    //         saveData('shareWithPerson', false);
    //     } else if (!e && date) {
    //         if (date < new Date().getTime()) {
    //             saveData('shareWithPerson', false);
    //         }
    //     } else if (!e && personId) {
    //         if (personId === 'none') {
    //             saveData('shareWithPerson', false);
    //         }
    //     }
    // }

    function saveData(key, value) {
        // console.log(key)
        if (key === 'personId') {
            dataRef.current.goal = 'none';
        } else if (key === 'createEvent' && !value) {
            dataRef.current = {...dataRef.current, ...{'inviteTargets': value}}    
        }
        dataRef.current = {...dataRef.current, ...{[key]: value}}
        setFormData(dataRef.current);
        // console.log(dataRef.current);
    
    }

    function changeDuration(dir) {
        let duration = Number(dataRef.current.duration)
        if (dir === 'up') {
            duration += 15
        } else {
            if (duration === 0) {
                console.log('already 0');
                return;
            }
            duration -= 15
        }
        saveData('duration', duration);
        computeDuration(duration);
    }

    function computeDuration(duration) {
        const hours = Math.floor(duration/60).toFixed(0)
        const mins = duration % 60
        setDurationText(`${hours} ${hours == 1 ? 'Hour' : 'Hours'}, ${mins} Minutes`)
    }
 
    function chooseActivityCategory(e) {
        saveData('activityCategory', e.id);
        saveData('activityType', null);
        const buttons = document.getElementsByClassName('category-button')
        for (let b of buttons) {
            b.classList.remove('primary')
        }
        // e.classList.add('primary');
    }

    async function handleSubmit() {
        if (!formData.title || !formData.duration || !formData.activityType || !formData.focusArea || !formData.startDate) {
            UIkit.modal.alert('Please complete the form before saving activity.')
            return;
        }
        setLoading(true);
        // console.log(formData)
        let newData = {...formData}
        newData.appId = profile.appId;
        newData.orgId = currentOrg.orgId;
        newData.status = 'active';
        if (newData.ts) {
            newData.edited = new Date().getTime();
        } else {
            newData.ts = new Date().getTime();
        }
        const res = await UseCloudFunction('saveActivity', {'activityObj': newData, 'edit': newData.edited ? true : false});
        if (res.error) {
            UIkit.modal.alert('Something went wrong, try again later.<br/>' + res.error);
        } else {
            UIkit.modal.alert('Activity saved successfully!');
            clearForm();
        }
        setLoading(false);
    }

    function clearForm() {
        setClearEditor(true);
        dataRef.current = {
            'duration': 0,
            'activityCategory': 'meeting',
        };
        setExistingDescription('');
        setFormData(dataRef.current);
        cancel();
    }

    return (
        <div className='new-activity-form'>
            <h4><FontAwesomeIcon icon={faList} className='title-icon' />Choose a Category</h4>
            <div className='button-div'>
                <button className={`g-button category-button ${formData.activityCategory === 'meeting' ? 'primary' : ''}`} id='meeting' onClick={(e) => chooseActivityCategory(e.target)} ><FontAwesomeIcon icon={faCalendarCheck} />Meeting</button>
                <button className={`g-button category-button ${formData.activityCategory === 'visit' ? 'primary' : ''}`} id='visit' onClick={(e) => chooseActivityCategory(e.target)}   ><FontAwesomeIcon icon={faHandsClapping} />In-Person Visit</button>
                <button className={`g-button category-button ${formData.activityCategory === 'other' ? 'primary' : ''}`} id='other' onClick={(e) => chooseActivityCategory(e.target)}><FontAwesomeIcon icon={faListCheck} />Other Duties</button>
            </div>
            {/* <div className="g-space-20"></div> */}
            <h4><FontAwesomeIcon icon={faUsers} className='title-icon' />Choose Target {currentOrg.termPersonPlural ?? 'People'}</h4>
            <PeopleChoice show={showPeopleModal} cancel={() => setShowPeopleModal(false)} savePerson={savePerson} formData={formData} edit={activityId ? true : false} />         
            {/* <div className="g-space-20"></div> */}
            <h4><FontAwesomeIcon icon={faChartSimple} className='title-icon' />Sort Data</h4>
            <SortTitlesForm formData={formData} saveData={saveData} />
            <h4><FontAwesomeIcon icon={faAlignLeft} className='title-icon' />Activity Details</h4>
            <div className='g-triple-col'>
                <div className='field'>
                    <label>Date and Time<span className='required'>*</span></label>
                    <input className="flatpickr flatpickr-input" type="text" id='startDate' placeholder="Pick a date..." />
                </div>
                
                <div className="field">
                    <label>Duration<span className='required'>*</span></label>
                    <div className="g-list-item duration-box">
                        <div className='duration-number'>{durationText}</div>
                        <div className='up-down-icons'>
                            <FontAwesomeIcon icon={faCaretUp} onClick={() => changeDuration('up')} className='clickable arrow' />
                            <FontAwesomeIcon icon={faCaretDown} onClick={() => changeDuration('dn')} className='clickable arrow' />
                        </div>
                    </div>
                </div>
                <div className='field'>
                    <label>Location</label>
                    <input value={formData.location ?? ''} onChange={(e) => saveData('location', e.target.value)} />
                </div>
            </div>
            <div className="g-double-col">
                
                <div className='field'>
                    <label>Focus Area<span className='required'>*</span></label>
                    <select value={formData.focusArea ?? ''} onChange={(e) => saveData('focusArea', e.target.value)}>
                        <option value=''  disabled>Choose...</option>
                        {
                            (focusAreas) && 
                            focusAreas.map(a => (
                                <option key={a} value={a}>{a}</option>
                            ))
                        }
                    </select>
                </div>
                <div className='field'>
                    <label>Activity Type<span className='required'>*</span></label>
                    <select value={formData.activityType ?? ''} onChange={(e) => saveData('activityType', e.target.value)} >
                        <option value='' disabled>Choose...</option>
                        {
                            (formData.activityCategory === 'meeting') 
                            ? 
                            [...currentOrg.meetingTypes].sort()
                            .map(type => (
                                <option key={type} value={type}>{type}</option>
                            ))
                            : 
                            (formData.activityCategory === 'visit')
                            ?
                            [...currentOrg.visitTypes].sort()
                            .map(type => (
                                <option key={type} value={type}>{type}</option>
                            ))
                            :
                            (formData.activityCategory === 'other')
                            ?
                            [...currentOrg.otherDuties].sort()
                            .map(type => (
                                <option key={type} value={type}>{type}</option>
                            ))
                            : null
                        }
                    </select>
                </div>
            </div>
            {/* {
                (formData.personId && formData.personId.length === 1) &&
                <div className='goal-row'>    
                    <div className='field'>
                        <label>Goal Addressed<span className='required'>*</span></label>
                        <select value={formData.goal ?? ''} onChange={(e) => saveData('goal', e.target.value)} >
                            <option value='' disabled>Choose...</option>
                            <option value='none'>No Goal Addressed</option>
                            {
                                (filteredGoals) &&
                                filteredGoals.sort((a,b) => a.ts > b.ts ? 1 : -1)
                                .map(g => (
                                    <option key={g.ts} value={g.ts}>{g.title}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
            }
                
            {
                (formData.goal && myGoals && myGoals[formData.goal] && myGoals[formData.goal].cycleBased) &&
                <>
                <div className='field'>
                    <label>Cycle Step</label>
                    <div className='g-list-item cycle-step'>
                        <span>Current Step: <b>{currentOrg.cycleTerms[myGoals[formData.goal].cycleStep].name} ({myGoals[formData.goal].cycleStep + 1})</b></span>
                        <span className='right'>Move this goal to the next step <button className={`g-button small-btn ${stepCompleted ? 'primary': ''}`} onClick={() => toggleStepCompleted()}>Cycle Step Completed</button></span>
                    </div>
                </div>
                </>
            } */}
            
            <div className="field">
                <label>Activity Title<span className='required'>*</span></label>
                <input type='text' value={formData.title ?? ''} onChange={(e) => saveData('title', e.target.value)} />
            </div>
            <div className='g-double-col'>
                
                
            </div>
            
            <div className="field">
                <label>Description</label>
                <TextEditor 
                    setTextContent={(e) => saveData('desc', e)} 
                    textContent={formData.desc} 
                    existingContent={existingDescription} 
                    clearEditor={clearEditor} 
                    setClearEditor={setClearEditor} 
                />
            </div>
            
            {/* <div className='g-double-col'> */}
                
                <div className="field">
                    <label>Tags (Comma separated)</label>
                    <input type='text' value={formData.tags ? formData.tags : ''} onChange={(e) => saveData('tags', e.target.value)}/>
                </div>
            {/* </div> */}

            {
                <>
                    <h4><FontAwesomeIcon icon={faFlagCheckered} className='title-icon' />Connect a Goal</h4>
                    <GoalSelectForm formData={formData} saveData={saveData} />
                </>
            }
            {/* {
                (profile && profile.gCalId) &&
                <>
                    <h4><FontAwesomeIcon icon={faCalendarDay} className='title-icon' />Calendar Features</h4>
                    <div className='field'>
                        <label className={`custom-checkbox ${!profile.gCalId && 'disabled'}`}>
                            <input type="checkbox" checked={formData.createEvent ?? false} onChange={(e) => saveData('createEvent', e.target.checked)} />
                            <span className="checkmark"></span>
                            Create event in connected Calendar
                        </label>
                        <div ></div>
                        <label className={`custom-checkbox ${!formData.createEvent && 'disabled'}`}>
                            <input type="checkbox" checked={formData.createEvent && formData.inviteTargets ? true : false} onChange={(e) => saveData('inviteTargets', e.target.checked)} />
                            <span className="checkmark"></span>
                            Invite Target {currentOrg.termPersonPlural ?? 'People'} to Calendar Event
                        </label>
                    </div> 
                </>
            } */}
            <hr />  
            <div><span className='required'>* = Required</span></div>
            <div className='buttons'>
                <button className='g-button' onClick={() => clearForm()}>Cancel</button>
                {
                    (!loading) 
                    ?
                    <button className='g-button primary submit' onClick={() => handleSubmit()}>Save {activityId ? 'Changes' : 'Activity'}</button>
                    :
                    <button className='g-button submit disabled' ><div data-uk-spinner='ratio: .5'></div></button>
                }
            </div>
               
        </div>
    )
}

export default NewActivityForm;