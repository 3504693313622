import React from 'react'
import './user-view.styles.scss'

const UserView = () => {
    
    
    
    return (
        <div>UserView</div>
    )
}

export default UserView