import React from 'react'
import './user-icon.styles.scss'

const UserIcon = ({ profile, reverse }) => {
    return (
        <div className={`user-icon-div ${reverse && 'reverse'}`}>
            <img src={profile.icon} className='user-icon' alt="profile-pic"  referrerPolicy="no-referrer" />
            {/* {profile.fName} {profile.lName} */}
        </div>
    )
}

export default UserIcon