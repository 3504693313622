import React, { useState } from 'react'
import './sign-in.styles.scss'
import './google-btn.styles.scss'
import { GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth'
import { UseCloudFunction, auth, signInWithGoogle } from 'utils/firebase.utils'
import UIkit from 'uikit'
import { Link, useNavigate } from 'react-router-dom'
import UnauthLayout from 'components/unauth-layout/unauth-layout.component'
import { useDispatch } from 'react-redux'
import { setCurrentUser } from 'state/slices/userSlice'
import ThreeDotsLoader from 'components/loader/three-dots-loader.component'

const SignIn = () => {

    const [ email, setEmail ] = useState('')
	const [ password, setPassword ] = useState('');
    const [ googleLoading, setGoogleLoading ] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

	const handleSubmit = async (e) => {
		e.preventDefault();		
		signInWithEmailAndPassword(auth, email, password)
		.then((user) => {
			// Signed in 
			// const user = userCredential.user;
			// ...
            console.log(user)
            const newUser = {
                displayName: user.user.displayName,
                email: user.user.email,
                metadata: {
                    createdAt: Number(user.user.metadata.createdAt),
                    lastLogInAt: user.user.metadata.lastLogInAt
                },
                uid: user.user.uid,
                photoURL: user.user.photoURL,
                provider: user.user.providerData[0].providerId
            }
            dispatch(setCurrentUser({...newUser}));
            navigate('/loading');
		}).catch(err => {
            console.log(err);
            UIkit.modal.alert('Sorry, the email and/or password doesn\'t match our records.<br />' + err);
        })
		
		setEmail('')
		setPassword('')
	}

    async function startCustomGoogleSignin() {
        setGoogleLoading(true);
        const authUrl = await UseCloudFunction('generateAuthUrl', {'info': 'info'})
        const redirect = window.location.origin;
        console.log(redirect);
		let encoded = encodeURIComponent(redirect  + '/loading')
		console.log(encoded)
        const link = authUrl.authLink.substring(0, authUrl.authLink.indexOf('redirect_uri')) + 'redirect_uri=' + encoded;
        // alert(link);
        window.location.replace(link);
        // https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=profile%20email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.calendarlist.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.events.owned&prompt=consent&response_type=code&client_id=522964444065-quaspueuilffio8qbh9c3q585085f870.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%3A3015%2Floading
        //https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=profile%20email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.calendarlist.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.events.owned&prompt=consent&response_type=code&client_id=522964444065-quaspueuilffio8qbh9c3q585085f870.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fguidepost.tamingthetech.com%2Floading
        // window.location.replace(authUrl.authLink)0, authUrl.authLink.indexOf('redirect_uri')
    }

    async function startGoogleSignin() {
        const provider = new GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/calendar.events.owned');
        provider.addScope('https://www.googleapis.com/auth/calendar.calendarlist.readonly');
        // provider.addScope('offline_access');
        provider.setCustomParameters({
            'access_type': 'offline',
            'prompt': 'consent',
            'scope': 'https://www.googleapis.com/auth/userinfo.profile',
        });
        signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            console.log(token);
            // The signed-in user info.
            const user = result.user;
            const newUser = {
                token: token,
                displayName: user.displayName,
                email: user.email,
                metadata: {
                createdAt: Number(user.metadata.createdAt),
                lastLogInAt: user.metadata.lastLogInAt
                },
                uid: user.uid,
                photoURL: user.photoURL,
                provider: user.providerData[0].providerId
            }
            dispatch(setCurrentUser({...newUser}));
            navigate('/loading')
            // IdP data available using getAdditionalUserInfo(result)
            // ...
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
        });
    }

    return (
        <UnauthLayout>
        <div className='sign-in-container'>
            
            <div className='sign-in-content'>
                <div className='g-card'>
                    {/* <div className='icon-container'>
                        <img className='main-logo' src={require('assets/logo_long.png')} alt="" />
                    </div> */}
                    <div className="g-space-40"></div>
                        
                    <div className='center-box'>
                        <button onClick={() => startCustomGoogleSignin()} className="g-button google-button" >
                            <img src={require('assets/google.png')} />
                            {
                                (googleLoading)
                                ?
                                <ThreeDotsLoader />
                                :
                                'Sign in with Google'
                            }
                        </button>
                        <p><b>- OR -<br />
                        Sign in with your email and password</b></p>
                    </div>
                    <div className="field" >
                        <input type="text" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                        <div className="g-space-20"></div>
                        <input className="uk-input" type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    
                    <div className='buttons'>
                        <Link to='/'>
                            <button className='g-button small-btn'>
                                Cancel
                            </button>
                        </Link>
                        <button className='g-button small-btn primary' onClick={(e) => handleSubmit(e)}>
                            Sign In
                        </button>
                        {/* <CustomButton onClick={signInWithGoogle} isGoogleSignIn>
                            Sign In With Google
                        </CustomButton>*/} 
                    </div>
                    <div className="g-space-20"></div>
                    <div className='center-box'>
                        <h4>Don't have an account?<br />Register <Link to='/register'><b>HERE</b></Link>!</h4>
                    </div>
                </div>
            </div>
            
        </div>
        </UnauthLayout>
    )
}

export default SignIn

// import 'shared/google-btn.styles.scss'

// import { Link } from 'react-router-dom'
// import { auth, signInWithGoogle } from 'firebase-utils/firebase.utils'
// import { signInWithEmailAndPassword } from 'firebase/auth'

// import Footer from 'components/footer/footer.component'
// import Header from 'components/header/header.component'
// import UIkit from 'uikit'

// const SignInPage = () => {

//     const [ email, setEmail ] = useState('')
// 	const [ password, setPassword ] = useState('')

// 	const handleSubmit = async (e) => {
// 		e.preventDefault();		
// 		signInWithEmailAndPassword(auth, email, password)
// 		.then((userCredential) => {
// 			// Signed in 
// 			// const user = userCredential.user;
// 			// ...
//             console.log(userCredential)
// 		}).catch(err => {
//             UIkit.modal.alert('Sorry, the email and/or password doesn\'t match our records.');
//         })
		
// 		setEmail('')
// 		setPassword('')
// 	}

//     return (
//         <div className='sign-in-page'>
//             <div className="content-container">
//                 {/* <Header /> */}
//                 <div className="sign-in-container">
//                     <div className='icon-container'>
//                         <img className='main-logo' src={require('assets/mainlogo-white.png')} alt="" />
//                     </div>
//                     <div className='sign-in g-card'>
                        
//                         <button onClick={signInWithGoogle} className="login-with-google-btn" >
//                             Sign in with Google
//                         </button>
//                         <p>- OR -</p>
//                         <p>Sign in with your email and password</p>
                    
//                         <div className="field" >
//                             <input type="text" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
//                             <div className="g-space-20"></div>
//                             <input className="uk-input" type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
//                         </div>
                        
//                         <div className='buttons'>
//                             <Link to='/'>
//                                 <button className='button-6 minor'>
//                                     Cancel
//                                 </button>
//                             </Link>
//                             <button className='button-6' onClick={(e) => handleSubmit(e)}>
//                                 Sign In
//                             </button>
//                             {/* <CustomButton onClick={signInWithGoogle} isGoogleSignIn>
//                                 Sign In With Google
//                             </CustomButton>*/} 
//                         </div>
//                         <div className="g-space-20"></div>
//                         <h4>Don't have an account?<br />Register <Link to='/register'><b>HERE</b></Link>!</h4>
//                     </div>
//                 </div>
//             </div>
//             <div className="footer-container">
//                 <Footer />  
//             </div>
//         </div>
//     )
// }