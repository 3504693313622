import React, { useState } from 'react'
import './head-bar-admin.styles.scss'

import { useSelector } from 'react-redux';
import UserIcon from 'components/user-icon/user-icon.component';
import { useNavigate } from 'react-router-dom';
import UserDropdown from 'components/head-bar/user-dropdown.component';

const HeadBarAdmin = () => {

    const user = useSelector(state => state.userData.currentUser);
    const profile = useSelector(state => state.userData.userProfile);
    const [ showDropdown, setShowDropdown ] = useState(false);
    const navigate = useNavigate();

    return (
        <div className="head-bar-admin-container">
            <div className="logo-container" onClick={() => navigate('/loading')}>
                    {/* <div className="g-card"> */}
                    
                    <img src={require("assets/logo_long_white.png")} alt="Logo" className="logo" />
                    <div className="admin-menu-spacer"></div>
                    <h3>Admin</h3>
                {/* </div> */}
                
            </div>
            {/* <SearchContainer setShowSearchModal={setShowSearchModal} location='head-bar' /> */}
            <div className='head-menu-box'>
                <div className='head-menu-flex'>
                    <div className='head-menu'>
                        <div className='head-menu-item' onClick={() => navigate('/loading')}>
                            Home
                        </div>
                        {/* {
                            (profile.admin) &&
                            <div className='head-menu-item'>
                                Admin
                            </div>
                        } */}
                    </div>
                    <div className='head-user-info'>
                        {
                            (user && profile) &&
                            <>
                                
                                <div className='user-icon' onClick={() => setShowDropdown(!showDropdown)}>
                                    <UserIcon profile={profile} reverse={true} />
                                </div>

                            </>
                            
                        }
                    </div>
                </div>
                <UserDropdown show={showDropdown} />
            </div>
        </div>
    )
}

export default HeadBarAdmin;