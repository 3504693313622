import React, { useEffect, useState } from 'react'
import './person-view.styles.scss'
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBullhorn, faBullseye, faChartLine, faRepeat } from '@fortawesome/free-solid-svg-icons';
import PersonInfoCard from 'components/COACH/view-person/info-card.component';
import ActivityItem from 'components/shared/activity-item/activity-item.component';
import { nb } from 'date-fns/locale';
import { useSelector } from 'react-redux';
import GoalItem from 'components/shared/goal-item/goal-item.component';
import PersonIcon from 'components/shared/person-icon.component';
import ReportsLanding from '../reports/reports-landing.component';
import DeepDiveTable from '../reports/deep-dive/deep-dive-table';

const AdminPersonView = ({ people, activity, goals}) => {

    const currentOrg = useSelector(state => state.contentData.currentOrg);
    const [ person, setPerson ] = useState();
    const [ showActivity, setShowActivity ] = useState(true);
    const [ personActivity, setPersonActivity ] = useState(null);
    const [ personGoals, setPersonGoals ] = useState(null);
    const { personId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!people || !personId) {return;}
        setPerson(people[personId])
    }, [people, personId])

    useEffect(() => {
        if (!activity) {return;}
        let obj = {}
        for (let a of Object.values(activity)) {
            if (a.personId && a.personId.includes(personId)) {
                obj[a.ts] = a
            }
        }
        setPersonActivity(obj);
        console.log(obj);
    }, [activity])

    useEffect(() => {
        if (!goals) {return;}
        let obj = {}
        for (let g of Object.values(goals)) {
            if (g.personId && g.personId.includes(personId)) {
                obj[g.ts] = g
            }
        }
        setPersonGoals(obj);
    }, [goals])

    return (
        <div className='admin-person-view'>
            <div className='person-view-content'>
                
                <Routes>
                    <Route path='' element={
                        <div>
                            <div className='back-div'><span className='back-icon' onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowLeft} />Back</span></div>
                            <div className='grid'>
                                {
                                    (personActivity) &&
                                    <>
                                        <div className='info-card'>
                                            {
                                                (person) &&
                                                <PersonInfoCard personData={person} admin={true} />
                                            }
                                            <button className={`g-button ${showActivity ? 'goals' : 'activity'} `} onClick={() => setShowActivity(!showActivity)}><FontAwesomeIcon icon={faRepeat} />Switch To {showActivity ? 'Goals' : 'Activity Logs'}</button>
                                            <button className='g-button reports' onClick={() => navigate('reports')}><FontAwesomeIcon icon={faChartLine} />Reports</button>
                                        </div>
                                        <div className='data-1'>
                                            <div className='g-card'>
                                                Total Logs: <b>{activity ? Object.values(personActivity).length : 0}</b>
                                            </div>
                                        </div>
                                        <div className='data-2'>
                                            <div className='g-card'>
                                                Total Time: <b>{activity ? +(Object.values(personActivity).filter(a => a.startDate < new Date().getTime()).reduce((acc, curr) => acc + curr.duration, 0 )/60).toFixed(2) : 0} hrs</b>
                                            </div>
                                        </div>
                                        <div className='data-3'>
                                            <div className='g-card'>
                                                Coaching Time: <b>{activity ? +(Object.values(personActivity).filter(a => a.startDate < new Date().getTime() && a.activityCategory !== 'other').reduce((acc, curr) => acc + curr.duration, 0 )/60).toFixed(2) : 0} hrs</b>
                                            </div>
                                        </div>
                                        <div className='data-4'>
                                            <div className='g-card'>
                                                Completed Goals: <b>{goals && Object.values(goals).filter(g => g.status === 'complete' && g.personId && g.personId.includes(personId)).length}/{goals && Object.values(goals).filter(g => g.personId.includes(personId) && g.status !== 'deleted').length}</b>
                                            </div>
                                        </div>
                                        {
                                            (showActivity)
                                            ?
                                            <>
                                                <div className='list1'>
                                                    <div className='g-card'>
                                                        <h4>Upcoming Activities</h4><hr />
                                                        <div className='activity-content'>                        
                                                        {
                                                            (activity) &&
                                                            Object.values(activity).filter(a => a.personId && a.personId.includes(personId) && a.startDate > new Date().getTime())
                                                            .map(a => (
                                                                <div key={a.ts}>
                                                                <ActivityItem activity={a} showButtons={false} myGoals={goals} />
                                                                </div>
                                                            ))
                                                        }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='list2'>
                                                    <div className='g-card'>
                                                        <h4>Recent Activities</h4><hr />
                                                        <div className='activity-content'>                        
                                                        {
                                                            (activity) &&
                                                            Object.values(activity).toSorted((a,b) => a.startDate < b.startDate ? 1 : -1)
                                                            .filter(a => a.personId && a.personId.includes(personId) && a.startDate <= new Date().getTime())
                                                            .map(a => (
                                                                <div key={a.ts}>
                                                                <ActivityItem activity={a} showButtons={false} myGoals={goals} />
                                                                </div>
                                                            ))
                                                        }
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className='list1'>
                                                    <div className='g-card'>
                                                        <h4>Active Goals</h4><hr />
                                                        <div className='activity-content'>
                                                        {
                                                            (goals && Object.values(goals).length > 0) &&
                                                            Object.values(goals)
                                                            .filter(g => g.cycleStep + 1 < currentOrg.cycleTerms.length && g.personId.includes(personId))
                                                            .toSorted((a,b) => a.ts > b.ts ? 1 : -1)
                                                            .map(g => (
                                                                <div key={g.ts}>
                                                                    <GoalItem goal={g} />
                                                                </div>
                                                            ))
                                                        }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='list2'>
                                                    <div className='g-card'>
                                                        <h4>Completed Goals</h4><hr />
                                                        <div className='activity-content'>
                                                        {
                                                            (goals && Object.values(goals).length > 0) &&
                                                            Object.values(goals)
                                                            .filter(g => g.status === 'complete' && g.personId.includes(personId))
                                                            .toSorted((a,b) => a.ts > b.ts ? 1 : -1)
                                                            .map(g => (
                                                                <div key={g.ts}>
                                                                    <GoalItem goal={g} displayButtons={false} />
                                                                </div>
                                                            ))
                                                        }
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    }></Route>
                    <Route path='people/personview/:personId/goals' element={'goals list'}></Route>
                    <Route path='reports' element={
                        <div>
                            <div className='back-div'><span className='back-icon' onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowLeft} />Back</span></div>
                            <div className='section-title with-icon'>
                                <span>Reports</span>
                                {
                                    (personId && people) &&
                                    <PersonIcon myPeople={people} personId={personId} />
                                }
                            </div>
                            <hr />
                            <ReportsLanding activity={personActivity} goals={personGoals} />
                        </div>
                    }></Route>
                    <Route path='reports/deepdive' element={<DeepDiveTable />}></Route>
                    <Route path="*" element={<Navigate replace to="/404" />} />
                </Routes>
            </div>
        </div>
    )
}

export default AdminPersonView