import React, { useEffect, useState } from 'react'
import './people-choice.styles.scss'

import Modal from 'components/modal/modal.component'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faX } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router-dom'

const PeopleChoice = ({ formData, savePerson }) => {

    const currentOrg = useSelector(state => state.contentData.currentOrg);
    const myPeople = useSelector(state => state.contentData.myPeople);
    const [ showModal, setShowModal ] = useState(false);
    const [ displayPeople, setDisplayPeople ] = useState(null);
    const location = useLocation();

    useEffect(() => {
        if (!myPeople) {return;}
        searchPeople();
    }, [myPeople])

    function choosePerson(id) {
        const picker = document.getElementById(`picker-${id}`)
        if (picker.classList.contains('active')) {
            console.log('remove')
            savePerson(id, false);
        } else {
            console.log('add')
            savePerson(id, true);
        }
        picker.classList.toggle('active')
    }

    function searchPeople(e) {
        if (e) {
            const filteredList = Object.values(myPeople).filter(p => p.fName.toLowerCase().includes(e.toLowerCase()) || p.lName.toLowerCase().includes(e.toLowerCase()))
            setDisplayPeople(filteredList);
        } else {
            setDisplayPeople(Object.values(myPeople));
        }
    }

    return (
        <div className='people-modal'>
            <div className="people-choice-div">
                <div>
                    <button className={`g-button`} onClick={() => setShowModal(true)}>
                        
                        Select {currentOrg.termPersonPlural ?? 'People'}
                    </button>
                </div>               
                <div>
                    <div className='g-list-item selected-people'>
                        {/* <p><b>Selected {currentOrg.termPersonPlural ?? 'People'}</b></p>
                        <hr /> */}
                        {
                            (myPeople && formData.personId && formData.personId.length > 0) 
                            ?
                            formData.personId.map(p => {
                                // console.log(p);
                                return (
                                <div key={p} className='g-list-item people-list-item'>
                                    <div className='name'>{myPeople[p].lName}, {myPeople[p].fName}</div>
                                    {
                                        !location.pathname.includes('/edit') &&
                                        <FontAwesomeIcon icon={faX} className='clickable' title='Remove this person' onClick={() => savePerson(p, false)} />
                                    }
                                </div>
                                )
                                })
                            :
                            <p>None selected...</p>
                        }
                        
                    </div>
                </div>
            </div>
            <Modal show={showModal} cancel={() => setShowModal(false)} closeButton={true} text={'Choose from your list'} cls={'narrow tall'}>
                <div className='search-bar'>
                    <div className='field'>
                        <input text='text' onChange={(e) => searchPeople(e.target.value)} />
                    </div>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                </div>
                {
                    (showModal && displayPeople) &&
                    displayPeople.sort((a,b) => (a.lName.toLowerCase() > b.lName.toLowerCase()) ? 1 : (a.lName.toLowerCase() === b.lName.toLowerCase()) ? ((a.fName.toLowerCase() > b.fName.toLowerCase()) ? 1 : -1) : -1 )
                    .map(p => (
                        <div key={p.personId} id={`picker-${p.personId}`} className={`g-list-item person-choice clickable ${formData.personId && formData.personId.includes(p.personId) ? 'active': ''}`} onClick={() => choosePerson(p.personId)}>
                            {p.lName}, {p.fName}
                        </div>
                    ))
                }
                <div className='g-space-10'></div>
            </Modal>
        </div>
    )
}

export default PeopleChoice