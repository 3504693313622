import React, { useRef, useState } from 'react';
import './sign-in.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
// import { UseCloudFunction, auth } from 'utils/firebase.utils';
import UIkit from 'uikit';
import { auth } from 'utils/firebase.utils';

const Register = () => {

    const [ formData, setFormData ] = useState({});
    const [ showPassword, setShowPassword ] = useState(false)
    const dataRef = useRef();
    const navigate = useNavigate();

    function saveData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}};
        setFormData(dataRef.current);
    }

    async function handleSubmit() {
        let profile = {...formData};
        profile.ts = new Date().getTime();
        profile.name = `${profile.fName} ${profile.lName}`;
        profile.email = profile.email.toLowerCase().trim();
        let password = profile.password;
        delete profile.password;
        const userData = await createUserWithEmailAndPassword(auth, profile.email, password)
        .then((userCredential) => {
            // Signed in 
        
            updateProfile(auth.currentUser, {
                displayName: profile.name,
            })
            const user = userCredential.user;
            return user;
        // ...
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
            if (errorCode === 'auth/email-already-in-use') {
                UIkit.modal.alert('That email address is already in our system. Try using a different address.');
                return;    
            }
            UIkit.modal.alert('Something went wrong. Try again later.');
            return;
        });
        // profile.appId = userData.uid;
        // profile.uid = userData.uid;
        // const res = await UseCloudFunction('createEmailPassword', profile)
        // if (res.message) {
            UIkit.modal.alert('Your account has been created! Click OK to sign in and start using GuidePost.')
            .then(() => {
                navigate('/logout')
            })
        // } else {
        //     UIkit.modal.alert('Something went wrong. Try again later.')
        //     return;
        // }
    }

    return (
        <div className='sign-in-container'>
            <div className='sign-in-content'>
                <div className='g-card'>
                    <div className='icon-container'>
                        <img className='main-logo' src={require('assets/logo_long.png')} alt="" />
                    </div>
                    <div className="g-space-40"></div>
                    
                    <div className="field">
                        {/* <label>First Name</label> */}
                        <input type="text" aria-label="first name" placeholder="First Name" defaultValue={formData.fName ? formData.fName : ''} onChange={(e) => saveData('fName', e.target.value)} />
                    </div>
                    <div className="field">
                        {/* <label>Last Name</label> */}
                        <input type="text" aria-label="last name" placeholder="Last Name" defaultValue={formData.lName ? formData.lName : ''} onChange={(e) => saveData('lName', e.target.value)} />
                    </div>
                    <div className="field">
                        {/* <label>Email Address</label> */}
                        <input type="text" aria-label="email" placeholder="Email Address" defaultValue={formData.email ? formData.email : ''} onChange={(e) => saveData('email', e.target.value)} />
                    </div>
                    <div className="field">
                        {/* <label>Password</label> */}
                        
                        <div className='input-icon'>
                            <input type={showPassword ? 'text': 'password'} placeholder="Create a Password" aria-label="password" defaultValue={formData.password ? formData.password : ''} onChange={(e) => saveData('password', e.target.value)} />
                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} className='icon' onClick={() => setShowPassword(!showPassword)} />
                        </div>
                    </div>  
                    <div className='buttons'>
                        <button className='g-button small-btn' onClick={() => navigate(-1)}>Cancel</button>
                        <button className='g-button small-btn primary' onClick={() => handleSubmit()}>Create Account</button>
                    </div> 
                </div>
            </div>
        </div>
    )
}

export default Register