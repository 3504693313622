import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import './coach-list.styles.scss';
import { switchOrgMenu } from '../org-sidebar-left/org-sidebar-left.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faPlusCircle, faSquareArrowUpRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { getCoachStats, getPeopleBlurbs } from 'utils/getUserData';
import { setImpersonateData } from 'state/slices/contentSlice';

const CoachList = ({ recentActivity }) => {
    
    const currentOrg = useSelector(state => state.contentData.currentOrg);
    const orgUsers = useSelector(state => state.contentData.orgUsers);
    const [ coachStats, setCoachStats ] = useState({})
    const [ weeklyCoached, setWeeklyCoached ] = useState(0);
    const navigate = useNavigate();
    const statsRef = useRef();
    const dispatch = useDispatch();
    let statsListener;

    useLayoutEffect(() => {
        setTimeout(() => {
            switchOrgMenu('org-menu-coaches')
        }, 50);
    }, [])

    useEffect(() => {
        if (!recentActivity || !orgUsers) {return;}
        processCoachData(); 
    }, [recentActivity, orgUsers])

    useEffect(() => {
        if (!currentOrg) {return;}
        getCoachStatDocs();
        // getBlurbs()
    }, [currentOrg])

    function processCoachData() {
        // console.log(recentActivity);
        const now = new Date().getTime();
        const arr = Object.values(recentActivity).filter(a => a.startDate < now)
        // console.log(arr);
        let coachObj = {}
        for (let c of Object.values(orgUsers).filter(u => u.role.includes('coach'))) {
            // console.log(arr.filter(a => a.appId === c.appId));
            const coachedCount = new Set(arr.filter(a => a.appId === c.appId).map(a => a.personId)).size;
            coachObj[c.appId] = {
                'coached': coachedCount,
                'logs': arr.filter(a => a.appId === c.appId).length,
            }
        }
        // console.log(coachObj);
        setWeeklyCoached(coachObj)
    }

    async function getCoachStatDocs() {
        getCoachStats({'orgId': currentOrg.orgId, 'callback': callback, 'receiveListener': receiveListener})
        function callback(data) {
            statsRef.current = {...statsRef.current, ...{[data.appId]: data}}
            setCoachStats(statsRef.current)
        }
        function receiveListener(unsubscribe) {
            statsListener = unsubscribe
        }
    }
    
    return (
        <div>
            <div className='section-title with-icon'>
                <span>Org Coaches</span>
                <FontAwesomeIcon className='icon' title="Create New Organization" icon={faPlusCircle} onClick={() => navigate('add')} />
            </div>
            <div className='people-list'>
                <div className="g-list-item people-list-item">
                    <div>Name</div>
                    <div>Last Activity</div>
                    <div>Active {currentOrg.termPersonPlural ?? 'People'}</div>
                    <div>{currentOrg.termPersonPlural ?? 'People'} Coached (7 days)</div>
                    <div>Hours logged (7 days)</div>
                    <div>Activity Logs (7 days)</div>
                    
                </div>
                {
                    (orgUsers && coachStats) &&
                    Object.values(orgUsers).sort((a, b) => (a.lName > b.lName) ? 1 : (a.lName === b.lName) ? ((a.fName > b.fName) ? 1 : -1) : -1 )
                    .filter(user => user.status === 'active' && user.role.includes('coach'))
                    .map(p => (
                    <div key={p.appId} className="g-list-item people-list-item clickable" onClick={() => navigate(`view/${p.appId}`)}>
                        <div className='name'><b>{p.lName}, {p.fName}</b></div>
                        <div>{coachStats[p.appId] ? format(new Date(coachStats[p.appId].lastActivity), 'P') : 'No activity'}</div>
                        <div>{coachStats[p.appId] && coachStats[p.appId].activePeopleCount ? coachStats[p.appId].activePeopleCount : 0}</div>
                        <div>{weeklyCoached && weeklyCoached[p.appId] ? weeklyCoached[p.appId].coached : 0}</div>
                        <div>{recentActivity ? `${+(Object.values(recentActivity).filter(a => a.startDate < new Date().getTime() && a.appId === p.appId).reduce((acc, cv) => acc + cv.duration, 0)/60).toFixed(2)} Hrs` : '0 Hrs'}</div>
                        <div>{weeklyCoached && weeklyCoached[p.appId] ? weeklyCoached[p.appId].logs : 0}</div>
                        
                        {/* <div>0</td>
                        <td>None</td>
                        <td>
                            <FontAwesomeIcon icon={faSquareArrowUpRight} className='clickable' onClick={() => navigate(p.personId)} />
                        </td> */}
                    </div>
                    ))
                }
            </div>
            {/* <table className='g-table'>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Status</th>
                        <th>Collabs</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (orgUsers) &&
                        Object.values(orgUsers).filter(user => user.status === 'active' && user.role.includes('coach'))
                        .sort((a,b) => a.lName.toLowerCase() > b.lName.toLowerCase() ? 1 : -1)
                        .map((user, index) => (
                            <tr key={user.appId}>
                                <td>{index + 1}</td>
                                <td>{user.fName} {user.lName}</td>
                                <td>{user.email}</td>
                                <td>{user.role.join(', ')}</td>
                                <td>{user.status}</td>
                                <td>0</td>
                                <td><FontAwesomeIcon icon={faArrowUpRightFromSquare} /></td>
                            </tr>
                        ))
                    }
                </tbody>
            </table> */}
            <div className="g-space-40"></div>
            <div className='section-title'>
                <span>Other Users</span>
            </div>
            <table className='g-table'>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Status</th>
                        <th>Collabs</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (orgUsers) &&
                        Object.values(orgUsers).filter(user => user.status !== 'active' || !user.role.includes('coach'))
                        .sort((a,b) => a.email.toLowerCase() > b.email.toLowerCase() ? 1 : -1)
                        .map((user, index) => (
                            <tr key={user.email}>
                                <td>{index + 1}</td>
                                <td>{user.fName} {user.lName}</td>
                                <td>{user.email}</td>
                                <td>{user.role && user.role.length ? user.role.join(', ') : user.role}</td>
                                <td>{user.status}</td>
                                <td>0</td>
                                <td><FontAwesomeIcon icon={faSquareArrowUpRight} /></td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}

export default CoachList