import React, { useEffect, useRef, useState } from 'react'
import './chat-input.styles.scss'

import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, faShare, faCircleXmark, faRotateRight } from '@fortawesome/free-solid-svg-icons'

import { getStorage, ref, uploadBytes, getDownloadURL, uploadString } from 'firebase/storage'

import { UseCloudFunction } from 'utils/firebase.utils'
import { resizeImage } from 'utils/resizeImage'

const ChatInput = ({ replyId }) => {

	const [ image, setImage ] = useState(null)
	const [ text, setText ] = useState('')
	const [ sending, setSending ] = useState(false)
	const currentOrg = useSelector(state => state.contentData.currentOrg)
	const profile = useSelector(state => state.userData.userProfile)
	const storage = getStorage();
    const imageRef = useRef();
	
	useEffect(() => {
		const input = document.getElementById('chat-text-input')
		input.addEventListener("keydown", (event) => {
 		 	input.style.removeProperty('height');
			input.style.height = (input.scrollHeight+2) + 'px';
		});
		input.addEventListener('mousedown', (event) => {
			input.style.removeProperty('height');
			input.style.height = (input.scrollHeight+2) + 'px';
		});
	}, [])

	async function handleUpload(file) {
        console.log(file.size)
		let newFile = window.URL.createObjectURL(file)
        if (!file.type.includes('gif')) { 
            const resizedImage = await resizeImage(file)
            newFile = window.URL.createObjectURL(resizedImage)
            console.log(newFile.size)
            imageRef.current = {url:newFile, file: resizedImage, 'name': file.name, 'type': file.type}
            setImage(imageRef.current);
        } else {
            imageRef.current = {url:newFile, file: file, 'name': file.name, 'type': file.type}
            setImage(imageRef.current)
        }
		const input = document.getElementById('chat-text-input')
		input.classList.remove('no-image')
		input.classList.add('image-uploaded')
	}

	async function handleSend(e) {
		setSending(true);
		console.log('sending chat')
		console.log(text)
		if (!text && !(image && image.file)) {
			console.log('no text or image')
			setSending(false);
			return;
		}
		const now = new Date().getTime();
		let newURL = '';
		if (image && image.file) {
			const chatRef = ref(storage, `uploaded-chat-images/${now}-${image.name}`)
			const metadata = { contentType: image.type }
			await uploadBytes(chatRef, image.file, metadata)
			.then(snap => console.log(snap))
				
			newURL = await getDownloadURL(chatRef)
			console.log(newURL)
		}
		const data = {
			'orgId': currentOrg.orgId,
			'profile': profile,
			'chat': {
				'text': text,
				'image': newURL,
			},
			'ts': now
		}
		if (replyId) {
			data.chat.replyId = replyId
		}
		console.log(data)
		await UseCloudFunction('submitChat', data)
		.then(res => {
			console.log(res)
			setText('')
			setImage(null)
			setSending(false);
		}).catch(err => console.log(err))
        // const newChat = document.getElementById(`chat-${now}`)
        // newChat.scrollIntoView({'behavior': 'smooth'});
	}
	
	return (
		<div className='chat-input'>
			<div className='chat-input-content'>
				<div style={{cursor: 'pointer'}}>
					<input type="file" accept="image/*" id="fileUpload" onChange={(e) => handleUpload(e.target.files[0])}  />
					<FontAwesomeIcon className='icon-link image-icon' icon={faImage} />
				</div>
				<textarea type='text' placeholder={(replyId) ? 'Reply...' : ''} id='chat-text-input' rows='1' className='uk-textarea chat-input-box no-image' value={text} onChange={e => setText(e.target.value)}></textarea>
				{
					(sending)
					? 
					<FontAwesomeIcon className='icon-link icn-spinner' icon={faRotateRight} />
					: 
					<FontAwesomeIcon className='icon-link send-button' icon={faShare} onClick={(e) => handleSend()} />
				}
				
			</div>
			{
				(image) 
				? 
				<div className='image-thumb-container'>
					<img src={image.url} className='image-thumb' alt='upload thumbnail' />
					<FontAwesomeIcon className='icon-link' icon={faCircleXmark} onClick={e => setImage(null)}  />
				</div>
				: ''
			}
		</div>
	)
}

export default ChatInput;