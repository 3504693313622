import React, { useEffect, useState } from 'react'
import './goal-item.styles.scss'

import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import DOMPurify from 'isomorphic-dompurify';
import { useSelector } from 'react-redux';
import Modal from 'components/modal/modal.component';
import { useNavigate } from 'react-router-dom';
import { UseCloudFunction } from 'utils/firebase.utils';
import UIkit from 'uikit';


const GoalItem = ({ goal, saveToActivity, showButtons, chosenGoals }) => {

    const currentOrg = useSelector(state => state.contentData.currentOrg);
    const [ cycle, setCycle] = useState(null);
    const [ newStep, setNewStep ] = useState(null);
    const [ cycleLoading, setCycleLoading ] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!goal) {return;}
        setNewStep(goal.cycleStep);
    }, [goal])
    function showDetails(id) {
        const els = document.getElementsByClassName('details');
        for (let el of els) {
            if (el.id === `details-${id}`) {
                el.classList.toggle('hidden');    
            } else {
                el.classList.add('hidden');
            }
        }
    }

    async function changeCycleStep(id) {
        setCycleLoading(true);
        let goalObj = {...goal};
        goalObj.cycleStep = newStep;
        const res = await UseCloudFunction('changeGoalCycleStep', {'goalObj': goalObj})
        console.log(res);
        setCycleLoading(false);
        if (res.error) {
            UIkit.modal.alert('Something went wrong. Try again later.<br />' + res.error)
            return;
        }
        setCycle(null);
        UIkit.modal.alert('Goal cycle step changed successfully.')
    }

    return (
        <div className={`goal-item ${goal.status === 'complete' && 'complete'}`}>
            <div className='title-div' onClick={() => showDetails(goal.ts)}>
                <p className='title'><b>{goal.title}</b></p>
                <div className='info-row'>
                    <p className='meta'>Created: {format(new Date(goal.ts), 'P')}</p>
                </div>
            </div>
            <div className='info-div'  onClick={() => showDetails(goal.ts)}>
            {
                (goal.cycleStep > -1) &&
                <p><span className='meta'>Step:</span> <br /><b>{currentOrg.cycleTerms[goal.cycleStep].name} ({goal.cycleStep + 1})</b></p>
            }
            </div>
            <div className='details hidden' id={`details-${goal.ts}`}>
                <span><b>Notes:</b></span><br />
                {<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(goal.desc) }} />}
                <div className="g-space-20"></div>
                {/* <div className='action-button-row'>
                    <FontAwesomeIcon icon={faPen}  />
                    <FontAwesomeIcon icon={faTrash} />    
                </div> */}
                
                {  
                    (!saveToActivity) ?
                    <div className='button-row'>
                        {
                            (showButtons) &&
                            <><hr /><button className='g-button tiny' onClick={() => navigate(`/coach/editgoal/${goal.ts}`)}>Edit</button>
                            <button className='g-button tiny' onClick={() => setCycle(true)}>Cycle</button></>
                        }
                    </div>
                    :
                    <div>
                        <hr />
                        <div className='save-to-activity-div'>
                            
                            <div className='flex'>
                                <span>Cycle to Step:</span>
                                <div className='field'>
                                    <select className='small' id={`goal-step-${goal.ts}`} defaultValue={goal.cycleStep}>
                                        {
                                            (currentOrg.cycleTerms) &&
                                            currentOrg.cycleTerms.map((c, index) => (
                                                <option key={c.order} value={index}>{c.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <button className='g-button ' onClick={() => {console.log(Number(document.getElementById(`goal-step-${goal.ts}`).value)); saveToActivity(goal.ts.toString(), Number(document.getElementById(`goal-step-${goal.ts}`).value))}}>{chosenGoals && chosenGoals.includes(goal.ts.toString()) ? 'Remove From Activity' : 'Save To Activity'}</button>
                        </div>
                    </div>
                }
                <hr />
                <p className='meta'><b>ID: </b>{goal.ts}</p>
            </div>
            <Modal show={cycle} cancel={() => setCycle(null)} closeButton={true} text='Goal Cycle' cls='narrow'>
                <div>
                    <h4>Goal: <b>{goal.title}</b></h4>
                    <p>Notes: <br /><span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(goal.desc) }} /></p>
                    <p>Current Cycle Step:<b> {currentOrg.cycleTerms[goal.cycleStep].name} ({goal.cycleStep + 1})</b></p>
                    
                    <div className='field'>
                        <label>Change Cycle Step:</label>
                        <div className='g-double-col'>
                            <select value={newStep ?? 0} onChange={(e) => {console.log(e.target.value); setNewStep(Number(e.target.value))}}>
                                {
                                    currentOrg.cycleTerms.map((c, index) => (
                                        <option key={c.order} value={index}>{c.name}</option>
                                    ))
                                }
                            </select>
                            <div>
                                {
                                    !cycleLoading
                                    ?
                                    <button className='g-button primary submit' onClick={() => changeCycleStep(goal.ts)}>Change Cycle Step</button>
                                    :
                                    <button className='g-button disabled submit' ><div data-uk-spinner='ratio: .5'></div></button>
                                }
                            </div>
                        </div>
                    
                    </div>
                    
                </div>
            </Modal>
        </div>
    )
}

export default GoalItem;