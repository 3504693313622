import React, { useEffect, useRef, useState } from 'react';
import './view-person.styles.scss';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { getActivePeople, getActivity } from 'utils/getUserData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBullseye, faCalendarAlt, faFlagCheckered, faHandshakeAngle, faLineChart, faListCheck, faPenToSquare, faSquareArrowUpRight } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import PersonInfoCard from './info-card.component';
import { switchOrgMenu } from 'components/ORG-MANAGER/org-sidebar-left/org-sidebar-left.component';
import { format } from 'date-fns';
import ActivityList from './components/activity-list.component';
import GoalsList from './components/goals-list.component';
import UIkit from 'uikit';
import { UseCloudFunction } from 'utils/firebase.utils';
import { setMyPeople } from 'state/slices/contentSlice';
import ActivityItem from 'components/shared/activity-item/activity-item.component';
import GoalItem from 'components/shared/goal-item/goal-item.component';
import CalendarPage from '../calendar/calendar-page.component';
import NewPerson from '../coach-landing/new-person/new-person.component';
import PendingPeopleList from '../pending-people/pending-people.component';
import UploadList from '../coach-landing/upload-list/upload-list.component';
import NewGoal from './components/new-goal.component';
import ActivityListFull from '../activity-list/activity-list-container.component';
import GoalList from '../goal-list/goal-list.component';
import NewActivity from './components/new-activity.component';
import ToDoContainer from '../todo-list/todo-container.component';
import ReportsLanding from 'components/ORG-MANAGER/reports/reports-landing.component';
import PersonReportsContainer from '../reports/person-reports-container.component';

const ViewPerson = ({ todoList, setTodoList }) => {

    const currentOrg = useSelector(state => state.contentData.currentOrg);
    const profile = useSelector(state => state.userData.userProfile);
    const myGoals = useSelector(state => state.contentData.myGoals);
    const myPeople = useSelector(state => state.contentData.myPeople);
    const { personId } = useParams();
    const [ personData, setPersonData ] = useState(null);
    const [ personGoals, setPersonGoals ] = useState([]);
    const [ hourTotal, setHourTotal ] = useState(0);
    const [ activity, setActivity ] = useState({});
    const [ height, setHeight ] = useState(0);
    const navigate = useNavigate();
    const activityRef = useRef();
    const dispatch = useDispatch();
    let personListener;
    let activityListener;

    useEffect(() => {
        switchOrgMenu('coach-my-people')
        // const divHeight = document.getElementById('activity-card').offsetHeight
        // setHeight(divHeight - 70);
    }, [])

    useEffect(() => {
        if (!personId || !myGoals) {return;}
        let goals = Object.values(myGoals).filter(g => g.personId === personId || g.personId.includes(personId))
        // console.log(goals);
        setPersonGoals(goals)
    }, [personId, myGoals])

    useEffect(() => {
        if (!personId) {return;}
        getPerson();
        getUserActivity();

        return () => {
            if (personListener) {
                console.log('detaching person listener');
                personListener();
            }
        }
    }, [personId])

    useEffect(() => {
        if (!activity) {return;}
        const hourCount = Object.values(activity).filter(a => a.startDate <= new Date().getTime()).reduce((acc, curr) => acc + curr.duration, 0 );
        setHourTotal(hourCount);
    }, [activity])

    async function getPerson() {
        await getActivePeople({'personId': personId, 'callback': personCallback, 'receiveListener': receiveListener});
        function personCallback(e) {
            // console.log(e);
            setPersonData(e);
        }
        function receiveListener(e) {
            personListener = e;
        }
    }

    async function getUserActivity() {
        getActivity({'personId': personId, 'callback': activityCallback, 'receiveListener': receiveActivityListener, 'status': 'active'})
        function receiveActivityListener(unsubscribe) {
            activityListener = unsubscribe;
        }
        function activityCallback(data) {
            activityRef.current = {...activityRef.current, ...{[data.ts]: data}};
            setActivity(activityRef.current);
        }
    }
    
    async function deletePerson(personId) {
        const approved = await UIkit.modal.confirm('Are you sure you want to delete this person? This action cannot be undone.')
        .then(() => { return true; }, () => { return false; })
        if (!approved) {return;}
        const res = await UseCloudFunction('deleteActivePerson', {'personId': personId, 'appId': profile.appId, 'orgId': currentOrg.orgId})
        console.log(res);
        if (res.error) {
            UIkit.modal.alert('Something went wrong. Try again later. <br />' + res.error);
            return;
        }
        const people = {...myPeople}
        delete people[personId]
        dispatch(setMyPeople(people))
        navigate('/coach/mypeople')
    }

    async function deleteActivity(activity) {
        const approved = await UIkit.modal.confirm('Are you sure you want to delete this activity?')
        .then(() => { return true; }, () => { return false; })
        if (!approved) {return;}
        const res = await UseCloudFunction('deleteActivity', {'activity': activity, 'appId': profile.appId})
        if (res.error) {
            UIkit.modal.alert('Something went wrong. Try again later.<br />' + res.error);
            return;
        }
        const activityList = {...activityRef.current}
        delete activityList[activity.ts];
        activityRef.current = activityList;
        setActivity(activityRef.current);
    }    
    

    return (
        <div className='view-person-container'>
            <Routes>
                <Route path='' element={
                    <div className='view-person-content'>
                        <div className='back-div'><span className='back-icon' onClick={() => navigate('/coach')}><FontAwesomeIcon icon={faArrowLeft} />Back</span></div>
                        <div className='view-person-grid'>
                            <div className='info-card'>
                                <PersonInfoCard personData={personData} deletePerson={deletePerson} />
                            </div>
                            <div className='data-row'>
                                <div className='g-card'>
                                    Total Hours Completed
                                    <div className='data-point'>
                                        {+(hourTotal/60).toFixed(2)}
                                    </div>
                                </div>
                                <div className='g-card'>
                                    Logs Completed
                                    <div className='data-point'>
                                        {activity ? Object.values(activity).length : 0}
                                    </div>
                                </div>
                                <div className='g-card'>
                                    Goals Completed
                                    <div className='data-point'>
                                        {personGoals.filter(g => g.status === 'complete').length} / {personGoals.length}
                                    </div>
                                </div>
                            </div>
                            
                            <div className='link-row'>
                                <div className='g-card link-box'>
                                    <button className='g-button med-btn goal' onClick={() => navigate(`/coach/addgoal/${personId}`)}><FontAwesomeIcon icon={faFlagCheckered} />Add Goal</button>
                                    <button className='g-button med-btn activity' onClick={() => navigate(`/coach/addactivity/${personId}`)}><FontAwesomeIcon icon={faPenToSquare} />New Activity Log</button>
                                    {/* <button className='g-button med-btn import' onClick={() => navigate('import')}><FontAwesomeIcon icon={faCalendarAlt} />Import Events</button> */}
                                    {/* <button className='g-button med-btn'><FontAwesomeIcon icon={faListCheck} />To Do List</button> */}
                                    <button className='g-button med-btn todo' onClick={() => navigate(`/coach/todolist/${personId}`)}><FontAwesomeIcon icon={faListCheck} />To Do List</button>
                                    <button className='g-button med-btn report' onClick={() => navigate(`reports`)}><FontAwesomeIcon icon={faLineChart} />View Reports</button>
                                </div>
                            </div>
                            <div className='g-double-col goals-activity'>
                                <div className='g-card'>
                                <div className='small-title-with-icon'>
                                        <span>Active Goals</span>
                                        <FontAwesomeIcon className='clickable' icon={faSquareArrowUpRight} onClick={() => navigate(`/coach/goals/${personId}`)} />
                                    </div>
                                    <hr className='no-top-margin' />
                                    <div className='scrolling-content'>
                                        {(personGoals && currentOrg) &&
                                            personGoals.filter(g => g.status === 'incomplete')
                                            .map(g => (
                                                <div key={g.ts} >
                                                    <GoalItem goal={g} showButtons={true} />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className='g-card' id='activity-card'>
                                    <div className='small-title-with-icon'>
                                        <span>Recently Recorded Logs</span>
                                        <FontAwesomeIcon className='clickable' icon={faSquareArrowUpRight} onClick={() => navigate(`/coach/activitylist/${personId}`)} />
                                    </div>
                                    <hr className='no-top-margin' />
                                    <div className='scrolling-content'>
                                    {
                                        (activity && myGoals) &&
                                        Object.values(activity).sort((a,b) => a.ts < b.ts ? 1 : -1)
                                        .map(a => (
                                            <div key={a.ts}>
                                                <ActivityItem activity={a} showButtons={true} myGoals={myGoals} deleteActivity={deleteActivity} />
                                            </div>
                                        ))
                                    }
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                }></Route>
                
                <Route path='reports/*' element={<PersonReportsContainer activity={activity} goals={personGoals} />}></Route>
                
                {/* <Route path=':personId' element={<ViewPerson />}></Route> */}
                
                
                
                {/* <Route path='people/:personId/goals/:goalId' element={<NewGoal />}></Route> */}
                
                {/* <Route path='people/:personId/activityList/:activityId' element={'Coming soon'}></Route> */}
                {/* <Route path=':sourcePersonId/activity/add' element={<NewActivity />}></Route>
                
                <Route path=':personId/todolist/:todoId' element={'edit todo item'}></Route>
                <Route path=':personId/import' element={<ImportEvents />}></Route> */}
                <Route path="*" element={<Navigate replace to="/404" />} /> 
            </Routes>
        </div>
    )
}

export default ViewPerson