import React, { useEffect, useRef, useState } from 'react'
import './manage-org-landing.styles.scss';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import Layout from 'components/layout/layout.comp';
import { getLastActivity, getOrgUsersFromDb, getOrgsFromDb } from 'utils/getAdminData';
import OrgSettings from './org-settings/org-settings.component';
import { switchOrgMenu } from './org-sidebar-left/org-sidebar-left.component';
import OrgLanding from './org-landing/org-landing.component';
import CoachList from './manage-coaches/coach-list.component';
import CoachView from './manage-coaches/coach-view.component';
import AddCoach from './manage-coaches/add-coach.component';
import { useDispatch, useSelector } from 'react-redux';
import { setChat, setCurrentOrg, setMyOrgs, setOrgUsers } from 'state/slices/contentSlice';
import { getActivity, getChats } from 'utils/getUserData';
import InviteHandler from 'components/invite-handler/invite-handler.component';
import ReportsLanding from './reports/reports-landing.component';
import CoachPage from 'pages/coach/coach.page';
import AdminPersonView from './manage-coaches/person-view.component';
import ViewPerson from 'components/COACH/view-person/view-person.component';
import ReportsContainer from './reports/reports-container.component';

const ManageOrg = () => {

    const currentOrg = useSelector(state => state.contentData.currentOrg);
    const myOrgs = useSelector(state => state.contentData.myOrgs);
    const [ orgData, setOrgData ] = useState(null);
    const [ recentActivity, setRecentActivity ] = useState(null);
    const [ lastActivity, setLastActivity ] = useState(null);
    const [ approvedRole, setApprovedRole ] = useState(false);
    const userListRef = useRef();
    const activityRef = useRef();
    const lastActivityRef = useRef();
    const chatRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let orgListener; 
    let recentActivityListener;
    let chatListener;

    useEffect(() => {
        checkRole();
        setTimeout(() => {
            switchOrgMenu('org-menu-home')
        }, 50);
        return () => {
            if (orgListener) {
                console.log('detaching org listener');
                orgListener();
            }
            if (recentActivityListener) {
                console.log('detaching recentActivity listener');
                recentActivityListener();
            }
        }
    }, [])

    function checkRole() {
        if (currentOrg && (currentOrg.role.includes('primaryContact') || currentOrg.role.includes('orgManager'))) {
            setApprovedRole(true);
        } else {
            navigate('/coach')
        }
    }

    useEffect(() => {
        if (currentOrg) {
            userListRef.current = null
            dispatch(setOrgUsers(userListRef.current));
            getData(currentOrg.orgId);
        }
    }, [currentOrg])

    async function getData(sentOrgId) {
        getOrgData(sentOrgId);
        getOrgUsers(sentOrgId);
        getActivityData(sentOrgId);
        getChatData(sentOrgId);
    }

    async function getOrgData(sentOrgId) {
        getOrgsFromDb({'orgId': sentOrgId, 'callback': orgCallback, 'receiveListener': receiveListener})
        async function orgCallback(org) {
            setOrgData(org);
        }
        function receiveListener(unsubscribe) {
            orgListener = unsubscribe;
        }
    }

    async function getOrgUsers(sentOrgId) {
        getOrgUsersFromDb({'orgId': sentOrgId, 'callback': userCallback})
        async function userCallback(user) {
            if (user.status === 'pending') {
                userListRef.current = {...userListRef.current, ...{[user.email]: user}}
            } else {
                userListRef.current = {...userListRef.current, ...{[user.appId]: user}}
            }
            
            dispatch(setOrgUsers(userListRef.current));
            
            if (user.status === 'active') {
                // console.log(user.appId);
                getLastActivity(user.appId, saveLastActivity)
            }
        }
    }

    async function getActivityData(sentOrgId) {
        const currentDate = new Date();
        const sevenDaysAgo = new Date(currentDate);
        sevenDaysAgo.setDate(currentDate.getDate() - 7);
        sevenDaysAgo.setHours(0, 0, 0, 0);
        activityRef.current = null;
        setRecentActivity(activityRef.current);
        getActivity({'orgId': sentOrgId, 'startDate': sevenDaysAgo.getTime(), 'callback': recentActivityCallback, 'receiveListener': receiveRecentActivityListener, 'status': 'active'})
        async function recentActivityCallback(data) {
            activityRef.current = {...activityRef.current, ...{[data.ts]: data}};
            setRecentActivity(activityRef.current);
        }
        function receiveRecentActivityListener(unsubscribe) {
            recentActivityListener = unsubscribe;
        }
    }
    
    async function getChatData(sentOrgId) {
        chatRef.current = null;
        dispatch(setChat(chatRef.current));
        getChats({'orgId': sentOrgId, 'callback': chatCallback, 'receiveListener': receiveChatListener})
        async function chatCallback(e) {
            chatRef.current = {...chatRef.current, ...{[e.ts]: e}}
            dispatch(setChat(chatRef.current))
        }
    
        function receiveChatListener(unsubscribe) {
            chatListener = unsubscribe;
        }
    
    }
    
    function saveLastActivity(appId, info) {
        // console.log(appId, info);
        if (info) {
            lastActivityRef.current = {...lastActivityRef.current, ...{[appId]: Object.values(info)[0].ts}}
        } else {
            lastActivityRef.current = {...lastActivityRef.current, ...{[appId]: null}}
        }
        setLastActivity(lastActivityRef.current);
    }

    async function saveOrgData(newOrgData) {
        newOrgData.role = myOrgs[newOrgData.orgId].role;
        setOrgData(newOrgData);
        dispatch(setCurrentOrg(newOrgData))
        const newMyOrgList = {...myOrgs};
        newMyOrgList[newOrgData.orgId] = {...newMyOrgList[newOrgData.orgId], ...newOrgData};
        dispatch(setMyOrgs(newMyOrgList));
    }

    
    return (
        <div>
            {
                (approvedRole) &&
                <Layout>
                    <Routes>
                        <Route path='' element={<OrgLanding recentActivity={recentActivity} lastActivity={lastActivity} />}></Route>
                        <Route path='settings' element={<OrgSettings orgData={orgData} saveOrgData={saveOrgData} />} ></Route>
                        <Route path='users' element={<CoachList recentActivity={recentActivity} />}></Route>
                        <Route path='users/add' element={<AddCoach orgData={orgData} />}></Route>
                        <Route path='users/view/:appId/*' element={<CoachView />}></Route>
                        <Route path='reports/*' element={<ReportsContainer />}></Route>
                        <Route path="*" element={<Navigate replace to="/404" />} />
                    </Routes>
                    <InviteHandler />   
                </Layout>
            }
        </div>
    )
}

export default ManageOrg;