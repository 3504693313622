import React, { useEffect, useState } from 'react'
import Chart from "react-apexcharts";

const Chart4 = ({ recentActivity, showCats }) => {

    const [ boxHeight, setBoxHeight ] = useState(0);

    useEffect(() => {
        const box = document.querySelector('.focus-chart');
        if (box) {
            const height = box.offsetHeight;
            setBoxHeight(height - 28);
        }
    }, [])
  
    const [ data, setData ] = useState({
        series: [{
            name: "",
            data: []
        }],
        options: {}
    });
        
    useEffect(() => {
        if (!recentActivity) {return;}
        const filteredData = getFilteredData(recentActivity);
        // console.log(filteredData)
        const dataObj = {
            options: {
                chart: {
                    stacked: true,
                    // stackType: "100%",
                    width: '100%',
                    // height: '200px',
                    zoom: {
                        enabled: false
                    },
                },
                dataLabels: {                            
                    formatter: (value) => {
                        return +value.toFixed(2)
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        dataLabels: {                            
                            formatter: (value) => {
                                return +value.toFixed(2)
                            },
                            total: {
                                enabled: true,
                                offsetX: 20,
                                style: {
                                    fontSize: '13px',
                                    // fontWeight: 900
                                },
                                
                            },
                        }
                    },
                    // dataLabels: {
                    //     show: false,
                    // },
                },
                xaxis: {
                    categories: ['Data'],
                    
                },
                yaxis: {
                    show: false,
                    
                },
                tooltip: {
                    y: {
                        formatter: function (value) {
                            return +value.toFixed(2)
                        }
                    }
                },
                // title: {
                //     text: 'Focus Areas',
                //     align: 'left'
                // },
                legend: {
                    show: showCats,
                },
                theme: {
                    // monochrome: {
                    //     enabled: true,
                    //     color: '#F27634',
                    //     shadeTo: 'light',
                    //     shadeIntensity: 0.65
                    //   }
                    palette: 'palette2'
                },
            },
            
            series: filteredData,
        }
        // console.log(dataObj);
        setData(dataObj);
    }, [recentActivity]);

    function getFilteredData() {
        let dataObj = {}
        for (let obj of recentActivity) {
            if (!obj.startDate || obj.startDate > new Date().getTime()) {continue;}
            if (dataObj[obj.activityCategory]) {
                const count = dataObj[obj.activityCategory].data[0]
                dataObj[obj.activityCategory].data = [(count + +(obj.duration/60).toFixed(2))]
            } else {
                dataObj[obj.activityCategory] = {
                    'name': obj.activityCategory === 'other' ? 'Other Duties' : obj.activityCategory === 'visit' ? 'In-Person Visit' : obj.activityCategory === 'meeting' && 'Meeting',
                    'data': [+(obj.duration/60).toFixed(2)],
                }
            }
        }
        const series = Object.values(dataObj).toSorted((a,b) => a.data[0] < b.data[0] ? 1 : -1);
        return series;
    }
  
    return (
        <div>
            {
                (data) && 
                <Chart
                    options={data.options}
                    series={data.series}
                    type="bar"
                    height={`${boxHeight}px`}
                />
            }
        </div>
    )
}

export default Chart4