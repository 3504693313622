import React, { useEffect, useState } from 'react'
import './coach-settings.styles.scss';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCalendar, faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { UseCloudFunction, auth } from 'utils/firebase.utils';
import Modal from 'components/modal/modal.component';
import UIkit from 'uikit';
import { setUserProfile } from 'state/slices/userSlice';
import { switchOrgMenu } from 'components/ORG-MANAGER/org-sidebar-left/org-sidebar-left.component';

const CoachSettings = () => {

    const navigate = useNavigate();
    const profile = useSelector(state => state.userData.userProfile);
    const currentUser = useSelector(state => state.userData.currentUser);
    const [ calendars, setCalendars ] = useState(null);
    const [ selectedCalendar, setSelectedCalendar ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ token, setToken ] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        switchOrgMenu('coach-settings');
    }, [])

    useEffect(() => {
        if (!profile) {return;}
        if (profile.gCalId && profile.gCalName && profile.gCalColor) {
            setSelectedCalendar({
                'calendarId': profile.gCalId,
                'calendarColor': profile.gCalColor,
                'calendarName': profile.gCalName,
            })
        }
    }, [profile])
    
    async function getCalendars() {
        setLoading(true);
        const access_token = profile.access_token;
        const refresh_token = profile.refresh_token;
        const res = await UseCloudFunction('importCalendarsFromGoogle', {'uid': currentUser.uid, 'access_token': access_token,'refresh_token': refresh_token});
        console.log(res);
        setCalendars(res.cal);
        setLoading(false)
    }

    async function chooseCalendar(id, color, name) {
        const res = await UseCloudFunction('saveGoogleCalendar', {'appId': profile.appId, 'calendarId': id, 'calendarColor': color, 'calendarName': name})
        console.log(res);
        if (res.error) {
            UIkit.modal.alert('Something went wrong. Try again later. <br />' + res.error);
            return;
        }
        dispatch(setUserProfile({...profile, ...{
            'gCalId': id,
            'gCalColor': color,
            'gCalName': name,
        }}))
        setCalendars(null);
        UIkit.modal.alert('Google Calendar saved successfully!');
    }

    return (
        <div className='coach-settings-container'>
            <div className='coach-settings-content'>
                <div className='back-div'><span className='back-icon' onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowLeft} />Back</span></div>
                <div className='g-card'>
                    <div className='section-title with-icon'>
                    Connect Google Calendar
                    </div>
                    <hr />
                    <p><b>Connected Calendar: </b>
                    {
                        (selectedCalendar) 
                        ?
                        <span>
                            <FontAwesomeIcon className='calendar-icon' icon={faCalendarDay} style={{'color': selectedCalendar.calendarColor}} />
                            {selectedCalendar.calendarName}
                        </span>
                        :
                        'No calendar connected'
                    }
                    </p>
                    {
                        (!loading) ?
                        <button className='g-button calendar-button' onClick={() => getCalendars()}>Connect a Calendar</button>
                        :
                        <button className='g-button calendar-button'><div data-uk-spinner='ratio: .5'></div></button>
                    }
                    
                    <div>{token}</div>
                    <Modal show={calendars} cancel={() => setCalendars(null)} closeButton={true} text='Choose a Google Calendar to connect'>
                        <div className='cal-list'>
                            { (calendars && calendars.length > 0) ?
                                calendars.map(c => (
                                    (c.accessRole === 'owner') &&
                                    <div key={c.id} className='g-list-item clickable' onClick={() => chooseCalendar(c.id, c.backgroundColor, c.summary)} >
                                        <FontAwesomeIcon className='calendar-icon' icon={faCalendarDay} style={{'color': c.backgroundColor}} />
                                        {c.summary} {c.primary && '[PRIMARY]'}
                                    </div>
                                ))
                                : <h4>No calendars found</h4>
                            }
                        </div>
                    </Modal>
                </div>      
            </div>    
        </div>
    )
}

export default CoachSettings