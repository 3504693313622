import React from 'react'
import './people-list.styles.scss'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import MyPeopleList from 'components/COACH/my-people/my-people-list.component';

const AdminPeopleList = ({ people, goals }) => {

    const navigate = useNavigate();

    return (
        <div className='admin-people-list'>
            
            <div className='people-list-content'>
                <div className='back-div'><span className='back-icon' onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowLeft} />Back</span></div>
                <div className='g-card'>
                    <MyPeopleList myPeople={people} myGoals={goals} admin={true} />
                </div>
            </div>
        </div>
    )
}

export default AdminPeopleList;