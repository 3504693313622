import React, { useState, useEffect } from 'react'
import './org-sidebar-left.styles.scss'

import { useNavigate } from 'react-router-dom';
import { mediaQueries } from 'utils/responsiveQueries';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug, faChartArea, faChartLine, faGear, faHome, faList, faPenSquare, faPenToSquare, faUsers } from '@fortawesome/free-solid-svg-icons';
import BugModal from 'components/bug-modal/bug-modal.component';

export function switchOrgMenu(e) {
    console.log(e);
    const menuItems = document.getElementsByClassName('menu-item')
    for (let item of menuItems) {
        item.classList.remove('active')
    }
    if (document.getElementById(e)) {
        document.getElementById(e).classList.add('active');
    }
}

const OrgSidebarLeft = ({ toggleMenu, orgId, closeSlider }) => {

    const [ viewDrop, setViewDrop ] = useState(false);
    const [ showServices, setShowServices ] = useState(false);
    const [ showSearchModal, setShowSearchModal ] = useState(false);
    const [ showBugModal, setShowBugModal ] = useState(false);
    const isMobileDevice = useMediaQuery({[mediaQueries.mobile.type]: mediaQueries.mobile.size});
    const navigate = useNavigate();

    function chooseMenuItem(e) {
        navigate(e);
        if (toggleMenu) {
            setTimeout(() => {        
                toggleMenu();
            }, 200);
        }
    }

    return (
        <div>
            <div className={`org-sidebar-container ${isMobileDevice ? 'mobile' : ''}`}>

                
                <div className="g-height-20"></div>
                {/* <div className="button-container">
                    
                    <button className={`start-button ${showSearchModal ? 'clicked' : ''}`} onClick={() => setShowSearchModal(true)}>
                        <span className="circle" aria-hidden="true">
                            <span className="icon arrow"></span>
                        </span>
                        <span className="button-text">Get Started</span>
                    </button>
                </div> */}
                <div className={`drop-container ${viewDrop ? 'active' : ''}`}>
                    <div className="drop-menu">
                        
                        {/* <div className="drop-menu-item" id="newFolder" onClick={() => setShowSearchModal(true)}> 
                            <img src={require('assets/icons/play.png')} className="drop-menu-item-icon" />
                            Add To WatchList
                        </div>
                        <div className="drop-menu-item" id="newFolder" onClick={() => navigate('/home/newsuggestion')}> 
                            <img src={require('assets/icons/suggestion.png')} className="drop-menu-item-icon" />
                            Recommend
                        </div>
                        <div className="drop-menu-item" id="newFolder" onClick={() => navigate('/home/newchat')}> 
                            <img src={require('assets/icons/chat.png')} className="drop-menu-item-icon" />
                            New Chat
                        </div> */}
                        
                        
                    </div>
                </div>
                <div className="g-height-20"></div>
                <div className="menu-container">
                    <div className='menu-item' id='org-menu-home' onClick={() => {navigate(`/manage`); if (closeSlider) closeSlider()}}>
                        <FontAwesomeIcon className='menu-item-icon' icon={faHome} size='lg' />
                        Home 
                    </div>
                    <div className="menu-item" id='org-menu-coaches' onClick={() => {navigate('/manage/users'); if (closeSlider) closeSlider()}}>
                        <FontAwesomeIcon className='menu-item-icon' icon={faUsers} size='lg' />
                        Org Users
                    </div>
                    <div className="menu-item" id='org-menu-reports' onClick={() => {navigate('/manage/reports'); if (closeSlider) closeSlider()}}>
                        <FontAwesomeIcon className='menu-item-icon' icon={faChartLine} size='lg' />
                        Reports
                    </div>
                    <div className='menu-item' id='org-menu-settings' onClick={() => {navigate('/manage/settings'); if (closeSlider) closeSlider()}}>
                        <FontAwesomeIcon className='menu-item-icon' icon={faGear} size='lg' />
                        Settings
                    </div>
                    <div className="menu-item" id='org-menu-bugs' onClick={() => setShowBugModal(true)}>
                        <FontAwesomeIcon className='menu-item-icon' icon={faBug} size='lg' />
                        Report a Bug
                    </div>
                </div>
            </div>
            <BugModal show={showBugModal} cancel={() => setShowBugModal(false)} />
        </div>
    )
}

export default OrgSidebarLeft;