import React, { useEffect, useState } from 'react';
import './org-view.styles.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { getOrgsFromDb } from 'utils/getAdminData';
import OrgSettings from 'components/ORG-MANAGER/org-settings/org-settings.component';

const OrgView = () => {

    const { orgId } = useParams();
    const [ currentOrg, setCurrentOrg ] = useState();
    const navigate = useNavigate();
    let orgListener;
    
    useEffect(() => {
        getOrgData();
    }, [orgId])
    
    useEffect(() => {
        
        return () => {
            if (orgListener) {
                console.log('detaching org listener');
                orgListener();
            }
        }
    }, [])

    async function getOrgData() {
        const obj = {
            'callback': function (e) {orgsCallback(e)},
            'orgId': orgId,
            'receiveListener': function (e) {receiveListener(e)},
        }
        getOrgsFromDb(obj);
    } 

    function receiveListener(unsubscribe) {
        orgListener = unsubscribe;
    }

    async function orgsCallback(org) {
        setCurrentOrg(org);
    }

    return (
        <div>
            <button className='g-button small-btn' onClick={() => navigate(-1)}>Back</button>
            {currentOrg && 
                <OrgSettings orgData={currentOrg} />
            }
        </div>
    )
}

export default OrgView