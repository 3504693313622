import React, { useEffect } from 'react'
import './person-reports-container.styles.scss';
import ReportsLanding from 'components/ORG-MANAGER/reports/reports-landing.component';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import DeepDiveTable from 'components/ORG-MANAGER/reports/deep-dive/deep-dive-table';
import { switchOrgMenu } from 'components/ORG-MANAGER/org-sidebar-left/org-sidebar-left.component';
import { useSelector } from 'react-redux';
import PersonIcon from 'components/shared/person-icon.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const PersonReportsContainer = ({ activity, goals }) => {

    const myPeople = useSelector(state => state.contentData.myPeople);
    const { personId } = useParams();
    const navigate = useNavigate();
    
    useEffect(() => {
        switchOrgMenu('org-menu-reports');
    }, [])

    return (
        <div className='person-reports-container'>
            <Routes>
                <Route path='' element={
                    <div>
                        <div className='back-div'><span className='back-icon' onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowLeft} />Back</span></div>
                        <div className='section-title with-icon'>
                            <span>Reports</span>
                            {
                                (personId && myPeople) &&
                                <PersonIcon myPeople={myPeople} personId={personId} />
                            }
                        </div>
                        <hr />
                        
                        <ReportsLanding activity={activity} goals={goals} />
                    </div>
                }></Route>
                <Route path='deepdive' element={<DeepDiveTable goals={goals} />}></Route>
                <Route path="*" element={<Navigate replace to="/404" />} />
            </Routes>
            
        </div>
    )
}

export default PersonReportsContainer