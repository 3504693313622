import React, { useEffect, useRef, useState } from 'react';
import './goal-select-form.styles.scss';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import Modal from 'components/modal/modal.component';
import GoalItem from 'components/shared/goal-item/goal-item.component';
import NewGoalForm from './new-goal-form.component';
import { useLocation } from 'react-router-dom';

const GoalSelectForm = ({ formData, saveData }) => {

    const currentOrg = useSelector(state => state.contentData.currentOrg);
    const myGoals = useSelector(state => state.contentData.myGoals);
    const [ goalsToDisplay, setGoalsToDisplay ] = useState(null);
    const [ showGoals, setShowGoals ] = useState(false);
    const [ showNewGoal, setShowNewGoal ] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (!formData || !formData.personId || !myGoals) {return;}
        function getGoalsToPick() {
            // console.log(formData);
            let goalArr = [];
            for (let g of Object.values(myGoals)) {
                let goal = {...g};
                if (typeof goal.personId === 'string') {
                    goal.personId = [goal.personId]
                }
                let avail = true;
                for (let p of formData.personId) {
                    if (!goal.personId.includes(p)) {
                        avail = false;
                    }
                }
                if (avail) {
                    goalArr.push(goal);
                }
            }
            setGoalsToDisplay(goalArr);
        }
        getGoalsToPick();
    }, [formData, myGoals])

    function switchModals() {
        setShowGoals(!showGoals);
        setShowNewGoal(!showNewGoal);
    }
    

    function saveChosenGoal(id, nextCycleStep) {
        let chosenGoals = formData.goal ?? [];
        let goalNextSteps = formData.goalSteps ?? {};
        if (chosenGoals.includes(id) && !nextCycleStep) {
            chosenGoals.splice(chosenGoals.indexOf(id), 1)
            delete goalNextSteps[id];
        } else {
            if (!chosenGoals.includes(id)) {
                chosenGoals.push(id)
            }    
            goalNextSteps[id] = {
                'id': id,
                'nextStep': nextCycleStep,
            }
            console.log(chosenGoals)
        }
        saveData('goal', chosenGoals);
        saveData('goalSteps', goalNextSteps);
    }

    return (
        <div className='goal-select-form'>
            {
                (myGoals && formData) &&
                <div className="goal-choice-div">
                    <div>
                        <button className={`g-button `} onClick={() => setShowGoals(true)}> 
                            Select Goal(s)
                        </button>
                    </div>               
                    <div>
                        <div className='g-list-item selected-goal-title'>
                            {/* <p>Selected</p>
                            <hr /> */}
                            {
                                (formData.goal && formData.goal.length > 0 && formData.goalSteps) 
                                ?
                                formData.goal.map(g => (
                                    <div key={g} className='g-list-item goal-list-item'>
                                        <div className='goal-list-item-title'>{myGoals[g].title}</div>
                                        <div><div className='meta'>Cycle Step: </div>{currentOrg.cycleTerms[formData.goalSteps[g].nextStep].name} ({formData.goalSteps[g].nextStep + 1})</div>
                                        {
                                            !location.pathname.includes('/edit') &&
                                            <div className='align-right'>
                                                <FontAwesomeIcon icon={faX} className='clickable' title='Remove this goal' onClick={() => saveChosenGoal(g.toString())} />
                                            </div>
                                        }
                                    </div>
                                ))
                                :
                                <p>None selected...</p>
                            }
                            
                        </div>
                    </div>
                </div>
            }
            <Modal show={showGoals} cancel={() => setShowGoals(false)} closeButton={true}  text={`Choose Goal(s)`}>
                <div className='goal-modal'>
                    {/* <div className='align-right'>
                        <button className='g-button tiny' onClick={() => switchModals()}>Create New Goal</button>
                    </div> */}
                    {
                        (goalsToDisplay && goalsToDisplay.length > 0) ?
                        goalsToDisplay.toSorted((a,b) => a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1)
                        .filter(g => g.status === 'incomplete')
                        .map((g, index) => (
                            <div key={g.ts}>
                                <GoalItem goal={g} saveToActivity={saveChosenGoal} chosenGoals={formData.goal} />
                            </div>
                        ))
                        :
                        <h4>No Goals Available</h4>
                    }
                    <div className='g-space-10'></div>
                </div>
            </Modal>
            <Modal show={showNewGoal} cancel={() => setShowNewGoal(false)} closeButton={true} text={'Create a New Goal'}>
                <NewGoalForm handleSuccessfulCompletion={switchModals}/>
            </Modal>
        </div>
    )
}

export default GoalSelectForm