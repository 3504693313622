import React, { useEffect } from 'react'
import './my-resources.styles.scss'
import { useSelector } from 'react-redux'
import { switchOrgMenu } from 'components/ORG-MANAGER/org-sidebar-left/org-sidebar-left.component';

const MyResources = () => {
    
    const currentOrg = useSelector(state => state.contentData.currentOrg);

    useEffect(() => {
        switchOrgMenu('coach-my-resources')
    }, [])

    return (
        <div>my-resources.component</div>
    )
}

export default MyResources;