import React from 'react';
import './org-sidebar-container.styles.scss';
import OrgSidebarLeft from './org-sidebar-left.component';
import { useSelector } from 'react-redux';

const OrgSidebarLeftContainer = ({ closeSlider }) => {

    const currentOrg = useSelector(state => state.contentData.currentOrg);
    const myOrgs = useSelector(state => state.contentData.myOrgs);
    
    return (
        <div className='org-sidebar-left-container'>
            {
                (currentOrg.orgId && myOrgs) &&
                <div className='org-header'>
                    <img src={(myOrgs[currentOrg.orgId].icon && myOrgs[currentOrg.orgId].icon !== 'none') ? myOrgs[currentOrg.orgId].icon : require('assets/placeholder-icon-org.jpg')} />
                    <span>{myOrgs[currentOrg.orgId].name}</span>
                </div>
            }
            <div className=''>
                <OrgSidebarLeft orgId={currentOrg.orgId} closeSlider={closeSlider} />
            </div>
        </div>
    )
}

export default OrgSidebarLeftContainer