import React, { useState } from 'react';
import './layout.styles.scss';

import HeadBar from 'components/head-bar/head-bar.component';

import { Desktop, Mobile, Tablet } from 'utils/responsiveQueries';
import SidebarLeftContainer from 'components/COACH/sidebar-left/sidebar-container.component';
import SidebarRightContainer from 'components/sidebar-right/sidebar-container.component copy';
import { useLocation } from 'react-router-dom';
import OrgSidebarLeftContainer from 'components/ORG-MANAGER/org-sidebar-left/org-sidebar-container.component';
import HeaderMobile from 'components/head-bar/header-mobile.component';

const Layout = ({ children }) => {

    const location = useLocation();

    return (
        <div>
            <Desktop>
                <HeadBar />
                <div className='desktop-container'>
                    <div className='sidebar'>
                        {
                            (location.pathname.includes('/manage')) 
                            ?
                            <OrgSidebarLeftContainer />
                            :
                            <SidebarLeftContainer />
                        }
                    </div>
                    <div className='content-container'>
                        {children}
                    </div>
                </div>
            </Desktop>

            <Tablet>
                <HeaderMobile />
                <div className='tablet-content-container'>
                    {children}
                </div>
            </Tablet>

            <Mobile>
                <HeaderMobile />
                <div className='mobile-content-container'>
                    {children}
                </div>
            </Mobile>
            
        </div>
    )
}

export default Layout