import React from 'react';
import './my-orgs-modal.styles.scss'

import Modal from 'components/modal/modal.component';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCurrentOrg } from 'state/slices/contentSlice';

const MyOrgsModal = ({ show, cancel }) => {

    const myOrgs = useSelector(state => state.contentData.myOrgs)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    function chooseOrg(orgId) {
        cancel();
        dispatch(setCurrentOrg(myOrgs[orgId]))
        if (myOrgs[orgId].role.includes('primaryContact'||'orgManager')) {
            navigate(`/manage`);
        } else {
            navigate('/coach');
        }
    }

    return (
        <div>
            <Modal show={show} cancel={cancel} closeButton={true} text='Choose an organization'>
                <div className='org-modal-container'>
                    {
                        (myOrgs) &&
                        Object.values(myOrgs).sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                        .map(org => (
                            <div key={org.orgId} className='g-card org-card' onClick={() => chooseOrg(org.orgId)}>
                                <img src={org.icon !== 'none' ? org.icon : require('assets/placeholder-icon-org.jpg')} className='org-icon' />
                                <span>{org.name}</span>
                            </div>
                        ))
                    }
                </div>
            </Modal>
        </div>
    )
}

export default MyOrgsModal