import React, { useEffect, useRef, useState } from 'react';
import './org-terms.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setMyOrgs } from 'state/slices/contentSlice';
import UIkit from 'uikit';
import { UseCloudFunction } from 'utils/firebase.utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faCaretDown, faCaretUp, faTrash, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';

const OrgTerms = ({ orgData, saveOrgData }) => {
    
    const profile = useSelector(state => state.userData.userProfile);
    const myOrgs = useSelector(state => state.contentData.myOrgs);
    const [ termData, setTermData ] = useState({});
    const [ cycleTerms, setCycleTerms ] = useState([]);
    const dataRef = useRef();
    const cycleRef = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!orgData) {return;}
        dataRef.current = orgData;
        setTermData(dataRef.current)
        cycleRef.current = [...orgData.cycleTerms] ?? [];
        setCycleTerms(cycleRef.current);
    }, [orgData])

    function saveData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}};
        setTermData(dataRef.current);
    }

    function saveCycleTerm() {
        const cycleName = document.getElementById('cycle-name').value;
        const cycleDesc = document.getElementById('cycle-desc').value;
        let newTerms = [...cycleTerms];
        newTerms.push({'name': cycleName, 'desc': cycleDesc, 'order': cycleTerms.length + 1})
        console.log(newTerms)
        setCycleTerms(newTerms);
        document.getElementById('cycle-name').value = '';
        document.getElementById('cycle-desc').value = '';
    }

    function moveTerm(dir, order) {
        if (dir === 'delete') {
            let terms = [...cycleTerms];
            const newTerms = terms.filter(t => t.order !== order)
            setCycleTerms(newTerms);
            return;
        }
        if (cycleTerms.length === 1 || dir === 'up' && order === 1 || dir !== 'up' && order === cycleTerms.length) { return; }
        let order2;
        if (dir === 'up') {
            order2 = order - 1;
        } else {
            order2 = order + 1;
        }
        let terms = [...cycleTerms];
        const newTerms = terms.filter(t => t.order !== order && t.order !== order2)
        console.log(newTerms);
        let term1 = terms.filter(t => t.order === order)[0]
        let term2 = terms.filter(t => t.order === order2)[0]
        if (dir == 'up') {
            term1.order--;
            term2.order++;
        } else {
            term1.order++;
            term2.order--;
        }
        newTerms.push(term1);
        newTerms.push(term2);
        console.log(newTerms);
        setCycleTerms(newTerms);
    }

    async function saveNewData() {
        if (termData.termPersonSingular && !termData.termPersonPlural || !termData.termPersonSingular && termData.termPersonPlural) {
            UIkit.modal.alert('Please include both a singular and a plural version of your title for learners.')
            return;
        }
        if ((termData.termLevel2 && !termData.termLevel1) || (termData.termLevel3 && (!termData.termLevel2 || !termData.termLevel1)) || (termData.termLevel4 && (!termData.termLevel3 || !termData.termLevel2 || !termData.termLevel1))) {
            UIkit.modal.alert('You must have a parent level for each sub level. For example, you can\'t have a Group 2 without a Group 1.')
            return;
        }
        console.log(termData)
        const now = new Date().getTime();
        const newData = {...termData, ...{'lastEdit': now}}
        const res = await UseCloudFunction('saveOrgTerms', {'termData': newData, 'orgId': orgData.orgId, 'ts': now, 'appId': profile.appId})
        if (res.success) {
            UIkit.modal.alert('Your organization\'s terminology has been updated!')
            saveOrgData({...orgData, ...newData});
            const newOrg = {...myOrgs[orgData.orgId], ...newData}
            dispatch(setMyOrgs({...myOrgs, ...{[orgData.orgId]: newOrg}}))
        }
    }
    
    return (
        <div className='org-terms'>
            <h3>Organization Terminology</h3><hr />
            <h4>Learners</h4>
            <div className="field">
                <label>What do you want your users' learners to be called? (Default is "People")</label>
            </div>
            <div className="g-double-col">
                <div className="field">
                    {/* <label>Singular (i.e. Learner, Explorer)</label> */}
                    <input type='text' placeholder='Singular (i.e. Learner, Explorer)' defaultValue={termData.termPersonSingular ?? ''} onChange={(e) => saveData('termPersonSingular', e.target.value)} />
                </div>
                <div className="field">
                    {/* <label>Plural (i.e. Learners, Explorers)</label> */}
                    <input type='text' placeholder='Plural (i.e. Learners, Explorers)' defaultValue={termData.termPersonPlural ?? ''} onChange={(e) => saveData('termPersonPlural', e.target.value)} />
                </div>
            </div>
            <hr />
            {/* <div className="g-space-20"></div> */}
            <h4>Sorting Titles</h4>
            <div className="field">
                <label>You can use up to 4 levels of sorting to do deep dives into your reports. The default terminology is Sort 1, Sort 2, etc. If you complete one or two but leave any of the others blank, the option to use that level will not be available to your users. Leaving them all blank will give your users the ability to use all four levels at the default terminology.</label>
            </div>
            <div className="field">
                <input type="text" placeholder='Sort 1 (i.e. District, Company)' defaultValue={termData.termLevel1 ?? ''} onChange={(e) => saveData('termLevel1', e.target.value)} />
            </div>
            <div className="field">
                <input type="text" placeholder='Sort 2 (i.e. School Age Level, Campus/Building)' defaultValue={termData.termLevel2 ?? ''} onChange={(e) => saveData('termLevel2', e.target.value)} />
            </div>
            <div className="field">
                <input type="text" placeholder='Sort 3 (i.e. School, Department)' defaultValue={termData.termLevel3 ?? ''} onChange={(e) => saveData('termLevel3', e.target.value)} />
            </div>
            <div className="field">
                <input type="text" placeholder='Sort 4 (i.e. Grade Level, Floor)' defaultValue={termData.termLevel4 ?? ''} onChange={(e) => saveData('termLevel4', e.target.value)} />
            </div>
            <hr />
            {/* <div className="g-space-20"></div> */}
            <h4>Cycle Definitions</h4>
            <div className='field'>
                <label>You can define up to 10 custom steps in your coaching cycle. Simply type a name and an optional description and click "Add" to add it to your cycle definitions.</label>
            </div>
            <div className='cycle-terms'>
                {
                    (cycleTerms.length > 0) &&
                    cycleTerms.sort((a,b) => a.order > b.order ? 1 : -1)
                    .map(term => (
                        <div key={term.order} className='cycle-term-container'>
                            <div className='cycle-term g-card'>
                                <div><b>{term.name}</b></div>
                                <span>{term.desc}</span>
                            </div>
                            <div className='cycle-term-actions'>
                                <FontAwesomeIcon icon={faCaretUp} onClick={() => moveTerm('up', term.order)} className='clickable arrow' />
                                <FontAwesomeIcon icon={faXmarkCircle} onClick={() => moveTerm('delete', term.order)} className='clickable delete' />
                                <FontAwesomeIcon icon={faCaretDown} onClick={() => moveTerm('dn', term.order)} className='clickable arrow' />
                            </div>
                        </div>
                    )) 
                }
            </div>
            <div className="g-space-20"></div>
            <div className='cycle-form'>
                <div className='field'>
                    <input type='text' placeholder='Step name' id='cycle-name' />
                </div>
                <div className='field'>
                    <textarea type='text' rows="3" placeholder='Step description' id='cycle-desc' ></textarea>
                </div>
                <div>
                    <button className='g-button' onClick={() => saveCycleTerm()}>Add</button>
                </div>
            </div>
            <hr />
            <div className='buttons'>
                <button className='g-button primary' onClick={() => saveNewData()}>Save Changes</button>
            </div>
            
        </div>
    )
}

export default OrgTerms