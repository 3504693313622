import React, { useEffect, useState } from 'react';
import './report-row1.styles.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faBullseye, faHandshakeAngle, faPeopleCarryBox, faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { setReportData } from 'state/slices/contentSlice';

const ReportRow1 = ({ activity, goals }) => {
    
    const currentOrg = useSelector(state => state.contentData.currentOrg);
    const [ personCount, setPersonCount ] = useState();
    const [ totalTime, setTotalTime ] = useState();
    const [ coachingTime, setCoachingTime ] = useState();
    const [ nonCoachingTime, setNonCoachingTime ] = useState();
    const [ completeGoals, setCompleteGoals ] = useState();
    const [ totalGoals, setTotalGoals ] = useState();
    const { personId } = useParams();
    const { pageId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    useEffect(() => {
        // console.log(pageId);
    }, [pageId])

    useEffect(() => {
        if (!activity) {return;}
        let personArr = []
        let totalTimeLogged = 0;
        let coachTimeLogged = 0;
        let nonCoachTimeLogged = 0;
        for (let a of activity) {
            if (a.personId) {
                for (let p of a.personId) {
                    if (!personArr.includes(p)) {
                        personArr.push(p);
                    }
                }
            }
            if (a.startDate < new Date().getTime()) {
                totalTimeLogged += a.duration;
                if (a.activityCategory !== 'other') {
                    coachTimeLogged += a.duration;
                } else {
                    nonCoachTimeLogged += a.duration;
                }
            }
            
        }
        setPersonCount(personArr.length);
        setTotalTime((totalTimeLogged/60).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
        setCoachingTime((coachTimeLogged/60).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
        setNonCoachingTime(+(nonCoachTimeLogged/60).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
    }, [activity])

    useEffect(() => {
        if (!goals) {return;}
        let totalGoalCount = 0;
        let completedGoalCount = 0;
        for (let g of goals) {
            if (g.status === 'complete') {
                completedGoalCount++;
            }
            totalGoalCount++;
        }
        setTotalGoals(totalGoalCount);
        setCompleteGoals(completedGoalCount);
    }, [goals])

    function goToDeepDive(e) {
        if (e === 'totaltime') {
            dispatch(setReportData({
                'data': activity,
                'title': 'Total Time Logged'
            }))
        } else if (e === 'coachingtime') {
            dispatch(setReportData({
                'data': activity.filter(a => a.activityCategory !== 'other'),
                'title': 'Total Coaching Time'
            }))
        } else if (e === 'othertime') {
            dispatch(setReportData({
                'data': activity.filter(a => a.activityCategory === 'other'),
                'title': 'Total Non-Coaching Time'
            }))
        }
        navigate('deepdive')
    }

    return (
        <div className={`report-row1 ${(personId || pageId === 'coach') ? 'no-box-1' : ''}`}>
            {
                ((!personId && !pageId) || pageId === 'manager') &&
                <div className='g-card data-box people-coached'>
                    <div className='data-point'>
                        <div className='big-data'>{personCount ?? 0}</div>
                        <div className='descriptor'>Total {currentOrg.termPersonPlural ?? 'People'} Coached</div>
                        {/* <div className='meta'>-4% compared to last week</div> */}
                    </div>
                    <div className='icon'>
                        <span className='icon-circle'>
                            <FontAwesomeIcon icon={faHandshakeAngle} size="2xl" />
                        </span>
                    </div>
                </div>
            }
            <div className='g-card data-box time-logged clickable' onClick={() => goToDeepDive('totaltime')}>
                <div className='data-point'>
                    <div className='big-data'>{totalTime ?? 0} Hrs.</div>
                    <div className='descriptor'>Total Time Logged</div>
                    {/* <div className='meta'>-4% compared to last week</div> */}
                </div>
                <div className='icon'>
                    <span className='icon-circle'>
                        <FontAwesomeIcon icon={faStopwatch} size="2xl" />
                    </span>
                </div>
            </div>
            <div className='g-card data-box time-coaching clickable' onClick={() => goToDeepDive('coachingtime')}>
                <div className='data-point'>
                    <div className='big-data'>{coachingTime ?? 0} Hrs.</div>
                    <div className='descriptor'>Total Time Coaching</div>
                    {/* <div className='meta'>-4% compared to last week</div> */}
                </div>
                <div className='icon'>
                    <span className='icon-circle'>
                        <FontAwesomeIcon icon={faPeopleCarryBox} size="2xl" />
                    </span>
                </div>
            </div>
            <div className='g-card data-box non-coaching clickable' onClick={() => goToDeepDive('othertime')}>
                <div className='data-point'>
                    <div className='big-data'>{nonCoachingTime ?? 0} Hrs.</div>
                    <div className='descriptor'>Other Coach Duties</div>
                    {/* <div className='meta'>-4% compared to last week</div> */}
                </div>
                <div className='icon'>
                    <span className='icon-circle'>
                        <FontAwesomeIcon icon={faBriefcase} size="2xl" />
                    </span>
                </div>
            </div>
            <div className='g-card data-box goals'>
                <div className='data-point'>
                    <div className='big-data'>{totalGoals ?? 0}</div>
                    <div className='descriptor'>Goals Addressed</div>
                    {/* <div className='meta'>-4% compared to last week</div> */}
                </div>
                <div className='icon'>
                    <span className='icon-circle'>
                        <FontAwesomeIcon icon={faBullseye} size="2xl" />
                    </span>
                </div>
            </div>
        </div>
    )
}

export default ReportRow1