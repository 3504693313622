import React, { useEffect, useRef, useState } from 'react'
import './import-log.styles.scss';
import Calendar from '../calendar/calendar.component';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UseCloudFunction } from 'utils/firebase.utils';
import ThreeDotsLoader from 'components/loader/three-dots-loader.component';
import { getActivity } from 'utils/getUserData';
import { switchOrgMenu } from 'components/ORG-MANAGER/org-sidebar-left/org-sidebar-left.component';

const ImportLog = () => {

    const myPeople = useSelector(state => state.contentData.myPeople);
    const profile = useSelector(state => state.userData.userProfile);
    const currentOrg = useSelector(state => state.contentData.currentOrg);
    const [ events, setEvents ] = useState(null);
    const [ activity, setActivity ] = useState(null);
    const navigate = useNavigate();
    const activityRef = useRef();
    let activityListener;
    const dateRef = useRef();
    const countRef = useRef();

    useEffect(() => {
        switchOrgMenu('coach-import');
        dateRef.current = {
            'minDate': new Date(new Date().setDate(new Date().getDate()-30)),
            'maxDate': new Date(new Date().setDate(new Date().getDate()+30))
        }
        countRef.current = 0;
    }, [])

    useEffect(() => {
        if (!profile || !myPeople) {return;}
        if (!profile.gCalId) {
            navigate(-1);
            return;
        }
        getEventsFromCal();
        getActivityFromDb();
    }, [profile, myPeople])

    async function getEventsFromCal() {
        const eventsFromCal = await UseCloudFunction('importEventsFromGoogle', {'profile': profile, 'minDate': dateRef.current.minDate, 'maxDate': dateRef.current.maxDate, 'orgId': currentOrg.id});
        console.log(eventsFromCal);
        let eventObj = {...events}
        for (let e of eventsFromCal.events) {
            if (!e.start || (!e.start.dateTime && !e.start.date)) {
                continue;
            }
            if (e.summary && e.summary.includes('filling')) {
                console.log(e)
            }
            const startDate = e.start.dateTime ? new Date(e.start.dateTime).getTime() : new Date(e.start.date.replace('-', '/')).getTime() + 1800000;
            const endDate = e.end.dateTime ? new Date(e.end.dateTime).getTime() : startDate + 1800000;
            const duration = (endDate - startDate)/60000;
            const title = e.summary;
            const desc = e.description ? `<p>${e.description}</p>` : '';
            const location = e.location ?? '';
            const description = e.description ?? '';
            let attendees = [];
            if (e.attendees) {
                for (let a of e.attendees) {
                    const person = Object.values(myPeople).filter(p => p.email === a.email)[0];
                    if (person) {
                        attendees.push(person.personId)
                    }
                }
            }
            eventObj[e.id] = {
                'startDate': startDate,
                'duration': duration,
                'title': title,
                'desc': desc,
                'people': attendees,
                'location': location,
                'importId': e.id
            }
        }
        // console.log(eventObj);
        setEvents(eventObj);
    }

    function getMoreEvents(dayCount) {
        console.log(dayCount);
        let newMin;
        let newMax;
        if (dayCount > 0 && dayCount > countRef.current) {
            newMin = dateRef.current.maxDate;
            newMax = new Date(new Date(newMin).setDate(new Date(newMin).getDate() + 30));
            dateRef.current = {'minDate': newMin, 'maxDate': newMax};
            getEventsFromCal();
        } else if (dayCount < 0 && dayCount < countRef.current) {
            newMax = dateRef.current.minDate;
            newMin = new Date(new Date(newMax).setDate(new Date(newMax).getDate() - 30));
            dateRef.current = {'minDate': newMin, 'maxDate': newMax};
            getEventsFromCal();
        } else if (dayCount === 0) {
            dateRef.current = {
                'minDate': new Date(new Date().setDate(new Date().getDate()-30)),
                'maxDate': new Date(new Date().setDate(new Date().getDate()+30))
            }
        }
        console.log(newMin, newMax);
        countRef.current = dayCount;
        //new Date(new Date().setDate(new Date().getDate()-30))

    }

    function getActivityFromDb() {
        getActivity({'appId': profile.appId, 'status': 'active', 'callback': callback, 'receiveListener': handleListener})
        function callback(data) {
            activityRef.current = {...activityRef.current, ...{[data.ts]: data}}
            setActivity(activityRef.current);
        }
        function handleListener(unsub) {
            activityListener = unsub;
        }
    }

    return (
        <div>
            {
                (events && activity) ?
                <Calendar itemsToDisplay={events} importEvents={true} activity={activity} getMoreEvents={getMoreEvents} />
                : 
                <div className='center'><ThreeDotsLoader /></div>
            }
        </div>
    )
}

export default ImportLog