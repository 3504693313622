import React, { useState } from 'react';
import './upload-list.styles.scss';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faXmarkSquare } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { usePapaParse } from 'react-papaparse';
import { RandomString } from 'utils/randomString';
import UIkit from 'uikit';
import { getRandomLinearGradient } from 'utils/randomGradient';
import { UseCloudFunction } from 'utils/firebase.utils';
import { getPeopleBlurbs } from 'utils/getUserData';
import { setMyPeople } from 'state/slices/contentSlice';

const UploadList = () => {

    const currentOrg = useSelector(state => state.contentData.currentOrg);
    const profile = useSelector(state => state.userData.userProfile)
    const [ loading, setLoading ] = useState(false);
    const [ headers, setHeaders ] = useState(null);
    const [ uploadedList, setUploadedList ] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const fileReader = new FileReader();
    const { readString } = usePapaParse();

    async function handleUpload(e) {
        setUploadedList(null);
		const file = e.target.files[0];
		console.log('changed')
		const csvOutput = await readFileAsync(e.target.files[0])
		// console.log(csvOutput)
		const arr = await getParsedData(csvOutput)
		const objArr = await createObjs(arr)
		// setArray(arr);
		setHeaders(Object.keys(Object.assign({}, ...arr)));
		console.log(objArr)
        setUploadedList(objArr)
        e.target.files = null;
    }

    function readFileAsync(file) {
        return new Promise((resolve, reject) => {
            fileReader.onload = (event) => {
                resolve(event.target.result);	
            };
            fileReader.readAsText(file);
        })
    }

    function getParsedData(arr) {
		return new Promise((resolve, reject) => {
			readString(arr, {
		      	worker: true,
		      	complete: (results) => {
			        // console.log('---------------------------');
			        // console.log(results.data);
			        // console.log('---------------------------');
					resolve(results.data)
			  	},
		    });
		})
	}

    function createObjs(arr) {
		const headers = arr[0]
		let objArr = []
		for (let a=1; a<arr.length; a++) {
			const row = arr[a]
            const now = new Date().getTime()
            let rowObj = {}
            for (let r in row) {
                rowObj[headers[r]] = row[r]
            }
			let obj = {
                'added': now,
                'email': rowObj['Email'],
                'fName': rowObj['First Name'],
                'grad': getRandomLinearGradient(),
                'lName': rowObj['Last Name'],
                'notes': rowObj['Notes'],
                'orgId': currentOrg.orgId,
                'personId': RandomString(20),
                'sortLevel1': rowObj['Sort Title 1'],
                'sortLevel2': rowObj['Sort Title 2'],
                'sortLevel3': rowObj['Sort Title 3'],
                'sortLevel4': rowObj['Sort Title 4'],
                'status': 'pending',
                'ts': now,
                'appId': profile.appId
            }
			objArr.push(obj)
		}
		return objArr;
	}

    async function removePerson(personId) {
        const approved = await UIkit.modal.confirm('Are you sure you want to remove this person from the list?')
        .then(() => {return true}, () => {return false})
        if (!approved) { return; }
        const newList = uploadedList.filter(u => u.personId !== personId)
        setUploadedList(newList);
    }

    async function finalizeUpload() {
        const approved = await UIkit.modal.confirm('Are you sure you are ready to upload this list?')
        .then(() => {return true}, () => {return false})
        if (!approved) { return; }
        setLoading(true);
        const res = await UseCloudFunction('uploadPersonList', {'arr': uploadedList, 'appId': profile.appId, 'orgId': currentOrg.orgId})
        console.log(res);
        if (res.success) {
            UIkit.modal.alert('Your people have been uploaded successfully and have been added to the PENDING PEOPLE list. To activate a person, click on "VIEW PENDING PEOPLE" when you go to add a new person.');
            // getPeopleBlurbs({'appId': profile.appId, 'orgId': currentOrg.orgId, 'callback': receivePeople});
            navigate('/coach/pendinglist')
            // function receivePeople(data) {
            //     dispatch(setMyPeople(data));
            // }
        } else {
            UIkit.modal.alert('Something went wrong. Try again later.<br />' + res.error);
        }
        setLoading(false);
    }

    return (
        <div className='upload-list-container'>
            <div className="upload-list-content">
                <div className='back-div'><span className='back-icon' onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowLeft} />Back</span></div>
                <div className="g-card">
                    <div className='section-title'>Upload a list of {currentOrg && currentOrg.termPersonPlural ? currentOrg.termPersonPlural : 'People'}</div>
                    <hr />
                    <p>Open <a className='sheet-link' href='https://docs.google.com/spreadsheets/d/1q4FhM6f2qo0g4fiib5W7gOV1t08BM0Ai1g6_dRZmq70/copy' target='_blank'>this Google Sheet</a> or <a className='sheet-link' href='https://guidepost.tamingthetech.com/files/GuidePost_Upload_Template.xlsx'>this Excel spreadsheet</a>, and input the information for the people you will be working with. Be careful not to change the headers. We need those to stay as they are in order to upload your people correctly. Then upload the completed spreadsheet using the button below.</p>
                    {
                        (currentOrg.termLevel1) &&
                        <div>
                            <p>FYI, here are your organizations sort titles:</p>
                            <ul>
                                <li>Sort Title 1: <b>{currentOrg.termLevel1}</b></li>
                                {
                                    (currentOrg.termLevel2) &&
                                    <li>Sort Title 2: <b>{currentOrg.termLevel2}</b></li>
                                }
                                {
                                    (currentOrg.termLevel3) &&
                                    <li>Sort Title 3: <b>{currentOrg.termLevel3}</b></li>
                                }
                                {
                                    (currentOrg.termLevel4) &&
                                    <li>Sort Title 4: <b>{currentOrg.termLevel4}</b></li>
                                }
                            </ul>
                        </div>
                    }
                    
                    <div className="g-space-20"></div>
                    <div data-uk-form-custom>
                        <input 
                            type={"file"}
                            id={"csvCoachInput"}
                            accept={".csv"}
                            onChange={(e) => handleUpload(e, 'coach')} 
                            // className='hide' 
                        />
                        <button className="g-button" type="button" >Upload List of {currentOrg.termPersonPlural ?? 'People'}</button>
                    </div>
                    <div className="g-space-20"></div>
                    {
                        (uploadedList) &&
                        <div>
                            <p>Now, go through the list and make sure that the data is correct. Click the x on the right of any column you need to remove. When you're sure it's ready, click the "FINALIZE UPLOAD" button at the bottom of the page to finish the process. Once we are done uploading your {currentOrg.termPersonPlural ?? 'people'}, you will see them in your 'My {currentOrg.termPersonPlural ?? 'People'}' list.</p>
                            <div className="g-space-20"></div>
                            <table className='g-table'>
                                <thead>
                                    <tr>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Email</th>
                                        <th>{currentOrg.termLevel1 ?? 'Group 1'}</th>
                                        <th>{currentOrg.termLevel2 ?? 'Group 2'}</th>
                                        <th>{currentOrg.termLevel3 ?? 'Group 3'}</th>
                                        <th>{currentOrg.termLevel4 ?? 'Group 4'}</th>
                                        <th>Remove</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        uploadedList.map(u => (
                                            <tr key={u.personId}>
                                                <td>{u.fName}</td>
                                                <td>{u.lName}</td>
                                                <td>{u.email}</td>
                                                <td>{u.sortLevel1}</td>
                                                <td>{u.sortLevel2}</td>
                                                <td>{u.sortLevel3}</td>
                                                <td>{u.sortLevel4}</td>
                                                <td><FontAwesomeIcon icon={faXmarkSquare} className='clickable x-box' onClick={() => removePerson(u.personId)} /></td>
                                            </tr>
                                        ))
                                    }  
                                </tbody>
                            </table>
                            <div className="g-space-20"></div>
                            <div className='buttons'>
                                <button className='g-button'onClick={() => navigate(-1)}>Cancel</button>
                                {
                                    (!loading)
                                    ?
                                    <button className='g-button primary submit' onClick={() => finalizeUpload()}>FINALIZE UPLOAD</button>
                                    :
                                    <button className='g-button disabled submit'><div data-uk-spinner='ratio: .5'></div></button>
                                }
                                
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default UploadList