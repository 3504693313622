import React, { useEffect } from 'react';
import './new-goal.styles.scss';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import NewGoalForm from './new-goal-form.component';


const NewGoal = () => {

    const navigate = useNavigate();

    useEffect(() => {
        const els = document.getElementsByClassName('content-container');
        for (let el of els) {
            el.scroll({
                top: 0, 
                left: 0, 
                behavior: 'smooth'
            })
        }

    }, [])




    return (
        <div className="new-goal-container">        
            <div className="new-goal-content">
                <div className='back-div'><span className='back-icon' onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowLeft} />Back</span></div>
                <div className="g-card goal">
                    <NewGoalForm handleSuccessfulCompletion={() => navigate(-1)}/>
                </div>
            </div>
        </div>
    )
}

export default NewGoal