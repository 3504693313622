import React, { useEffect, useRef, useState } from 'react'
import './profile.styles.scss'

import ProfileIcon from './profile-icon.component'
import UIkit from 'uikit';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UseCloudFunction } from 'utils/firebase.utils';
import { setUserProfile } from 'state/slices/userSlice';

const ProfilePage = () => {

    const [ formData, setFormData ] = useState({});
    const [ loading, setLoading ] = useState(false);
    const currentUser = useSelector(state => state.userData.currentUser);
    const userProfile = useSelector(state => state.userData.userProfile);
    const storage = getStorage();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (userProfile) {
            console.log(userProfile)
            setFormData(userProfile)
        }
    }, [userProfile])

    function saveData(key, value) {
        setFormData({...formData, ...{[key]: value}});
    }

    function saveBio(text) {
        const length = text.length;
        const count = document.getElementById('word-count')
        if (length <= 200) {
            count.innerHTML = `${length}/200`   
            saveData('bio', text)
        }
    }

    async function handleSubmit() {
        if (!formData.fName || !formData.lName || !formData.icon) {
            UIkit.modal.alert('Please include your name and an icon before saving your profile.')
            return;
        }
		if (formData.icon.upload && formData.icon.type.indexOf('gif') == -1) {
			formData.icon.croppie.result({
				type:'blob',
				// size: 512
			})
			.then((blob) => {
				uploadImage(formData.icon,blob)
				return;
			})
		} else if (formData.icon.upload) {
			uploadImage(formData.icon, formData.icon.file)
			return;
		} else if (formData.icon.url) {
			saveProfile(formData.icon.url)
			return;
		} else if (formData.icon) {
			saveProfile(formData.icon)
		}
	}
				
	function uploadImage(profileIcon, blob) {		
		const now = new Date().getTime();
		const profileRef = ref(storage, `uploaded-profile-icons/${now}-${formData.icon.name}`)
		const metadata = {
			contentType: formData.icon.type,
		};
		uploadBytes(profileRef, blob, metadata).then((snapshot) => {
			getDownloadURL(profileRef)
			.then((url) => {
				saveProfile(url)
				return;
			})
		})
	}
				
	const saveProfile = async (url) => {
        setLoading(true)
		let appId;
        if (!userProfile) {
		    appId = currentUser.uid;
        } else {
            appId = userProfile.appId;
        }
        let tokenCount = 120;
        if (userProfile) {
            tokenCount = userProfile.tokenCount;
        }
        const profile = {
            appId: appId,
            fName: formData.fName,
            lName: formData.lName,
            email: currentUser.email,
            bio: formData.bio,
            name: `${formData.fName} ${formData.lName}`,
            timestamp: new Date().getTime(),
            uid: currentUser.uid,
            icon: url,
            status: 'active',
        }
		
        const res = await UseCloudFunction('createProfile', {'obj': profile})
        if (res.success) {
            setTimeout(() => {
                // dispatch(setUserProfile(res.newProfile))
                // dispatch(setCurrentOrg(res.newOrg))
                // dispatch(setMyOrgs({[res.newOrg.orgId]: res.newOrg}))
                navigate('/loading')
            }, 1000)
        } else {
            UIkit.modal.alert('Something went wrong. Try again later.')
        }
        
        return;
	}

    return (
        <div className='profile-page'>
            <img src={require('assets/logo_long.png')} className='profile-logo' />
            <div className='g-card'>
                <div className='card-head'>
                    <span>Create a Profile</span><hr />
                </div>
                <div className='g-grid'>
                    <div className="g-half-col">
                        <div className="field">
                            <label>First Name</label>
                            <input type='text' defaultValue={formData.fName ? formData.fName : ''} onChange={(e) => saveData('fName', e.target.value)} />
                        </div>
                    </div>
                    <div className="g-half-col">
                        <div className="field">
                            <label>Last Name</label>
                            <input type='text' defaultValue={formData.lName ? formData.lName : ''} onChange={(e) => saveData('lName', e.target.value)} />
                        </div>
                    </div>
                </div>
                <ProfileIcon formData={formData} saveData={saveData} />
                <div className="g-space-20"></div>
                <div className='field'>
                    <label>Tell us about you</label>
                    <textarea defaultValue={formData.bio ? formData.bio : ''} onChange={(e) => saveBio(e.target.value)}></textarea>
                    <div id='word-count'>0/200</div>
                </div>
                <hr />
                <div className='buttons'>
                    <button className='g-button' onClick={() => navigate(-1)}>Cancel</button>
                    { 
                        (!loading)
                        ? 
                        <button className='g-button save-profile-button primary' onClick={() => handleSubmit()}>Save Your Profile</button>
                        :
                        <button className='g-button save-profile-button disabled' ><div data-uk-spinner='ratio: .5'></div></button>
                    }
                </div>
            </div>
        </div>
    )
}

export default ProfilePage