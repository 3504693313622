import React from 'react';
import './unauth-head.styles.scss';
import { Link, useNavigate } from 'react-router-dom';

const UnauthHeader = () => {

    const navigate = useNavigate();

    return (
        <div className='unauth-head-container'>
            <div className="g-container">
                <div className='unauth-head'>
                    <div className='logo-div'>
                        <img src={require('assets/logo_long.png')} />
                    </div>
                    <div className="links-div">
                        <Link to="/features" className='head-link'>Features</Link>
                        <Link to="/pricing" className='head-link'>Pricing</Link>
                        <Link to="/signin"><button className='g-button small-btn primary' >Sign In</button></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UnauthHeader