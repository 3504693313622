import Layout from 'components/layout/layout.comp'
import React from 'react'
import CoachPage from './coach.page'

const CoachPageContainer = () => {
  return (
    <div>
        <Layout>
            <CoachPage />
        </Layout>
    </div>
  )
}

export default CoachPageContainer