export function getCountsOfUniqueValues(arr, key, beforeNow) {
    const counts = {};
  
    for (const obj of arr) {
        if (beforeNow && obj.startDate > new Date().getTime()) {
            continue;
        }
        const value = obj[key];
        if (typeof value === 'object') {
            for (let item of value) {
                counts[item] = (counts[item] || 0) + 1;
            }
        } else {
            counts[value] = (counts[value] || 0) + 1;
        }
        
        // console.log(key, obj)
    }
  
    return counts;
}

export function getDurationCounts(arr, key, match, beforeNow) {
    // console.log(arr);
    let total = 0;
    for (const obj of arr) {
        if (beforeNow && obj.startDate > new Date().getTime()) {
            continue;
        }
        if (match.indexOf(obj[key]) > -1) {   
            total += obj.duration;    
        }   
    }
    return total;
}