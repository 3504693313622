import React, { useEffect, useRef, useState } from 'react'
import './new-activity.styles.scss';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useParams } from 'react-router-dom'
import NewActivityForm from './new-activity-form.component';
import { getActivity } from 'utils/getUserData';
import ActivityList from './activity-list.component';
import ActivityItem from 'components/shared/activity-item/activity-item.component';
import { useSelector } from 'react-redux';

const NewActivity = () => {

    const myGoals = useSelector(state => state.contentData.myGoals);
    const [ showActivity, setShowActivity ] = useState(false);
    const [ personId, setPersonId ] = useState(null);
    // const [ activityId, setActivityId ] = useState(null);
    const [ fullActivityList, setFullActivityList ] = useState(null);
    const { sourceActivityId, sourcePersonId } = useParams();
    const navigate = useNavigate();
    const fullActivityRef = useRef();
    let fullActivityListener;

    useEffect(() => {
        return () => {

        }
    }, [])

    useEffect(() => {
        if (sourcePersonId) {
            setPersonId([sourcePersonId]);
        }
    }, [sourcePersonId])

    useEffect(() => {
        // console.log(formData.personId, showActivity)
        if (!personId || !showActivity || personId.length > 1) {
            fullActivityRef.current = null
            setFullActivityList(fullActivityRef.current);
            if (personId && personId.length > 1) {
                setShowActivity(false);
            }
            return;
        } else if (personId && personId.length === 1) {
            async function getFullActivityList() {
                fullActivityRef.current = null
                setFullActivityList(fullActivityRef.current);
                getActivity({'personId': personId[0], 'callback': callback, 'receiveListener': receiveListener, 'status': 'active'})
                function callback(data) {
                    console.log(data);
                    fullActivityRef.current = {...fullActivityRef.current, ...{[data.ts]: data}}
                    setFullActivityList(fullActivityRef.current);
                }
                function receiveListener(unsubscribe) {
                    fullActivityListener = unsubscribe;
                }
            }
            getFullActivityList();
        }
    }, [personId, showActivity])

    function cancel() {
        navigate(-1);
    }

    return (
        <div className="new-activity-container">        
            <div className={`new-activity-content`}>
                <div className={`activity ${showActivity ? '' : 'full'}`}>
                    <div className='back-div'><span className='back-icon' onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowLeft} />Back</span></div>
                    <div className='g-card '>
                        {
                            sourceActivityId &&
                            <div className='id-row'><span className='meta'>{`ID: ${sourceActivityId}`}</span></div>
                        }
                        <div className='activity-header'>
                            <h3>{sourceActivityId ? 'Edit Activity Log' : 'Record a New Activity Log'}</h3>
                            {
                                (personId && personId.length === 1) &&
                                <button className='g-button small-btn' onClick={() => setShowActivity(!showActivity)}>View Previous Logs</button>
                            }
                        </div>
                        <hr />
                        <NewActivityForm activityId={sourceActivityId} setPersonId={setPersonId} cancel={cancel}  />
                    </div>
                </div>
                <div className={`old-activity-list ${ showActivity ? '' : 'hidden'}`}>
                    <div className="g-space-40"></div>
                    <div className='g-card sticky'>
                        <div className='activity-items'>
                            { (fullActivityList) &&
                                Object.values(fullActivityList).sort((a,b) => a.startDate < b.startDate ? 1 : -1)
                                .map(a => (
                                    <div key={a.ts}>
                                        <ActivityItem activity={a} showButtons={false} myGoals={myGoals} />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default NewActivity