import React, { useState, useEffect } from 'react'
import './modal.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

const Modal = ({ cancel, show, children, text, closeButton, cls, cls2, backgroundClose }) => {
	const showHideClassName = show ? "modal display-block" : "modal display-none";
	
	useEffect(() => {
		// console.log(closeButton)
	},[])

	useEffect(() => {
        // console.log(show)
		if (show) {
		    document.body.style.overflow = 'hidden'; 
		} else {
			document.body.style.overflow = 'unset';
		}
	},[show]) 

    function closeModal() {
        if (backgroundClose) {
            cancel();
        }
    }
  	
	return (
    	<div className={showHideClassName} onClick={() => closeModal()}>
      		
			<section className={`modal-main ${cls ?? ''}`}>
                <div className='close-button-div'>
                    <h4>{text ?? ''}</h4>
                    {
                        (closeButton) &&
                        <FontAwesomeIcon className='close-button' icon={faXmark} size='lg' onClick={cancel} />
                    }
                </div>
                <hr />
        		<div className={`modal-children ${cls2 ?? ''}`}>
					{children}
				</div>
				<div className='spinner hide'>
					<div className="uk-overlay-default uk-position-cover"></div>
		            <div className="uk-overlay uk-position-center uk-dark">
		            	  <div data-uk-spinner="ratio: 3"></div>
		            </div>
				</div>
      		</section>
    	</div>
  	);
}

export default Modal