import { format } from "date-fns";

/**
 * 
 * @param {obj} data = orgName, subject, email recipients (emails), platform link (link), linkText 
 * @returns 
 */

export function newCoachEmail(data) {
    return {
        'emails': data.emails,
        'body': `<p>Congratulations! You've been added as a coach to the <b>${data.orgName}</b> organization in the GuidePost Coach Platform. Please click the link below to accept your invitation.</p>`,
        'subject': data.subject,
        'title': `You've been added as a coach!`,
        'link': data.link,
        'linkText': data.linkText
    }
}

