import React, { useRef, useState } from 'react'
import './chat-item.styles.scss'

import { format } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import { UseCloudFunction } from 'utils/firebase.utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { setUnread } from 'state/slices/contentSlice'
import Linkify from 'linkify-react';

const ChatItem = ({ mess, icon, setChatToView, setReplyId, replyButton, isReply }) => {

    const unread = useSelector(state => state.contentData.unread)
    const orgId = useSelector(state => state.contentData.currentOrg.orgId)
    const appId = useSelector(state => state.userData.userProfile.appId)
    const [ options ] = useState({ target: "_blank" })
    const unreadRef = useRef();
    const dispatch = useDispatch();

	function openChat() {
		setChatToView(mess)
		setReplyId(mess.id)
	}

    async function removeUnread(id) {
        delete unreadRef.current[id]
        dispatch(setUnread({...unreadRef.current}))
        const res = await UseCloudFunction('removeUnread', {'noteId': id, 'orgId': orgId, 'appId': appId})
        console.log(res)
    } 

    async function toggleLike(messId) {
        console.log(messId)
        const res = await UseCloudFunction('toggleLike', {
            'orgId': orgId,
            'appId': appId,
            'messId': messId,
            'ts': new Date().getTime()
        })
        console.log(res)
    }

    if (!replyButton || (unread && unread[mess.id] && !mess.replies)) {
        return (
            <div className='chat-item' id={`chat-${mess.ts}`} >
                {
                    <div key={mess.ts} className={`chat-bubble g-list-item`}>
                        <div className='chat-head'>
                            <img src={icon} className='chat-icon' />
                            <div className='chat-head-text'>
                                <div className='chat-content'>{mess.name}</div>
                                <div className='chat-date'>{format(new Date(mess.ts), 'Pp')}</div>
                            </div>
                        </div>
                        <div className="chat-text">  
                            {/* {mess.ts} {mess.latest} */}
                            <div className='chat-content'>{mess.text}</div>
                            {
                                (mess.image) &&
                                <div data-uk-lightbox='animation:fade'>
                                    <a href={mess.image}>
                                        <img src={mess.image} className={`chat-image ${!replyButton && 'reply'}`} alt='upload' />
                                    </a>
                                </div>
                                
                            }
                            
                            {
                                (!isReply) &&
                                <div className='comment-like clickable' onClick={() => toggleLike(mess.id)} >
                                    {/* <div style={{'width': '30px'}}></div> */}
                                    <div >
                                        <img src={require('assets/pro.png')} style={{'height': '1em'}} /> {mess.likes ? Object.keys(mess.likes).length : '0'}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        )
    } else {
        return (
            <div className='chat-item' id={`chat-${mess.ts}`} >
                {
                    <div key={mess.ts} className={`chat-bubble  g-list-item`}>
                        <div className='chat-head'>
                            <img src={icon} className='chat-icon' />
                            <div className='chat-head-text'>
                                <div className='chat-content'>{mess.name}</div>
                                <div className='chat-date'>{format(new Date(mess.ts), 'Pp')}</div>
                            </div>
                        </div>
                        <div className="chat-text">  
                            {/* {mess.ts} {mess.latest} */}
                            <div className='chat-content'><Linkify options={options}>{mess.text}</Linkify></div>
                            {/* {mess.text.length > 45 ? `${mess.text.substring(0, 45)}...` : mess.text} {mess.text.length > 25 && <a onClick={() => openChat()}>more</a>} */}
                            {
                                (mess.image) &&
                                <div data-uk-lightbox='animation:fade'>
                                    <a href={mess.image}>
                                        <img src={mess.image} className={`chat-image ${!replyButton && 'reply'}`} alt='upload' />
                                    </a>
                                </div>
                            }
                                
                            <div className='comment-like'>
                                {/* <div style={{'width': '30px'}}></div> */}
                                <div className='clickable' onClick={() => toggleLike(mess.id)}>
                                    <img src={require('assets/pro.png')} /> {mess.likes ? Object.keys(mess.likes).length : '0'}
                                </div>
                                {
                                    (replyButton) &&
                                    <span className='clickable' onClick={() => openChat()}>
                                        {(mess.replies) ? Object.keys(mess.replies).length : 0} {(mess.replies && Object.keys(mess.replies).length === 1) ? 'Reply' : 'Replies'}
                                    </span>    
                                }
                                
                                
                            </div>
                            
                            {/* {
                                mess.replies &&
                                Object.values(mess.replies).sort((a,b) => a.ts < b.ts ? 1 : -1)
                                .map(reply => (
                                    <div className='chat-bubble reply'>
                                        <img src={reply.icon} className='chat-icon' />
                                    </div>
                                ))
                                
                            } */}
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default ChatItem;