import React, { useEffect, useState } from 'react'
import './deep-dive-table.styles.scss';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import ProperCase from 'utils/proper';
import Modal from 'components/modal/modal.component';
import DOMPurify from 'isomorphic-dompurify';

const DeepDiveTable = ({ goals }) => {

    const chartData = useSelector(state => state.contentData.reportData);
    const orgUsers  = useSelector(state => state.contentData.orgUsers);
    const currentOrg = useSelector(state => state.contentData.currentOrg);
    const [ displayData, setDisplayData ] = useState(null);
    const [ currentActivity, setCurrentActivity ] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!chartData) {return;}
        console.log(chartData);
        setDisplayData(chartData.data);
    }, [chartData])

    return (
        <div className='deep-dive-table'>
            <div className='back-div'><span className='back-icon' onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowLeft} />Back</span></div>
            <div className='section-title'>
                <span>Reports | {chartData && chartData.title}</span>
            </div>
            <hr />
            
            <table className='g-table'>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Coach</th>
                        <th>{currentOrg && currentOrg.termPersonPlural ? currentOrg.termPersonPlural : 'People'}</th>
                        <th>Duration</th>
                        <th>Category</th>
                        <th>Focus Area</th>
                        <th>Type</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {
                    (displayData && orgUsers) &&
                    displayData.toSorted((a,b) => a.startDate > b.startDate ? 1 : -1)
                    .map(a => (
                        <tr key={a.ts}>
                            <td>{a.startDate && format(new Date(a.startDate), 'P')}</td>
                            <td>{orgUsers[a.appId] && `${orgUsers[a.appId].fName} ${orgUsers[a.appId].lName}`}</td>
                            <td>{a.personId ? a.personId.length : 0}</td>
                            <td>{+(a.duration/60).toFixed(2)} hr</td>
                            <td>{ProperCase(a.activityCategory)}</td>
                            <td>{a.focusArea}</td>
                            <td>{a.activityType}</td>
                            <td>{<FontAwesomeIcon icon={faArrowUpRightFromSquare} className='clickable' onClick={() => setCurrentActivity(a)} />}</td>
                        </tr>
                    ))
                    

                }
                </tbody>
            </table>
            <Modal show={currentActivity} cancel={() => setCurrentActivity(null)} text='View Activity Details' closeButton={true} cls={'wide'} >
                {
                    (currentActivity) &&
                    <div className='activity-view-modal'>
                        <div className='coach-info'>
                            <div className='name-icon'>
                                <img src={orgUsers[currentActivity.appId] && orgUsers[currentActivity.appId].icon} />
                                <div className='name'>{orgUsers[currentActivity.appId] && `${orgUsers[currentActivity.appId].fName} ${orgUsers[currentActivity.appId].lName}`}<br />
                                <span className='meta'>{currentActivity.startDate && format(new Date(currentActivity.startDate), 'Pp')}</span>
                                </div>
                            </div>
                            
                        </div>
                        <div className='categories'>
                            <b>Category:</b> {ProperCase(currentActivity.activityCategory)}<br />
                            <b>Type: </b>{currentActivity.activityType}<br />
                            <b>Focus Area: </b>{currentActivity.focusArea}
                        </div>
                        <div className='details'>
                            <h4>"{currentActivity.title}"</h4>
                            <b>Activity Details:</b><br />
                            {<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(currentActivity.desc) }} />}
                        </div>
                    </div>
                }
            </Modal>
        </div>
    )
}

export default DeepDiveTable;