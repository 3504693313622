import React, { useEffect, useLayoutEffect } from 'react'
import './loading-page.styles.scss'

import Loader from 'components/loader/loader.comp'
import { useDispatch, useSelector } from 'react-redux'
import { UseCloudFunction, auth } from 'utils/firebase.utils'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { setCurrentUser, setUserProfile } from 'state/slices/userSlice'
import { setCurrentOrg, setMyOrgs } from 'state/slices/contentSlice'
import UIkit from 'uikit'
import { signInWithCustomToken } from 'firebase/auth'
import { current } from '@reduxjs/toolkit'
import { useState } from 'react'

const LoadingPage = ({ refresh }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const currentUser = useSelector(state => state.userData.currentUser);
    const myOrgs = useSelector(state => state.contentData.myOrgs);
    const [ emailPass, setEmailPass ] = useState(false);
    // const { authCode } = useParams();
    const [ searchParams ] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    

    useEffect(() => {
        if (searchParams && location) {
        // console.log(location)
            const auth_code = searchParams.get('code');
            if (auth_code) {
                signInWithAuthCode(auth_code);
            } else  {
                setEmailPass(true);
            }
        }
    }, [searchParams, location])

    useLayoutEffect(() => {
        if (currentUser && (emailPass || refresh)) {
            getUserProfile();
        } 
    }, [currentUser, emailPass, refresh])

    useEffect(() => {
        if (!myOrgs) {return;}
        chooseStartingPoint(myOrgs);
    }, [myOrgs])

    async function signInWithAuthCode(auth_code) {
        console.log(location.origin)
        const res = await UseCloudFunction('signInCustom', {'auth_code': auth_code, 'dev': window.location.origin.includes('localhost')});
        console.log(res);
        if (res.error) {
            UIkit.modal.alert('Something went wrong. Try again later. <br />' + res.error)
            return;
        }
        await signInWithCustomToken(auth, res.fbToken)
        .then(result => {
            const user = result.user;
            console.log(user)
            const newUser = {
                displayName: user.displayName,
                email: user.email,
                metadata: {
                    createdAt: Number(user.metadata.createdAt),
                    lastLogInAt: user.metadata.lastLogInAt
                },
                uid: user.uid,
                photoURL: user.photoURL,
                // provider: user.providerData[0].providerId
            }
            dispatch(setCurrentUser({...newUser}));
        }).catch(err => {
            UIkit.modal.alert('Something went wrong. Try again later. <br />' + err);
            return;
        })
        if (res.profile.status === 'active') {
            dispatch(setUserProfile(res.profile))
            dispatch(setMyOrgs(res.myOrgs))
            // chooseStartingPoint(res.myOrgs);
        } else {
            navigate('/profile');
        }
    }

    async function getUserProfile() {
        if (!userProfile) {
            const profile = await UseCloudFunction('signIn', {'email': currentUser.email});
            if (profile.profile && profile.profile.fName) {
                dispatch(setUserProfile(profile.profile))
                dispatch(setMyOrgs(profile.myOrgs))
                chooseStartingPoint(profile.myOrgs);
            } else {
                navigate('/profile');
            }
        } else {
            chooseStartingPoint(myOrgs)
        }
    }

    function chooseStartingPoint(tempMyOrgs) {
        const proOrgs = Object.values(tempMyOrgs).filter(org => org.orgType !== 'ind');
        if (proOrgs.length > 0) {
            const managing = Object.values(proOrgs).filter(org => org.role && (org.role.includes('primaryContact') || org.role.includes('orgManager')))
            // console.log(managing)
        
            if (managing.length > 0) {
                dispatch(setCurrentOrg(managing[0]))
                navigate(`/manage`);
            } else {
                dispatch(setCurrentOrg(Object.values(proOrgs)[0]))
                navigate('/coach');
            }
        } else {
            dispatch(setCurrentOrg(Object.values(tempMyOrgs)[0]))
            navigate('/coach');
        }
    }

    return (
        <div className='loading-page'>
            {/* <div className='loader-icon'>
                <img src={require('assets/icon.png')} />
            </div> */}
            <Loader />  
        </div>
    )
}

export default LoadingPage