import React, { useEffect, useState } from 'react'
import { tipsList } from './tips-list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const TipCard = () => {
    
    const [ tip, setTip ] = useState();
    const [ tipNo, setTipNo ] = useState();

    useEffect(() => {
        if (!tipsList) {return;}
        const rand = Math.floor(Math.random() * tipsList.length)
        const tempTip = tipsList[rand]
        setTip(tempTip);
        setTipNo(rand + 1);
        // console.log(tempTip);

    }, [tipsList])

    return (
        <div className='tip-card'>
            {
                (tip && tipNo) && 
                <>
                    <div className='icon'>
                        <span className='icon-circle' style={{'background': tip.bg}}>
                            <FontAwesomeIcon icon={tip.icon} size="2xl" />
                        </span>
                    </div>
                    <p>Coaching Tip #{tipNo}</p>
                    <div>                    
                        <p><b>{tip.text.substring(0, tip.text.indexOf(':') + 1)}</b>{tip.text.substring(tip.text.indexOf(':') + 1, tip.text.length)}</p>
                    </div>
                </>
            }
        </div>
    )
}

export default TipCard