import React, { useEffect } from 'react'
import './todo-container.styles.scss';
import { switchOrgMenu } from 'components/ORG-MANAGER/org-sidebar-left/org-sidebar-left.component';
import TodoForm from './todo-form.component';
import { useNavigate, useParams } from 'react-router-dom';
import TodoList from './todo-list.component';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faUser } from '@fortawesome/free-solid-svg-icons';
import PersonIcon from '../../shared/person-icon.component';

const ToDoContainer = ({ todoList, setTodoList }) => {

    const myPeople = useSelector(state => state.contentData.myPeople);
    const { personId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        switchOrgMenu('coach-my-todos')
    }, [])

    return (
        <div className='todo-container'>
            <div className="todo-content">
                <div className='back-div'><span className='back-icon' onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowLeft} />Back</span></div>
                <div className="g-double-col">
                    <div className='g-card'>
                        <div className='section-title small'>Add an item to the list</div><hr />
                        <TodoForm personId={personId} />
                    </div>
                    <div className='g-card'>
                        <div className='section-title with-icon small'>
                            <span>My To-Do List</span>
                            {
                                (personId && myPeople) &&
                                <PersonIcon personId={personId} myPeople={myPeople} />
                            }
                        </div><hr />
                        <TodoList todoList={todoList} setTodoList={setTodoList} personId={personId} />
                    </div>
                </div>
            </div>    
        </div>
    )
}

export default ToDoContainer