import React, { useEffect, useState } from 'react'
import './goal-list.styles.scss';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import PersonIcon from 'components/shared/person-icon.component';
import GoalItem from 'components/shared/goal-item/goal-item.component';

const GoalList = () => {

    const myPeople = useSelector(state => state.contentData.myPeople);
    const myGoals = useSelector(state => state.contentData.myGoals);
    const currentOrg = useSelector(state => state.contentData.currentOrg);
    const [ personGoals, setPersonGoals ] = useState(null);
    const { personId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!personId || !myGoals) {return;}
        let goals = Object.values(myGoals).filter(g => g.personId === personId || g.personId.includes(personId))
        // console.log(goals);
        setPersonGoals(goals)
    }, [personId, myGoals])

    return (
        <div className='goal-list-container'>
            <div className='goal-lists'>
                <div className='back-div'>
                    <span className='back-icon' onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowLeft} />Back</span>
                    {
                        (personId && myPeople) &&
                        <PersonIcon personId={personId} myPeople={myPeople} />
                    }
                </div>
                <div className='g-double-col'> 
                    <div className='g-card'>
                        <h4>Active Goals</h4><hr />
                        <div className='goal-content'>
                            {
                                (personGoals && personGoals.length > 0) &&
                                personGoals
                                .filter(g => g.cycleStep + 1 < currentOrg.cycleTerms.length)
                                .toSorted((a,b) => a.ts > b.ts ? 1 : -1)
                                .map(g => (
                                    <div key={g.ts}>
                                        <GoalItem goal={g} showButtons={true} />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className='g-card'>
                        <h4>Completed Goals</h4><hr />
                        <div className='goal-content'>
                        {
                                (personGoals && personGoals.length > 0) &&
                                personGoals
                                .filter(g => g.cycleStep + 1 === currentOrg.cycleTerms.length)
                                .toSorted((a,b) => a.ts > b.ts ? 1 : -1)
                                .map(g => (
                                    <div key={g.ts}>
                                        <GoalItem goal={g} showButtons={true} />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GoalList