import React, { useEffect, useLayoutEffect, useState } from 'react';
import './org-landing.styles.scss';

import { switchOrgMenu } from '../org-sidebar-left/org-sidebar-left.component';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faBullseye, faHandshakeAngle, faSquareArrowUpRight, faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { Chart1 } from './chart1.component';
import Chart2 from './chart2.component';
import Chart3 from './chart3.component';
import { getCountsOfUniqueValues, getDurationCounts } from 'utils/getUniqueCounts';
import { format } from 'date-fns';
import ChatInput from 'components/chat/chat-input.component';
import ChatDisplay from 'components/chat/chat-display.component';
import { IsMobileDevice, useIsMobileDevice } from 'utils/responsiveQueries';

const OrgLanding = ({ recentActivity, lastActivity }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const currentOrg = useSelector(state => state.contentData.currentOrg);
    const orgUsers = useSelector(state => state.contentData.orgUsers);
    const [ personCount, setPersonCount ] = useState(0);
    const [ hourTotal, setHourTotal ] = useState(0);
    const [ goalTotal, setGoalTotal ] = useState(0);
    const [ coachHoursTotal, setCoachHoursTotal ] = useState(0);
    const [ replyId, setReplyId ] = useState(null)

    const [ mobile, setMobile ] = useState(IsMobileDevice())
    
    useLayoutEffect(() => {
        setTimeout(() => {
            switchOrgMenu('org-menu-home')
        }, 50);
    }, [])

    useEffect(() => {
        console.log(mobile)
    }, [mobile])

    useEffect(() => {
        if (!recentActivity) {return;}
        const personList = []
        const goalList = []
        for (let a of Object.values(recentActivity)) {
            if (a.personId) {
                for (let p of a.personId) {
                    if (!personList.includes(p)) {
                        personList.push(p);
                    }
                }
            }
            if (a.goal) {
                for (let g of a.goal) {
                    if (!goalList.includes(g)) {
                        goalList.push(g)
                    }
                }
            }
        }
        // console.log(personList);
        setPersonCount(personList.length);
        setGoalTotal(goalList.length);
        const hourCount = Object.values(recentActivity).filter(a => a.startDate <= new Date().getTime()).reduce((acc, curr) => acc + curr.duration, 0 );
        setHourTotal((hourCount/60).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
        const coachHours = getDurationCounts(Object.values(recentActivity), 'activityCategory', ['meeting', 'visit'], true)
        setCoachHoursTotal((coachHours/60).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
    }, [recentActivity])

    return (
        <div className={`org-landing-container ${mobile ? 'mobile' : ''}`}>
            <div className='g-card welcome-card'>
                <div className='text-div'>Welcome Back, {userProfile.fName}!</div>
                <div className='image-div'>
                    {/* <img src={require('assets/welcome.png')} /> */}
                </div>
            </div>
            <div className='g-card data-box people-coached-card'>
                <div className='icon'>
                    <span className='icon-circle'>
                        <FontAwesomeIcon icon={faHandshakeAngle} size="2xl" />
                    </span>
                </div>
                <div className='data-point'>
                    <div className='big-data'>{personCount}</div>
                    <div className='descriptor'>{currentOrg.termPersonPlural ?? 'People'} coached this week</div>
                    {/* <div className='meta'>-4% compared to last week</div> */}
                </div>
                
                
            </div>
            <div className='g-card data-box hours-card'>
                <div className='data-point'>
                    <div className='big-data'>{hourTotal}</div>
                    <div className='descriptor'>Total hours logged this week</div>
                    {/* <div className='meta'>+5% compared to last week</div> */}
                </div>
                <div className='icon'>
                    <span className='icon-circle'>
                        <FontAwesomeIcon icon={faStopwatch} size="2xl" />
                    </span>
                </div>
                
            </div>
            <div className='g-card data-box goals-card'>
                <div className='data-point'>
                    <div className='big-data'>{coachHoursTotal}</div>
                    <div className='descriptor'>Total hours coached this week</div>
                    {/* <div className='meta'>+5% compared to last week</div> */}
                </div>
                <div className='icon'>
                    <span className='icon-circle'>
                        <FontAwesomeIcon icon={faBullseye} size="2xl" />
                    </span>
                </div>
                
            </div>
            <div className='g-card data-box other-card'>
                <div className='data-point'>
                    <div className='big-data'>{goalTotal}</div>
                    <div className='descriptor'>Goals addressed this week</div>
                    {/* <div className='meta'>+5% compared to last week</div> */}
                </div>
                <div className='icon'>
                    <span className='icon-circle'>
                        <FontAwesomeIcon icon={faBullseye} size="2xl" />
                    </span>
                </div>
                
            </div>
            
            <div className='g-card hours-chart'>
                <b>Total Hours per day</b>
                {
                    (recentActivity) 
                    ?
                    <Chart3 recentActivity={Object.values(recentActivity)} />
                    : <h3>No activity yet...</h3>
                }
            </div>
            <div className='g-card focus-chart'>
                <b>Focus Areas</b>
                {
                    (recentActivity)
                    ?
                    <Chart2 recentActivity={Object.values(recentActivity)} />
                    : <h3>No activity yet...</h3>
                }
            </div>
            
            
            <div className='g-card daily-feed'>
                <div className='small-title-with-icon'>
                    <span>Daily Feed</span>
                    <FontAwesomeIcon icon={faSquareArrowUpRight} className='clickable'/>
                </div>
                <hr className='no-margin' />
                <ChatDisplay setReplyId={setReplyId} replyId={replyId} />
				<ChatInput replyId={replyId} />
            </div>
 
            {/* <div className='g-card coach-list'>
                <div className='list-header g-list-item'>
                    <span>Coach</span>
                    <span>Last Activity</span>
                    <span>{currentOrg.termPersonPlural ?? 'People'}</span>
                </div>
                {
                    (orgUsers && lastActivity) &&
                    Object.values(orgUsers).filter(user => user.status === 'active' && user.role.includes('coach'))
                    .sort((a,b) => a.lName.toLowerCase() > b.lName.toLowerCase() ? 1 : -1)
                    .map((user, index) => (
                        <div className='g-list-item clickable' key={user.appId}>
                            <span>{user.fName} {user.lName}</span>
                            <span>{lastActivity[user.appId] ? format(new Date(lastActivity[user.appId]), 'P') : 'none'}</span>
                            <span>{user.role.join(', ')}</span>
                            <span>{user.status}</span>
                            <span>0</span>
                            <span><FontAwesomeIcon icon={faArrowUpRightFromSquare} /></span>
                        </div>
                    ))
                }
            </div> */}
        </div>
    )
}

export default OrgLanding;