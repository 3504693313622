import React, { useEffect, useState } from 'react'
import './info-card.styles.scss';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { getCoachStats } from 'utils/getUserData';

const PersonInfoCard = ({ personData, deletePerson, admin }) => {

    const currentOrg = useSelector(state => state.contentData.currentOrg);
    const [ showPersonActions, setShowPersonActions ] = useState(false);
    const [ allowDelete, setAllowDelete ] = useState(false);
    const navigate = useNavigate();
    let statsListener;

    useEffect(() => {
        if (!personData) {return;}
        console.log(personData);
        if (!personData.goalCount && !personData.logCount) {
            setAllowDelete(true);
        } else {
            setAllowDelete(false);
        }
    }, [personData])



    return (
        <div className='view-person-info-div'>
            {
                (personData) &&
                <div className="g-card">
                    <div className='person-actions'>
                        <FontAwesomeIcon icon={faEllipsisV} className='clickable' onClick={() => setShowPersonActions(!showPersonActions)} />
                        <div className={`person-actions-drop ${showPersonActions && 'active'}`}>
                            <div className={`drop-item ${admin && 'disabled'}`} onClick={() => navigate(`/coach/editperson/${personData.personId}`)}>Edit<FontAwesomeIcon icon={faPen} /></div>
                            <div className={`drop-item ${(!allowDelete || admin) && 'disabled'}`} onClick={() => deletePerson(personData.personId)}>Delete<FontAwesomeIcon icon={faTrash} /></div>
                        </div>
                    </div>
                    <div className='image-div'>
                        {
                            (personData.icon)
                            ?
                            <img src='' />
                            :
                            <div className='grad' style={{'background': personData.grad}}>
                                <span>{personData.fName[0]}{personData.lName[0]}</span></div>
                        }
                    </div>
                    <div className='name-div'>{personData.fName} {personData.lName}</div>
                    <div className='info-div'>
                        <div className='meta'>{currentOrg.termLevel1 ?? 'Group 1'}</div>
                        <div>{personData.sortLevel1}</div>
                        <div className="g-space-20"></div>
                    
                    {
                        (personData.sortLevel2) &&
                        <>
                            <div className='meta'>{currentOrg.termLevel2 ?? 'Group 2'}</div>
                            <div>{personData.sortLevel2}</div>
                            <div className="g-space-20"></div>
                        </>
                    }
                    {
                        (personData.sortLevel3) &&
                        <>
                            <div className='meta'>{currentOrg.termLevel3 ?? 'Group 3'}</div>
                            <div>{personData.sortLevel3}</div>
                            <div className="g-space-20"></div>
                        </>
                    }
                    {
                        (personData.sortLevel4) &&
                        <>
                            <div className='meta'>{currentOrg.termLevel4 ?? 'Group 4'}</div>
                            <div>{personData.sortLevel4}</div>
                        </>
                    }
                    </div>
                </div>
            }
        </div>
    )
}

export default PersonInfoCard