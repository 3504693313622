import React, { useEffect, useLayoutEffect } from 'react'
import './landing.styles.scss'

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import UnauthLayout from 'components/unauth-layout/unauth-layout.component';
// import { signInWithGoogle } from 'utils/firebase.utils';

const LandingPage = () => {

    const currentUser = useSelector(state => state.userData.currentUser);
    const userProfile = useSelector(state => state.userData.userProfile);
    const navigate = useNavigate();

    useEffect(() => {
        // console.log('currentUser changed')
        // console.log(currentUser);
        if (currentUser) {
            navigate('/loading');
        }
    }, [currentUser])

    return (
        <div>
            <UnauthLayout>
            <div className='landing-page'>
                <img src={require('assets/logo_tall.png')} className='landing-icon' />
                <div className="g-space-20"></div>
                {
                    (currentUser) ?
                    <button className='g-button big' onClick={() => navigate('/loading')}>Home</button>
                    :
                    <button className='g-button big' onClick={() => navigate('/signin')}>Sign In</button>
                }
            </div>
            </UnauthLayout>
        </div>
    )
}

export default LandingPage;