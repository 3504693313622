import React, { useEffect, useRef, useState } from 'react';
import './todo-form.styles.scss';
import { useSelector } from 'react-redux';
import flatpickr from 'flatpickr';
import UIkit from 'uikit';
import { UseCloudFunction } from 'utils/firebase.utils';
import { faLessThanEqual } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const TodoForm = ({ personId }) => {
    
    const currentOrg = useSelector(state => state.contentData.currentOrg);
    const profile = useSelector(state => state.userData.userProfile);
    const myPeople = useSelector(state => state.contentData.myPeople);
    const [ formData, setFormData ] = useState({});
    const [ flatpickrInstance, setFlatpickrInstance ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const dataRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        const dueDateElement = document.getElementById(`dueDate`);
		let fp1 = flatpickr(dueDateElement, 
			{
				altInput: true,
	            altFormat: "F j, Y",
                enableTime: false,
                dateFormat: "Y-m-d",
				onChange: (dates) => {saveData('dueDate', new Date(dates[0]).getTime())}
			}
		);
		setFlatpickrInstance(fp1);
    }, [])

    useEffect(() => {
        if (!personId) {return;}
        dataRef.current = {...dataRef.current, ...{'personId': personId}}
        setFormData(dataRef.current);
    }, [personId])

    function saveData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}};
        setFormData(dataRef.current);
    }

    async function handleSubmit() {
        if (!formData.title || !formData.personId) {
            UIkit.modal.alert('Please complete the required parts of the form before submitting.')
            return;
        }
        setLoading(true);
        const obj = {...formData, ...{
            'orgId': currentOrg.orgId,
            'appId': profile.appId,
            'ts': new Date().getTime(),
            'status': 'active'
        }}
        
        const res = await UseCloudFunction('updateToDoItem', {'obj': obj})
        if (res.error) {
            UIkit.modal.alert('Something went wrong. Please try again later.<br/>' + res.error)
            return;
        } else {
            dataRef.current = {};
            setFormData(dataRef.current);
            flatpickrInstance.clear();
        }
        setLoading(false);
    }


    return (
        <div className='todo-form'>
            <div className='field small'>
                <label>To-Do Title<span className='required'>*</span></label>
                <input type='text' value={formData.title ?? ''} onChange={(e) => saveData('title', e.target.value)} />
            </div>
            
            <div className='field small'>
                <label>Choose an active {currentOrg.termPersonSingular ?? 'Person'}<span className='required'>*</span></label>
                <select className={personId ? 'disabled' : ''} value={formData.personId ?? ''} onChange={(e) => saveData('personId', e.target.value)} >
                    <option value='' disabled>Choose...</option>
                    <option value='none'>No {currentOrg.termPersonSingular ?? 'Person'} chosen</option>
                    {
                        (myPeople) &&
                        Object.values(myPeople).sort((a, b) => (a.lName.toLowerCase() > b.lName.toLowerCase()) ? 1 : (a.lName.toLowerCase() === b.lName.toLowerCase()) ? ((a.fName.toLowerCase() > b.fName.toLowerCase()) ? 1 : -1) : -1 )
                        .map(p => (
                            <option key={p.personId} value={p.personId}>{p.lName}, {p.fName}</option>
                        ))
                    }
                </select>
            </div>
            
            <div className='field small'>
                <label>Due Date (optional)</label>
                <input type='text' id='dueDate' />
            </div>
            
            <div className='field small'>
                <label>Details (optional)</label>
                <textarea rows='3' value={formData.details ?? ''} onChange={(e) => saveData('details', e.target.value)}></textarea>
            </div>
            <hr />
            <span className='required'>* = Required</span>
            <div className='buttons'>
                <button className='g-button' onClick={() => navigate(-1)}>Cancel</button>
                {
                    (!loading)
                    ?
                    <button className='g-button primary submit' onClick={() => handleSubmit()}>Add to List</button>
                    :
                    <button className='g-button disabled submit'><div data-uk-spinner='ratio: .5'></div></button>
                }
            </div>
            
        </div>
    )
}

export default TodoForm