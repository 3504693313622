import React, { useEffect, useState } from 'react';
import './invite-modal.styles.scss'
import Modal from 'components/modal/modal.component';
import { acceptInvite, declineInvite } from 'utils/inviteAcceptDecline';
import { useSelector } from 'react-redux';

const InviteModal = ({ invite, callback }) => {
    
    const profile = useSelector(state => state.userData.userProfile);
    const [ showInvite, setShowInvite ] = useState(false);

    useEffect(() => {
        if (invite) {
            setShowInvite(true);
        } else {
            setShowInvite(false);
        }
    }, [invite])

    async function acceptDecline(e) {
        if (e === 'accept') {
            const res = await acceptInvite(invite, profile.appId)
            callback(res);
        } else if (e === 'decline') {
            const res = await declineInvite(invite, profile.appId)
            callback(res);
        }
    }

    return (
        <div className='invite-modal'>
            <Modal 
                show={showInvite} 
                close={() => setShowInvite(false)}
                text={`You've been invited to join the ${(invite && invite.orgName) ? invite.orgName : '?'}!`} 
                cls='narrow'
            >
                <div className='invite-modal-content'>
                    <img src={require('assets/invitation.png')} className='invite-img' />
                    <div className="g-space-20"></div>
                    <p>By accepting the invitation, all of your current data will be transferred to the organization, and you will no longer have an individual account with this email address. To maintain this individual account, you will need to decline the invitation and have the organization's manager send a new invite using a different email address.</p>
                    <div className='button-row'>
                        <button className='g-button' onClick={() => acceptDecline('decline')}>Decline</button>
                        <button className='g-button primary' onClick={() => acceptDecline('accept')}>Accept the Invitation!</button>                    
                    </div> 
                </div>
            </Modal>
        </div>
    )
}

export default InviteModal